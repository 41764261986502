import { IUser } from '../models/user';

export function getActiveUser(users: IUser[]): IUser | undefined {
  return users.find((user) => user.active === true);
}

export function haveActiveEntity(users: IUser[]) {
  return getActiveUser(users) !== undefined;
}

export function getMainUser(users: IUser[]): IUser | undefined {
  return users.find((user) => user.mainUser === true);
}

export function getActiveUserName(users: IUser[]): string {
  var user = users.find((user) => user.active === true);
  if (user) {
    return user.name;
  }

  return '';
}
