import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { AuthContextProps, useAuth } from 'react-oidc-context';
import { IProblemDetails } from '../models/problemDetails';

const axiosConfig = {
  baseURL:
    process.env.REACT_APP_AXIOS_BASE_URL ||
    (() => {
      console.error('Missing REACT_APP_AXIOS_BASE_URL in .env.');
      return '';
    })(),
};

export interface IApiError {
  code: number;
  additionalDetails: any;
  status: number;
}

export var ApiClient: AxiosInstance;

export function useApiClient(auth2: AuthContextProps | undefined = undefined) {
  const client = axios.create({
    baseURL: axiosConfig.baseURL,
  });

  const auth = useAuth();

  if (auth !== undefined) {
    client.interceptors.request.use(async (config) => {
      let token = sessionStorage.getItem('coeli_token');
      if (token == null) token = await getToken(auth);
      if (token != null && config.headers !== undefined)
        config.headers['Authorization'] = `Bearer ${token}`;
      config.baseURL = axiosConfig.baseURL;
      return config;
    });
  }
  client.interceptors.response.use(
    (response: AxiosResponse<any>) => {
      return response;
    },
    (error) => {
      const problemDetails: IProblemDetails = error.response.data;
      switch (problemDetails.code) {
        // case ApiErrorCode.Unknown:

        //   break;
        default:
          // Check for unauthorized
          if (error.response.status === 401) {
            sessionStorage.removeItem('coeli_token');
            auth.signoutRedirect({ id_token_hint: auth.user?.id_token });
            auth.removeUser();
          } else {
            alert(
              'Ett okänt fel har uppstått (' +
                error.response.status +
                ')! Kontakta info@coeli.se'
            );
          }

          console.error(error.response.data);
          break;
        // Do nothing and let specific custom business exception handling.
      }

      return Promise.reject(error.response.data as IApiError);
    }
  );

  ApiClient = client;
  return client;
}

async function getToken(auth: AuthContextProps): Promise<string | null> {
  const user = await auth.signinSilent();

  if (user != null) {
    sessionStorage.setItem('coeli_token', user.access_token);
    return user.access_token;
  }

  return null;
}
