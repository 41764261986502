import KycForm from '../components/forms/KycForm';
import KycFormCompany from '../components/forms/KycCompany';
import { useAppSelector } from '../redux/hooks';
import { getActiveUser } from '../redux/stateFuncs';
import ReactToPrint from 'react-to-print';
import { Box, Button } from '@mui/material';
import { useRef } from 'react';

const Kyc = () => {
  const users = useAppSelector((state) => state.users);

  const activeUser = getActiveUser(users);
  const componentRef = useRef<HTMLDivElement>(null)
  
  return (
    <>
      <div ref={componentRef}>
        {activeUser?.isCompany == true ? <KycFormCompany /> : <KycForm />}
      </div>
      <Box m={2}>
      <ReactToPrint
        trigger={() => <Button variant="contained" color="primary">Print</Button>}
        content={() => componentRef.current}
      />
      </Box>
    </>
  );
};

export default Kyc;
