import { useEffect, useState } from 'react';
import { useApiClient } from './apiClient';
import { createContext, useContext } from 'react';
import { AxiosInstance } from 'axios';

declare var Infront: any; //Loaded in HTML
declare var InfrontSDK: any; //Loaded in HTML

interface InfrontToken {
  access_token: string;
  token_type: string;
}

export interface InfrontSDKContext {
  infrontService: InFrontService;
  ready: boolean;
}

export class InFrontService {
  private onReady: (ready: boolean, infrontService: InFrontService) => void;
  private api: AxiosInstance;

  constructor(
    onReadyCallback: (ready: boolean, infrontService: InFrontService) => void,
    apiInstance: AxiosInstance
  ) {
    this.onReady = onReadyCallback;
    this.api = apiInstance;
  }

  infrontSdkReady: boolean = false;
  infrontUI: any;

  initInFront = async () => {
    const response = await this.api.get(`api/InFrontToken/GetToken`);
    const data = response.data as InfrontToken;

    var ui = await this.initInfrontUI(data);
    this.infrontUI = ui;
    this.onReady(true, this);
  };

  initInfrontUI = async (token: InfrontToken): Promise<any> => {
    var infrontUi = new Infront.UI({
      signed_token: token.access_token,
      token_type: token.token_type,
      baseCurrency: 'SEK',
      routingUrl: 'https://mws1.infrontservices.com/mws',
      secureConnection: Infront.ConnectionSecurity.Require,
      useDefaultStateStorage: true,
      language: 'sv',
      streaming: true,
      visualWidgetAccess: true,
      store_session: true,
    });

    return new Promise<any>((resolve, reject) => {
      infrontUi.registerEventObserver('onReady', () => {
        this.infrontSdkReady = true;
        resolve(infrontUi);
      });

      infrontUi.init();
    });
  };
}

export const InFrontContext = createContext<null | InfrontSDKContext>(null);

interface InFrontProviderProps {
  children: React.ReactNode;
}

//export const InFrontProvider: React.FunctionComponent<InFrontProviderProps> = (
export const InFrontProvider = (props: InFrontProviderProps) => {
  const api = useApiClient();

  const [inFrontService, setInFrontService] =
    useState<InfrontSDKContext | null>(null);

  function setInFrontReady(ready: boolean, service: InFrontService) {
    setInFrontService({ ready: ready, infrontService: service });
  }

  useEffect(() => {
    const inFront: InFrontService = new InFrontService(setInFrontReady, api);
    inFront.initInFront();
  }, []);

  return (
    <InFrontContext.Provider value={inFrontService}>
      {props.children}
    </InFrontContext.Provider>
  );
};

export const useInfront = () => {
  const infront = useContext(InFrontContext);

  const getSymbolAndFeedByIsin = async (
    sdk: any,
    isin: string
  ): Promise<[string, number]> => {
    const searchParameters = {
      ISIN: isin,
    };

    const queryForFields = ['Feed', 'Ticker'];

    return new Promise<[string, number]>((resolve, reject) => {
      const options = {
        parameters: searchParameters,
        fields: queryForFields,
        limit: 1,
        onError: (error: any) => {
          reject();
        },
        onData: (data: any) => {
          let unbind = data.observe({
            itemAdded: (item: any, index: number) => {
              resolve([item.get('Feed'), item.get('Ticker')]);
              unbind();
            },
          });
        },
        onStatus: (requestName: string, status: any, extra: any) => {
          // if extra.subCount == 0 we will not get any answer in the data.observe clause, so just return '',0
          if (extra !== undefined && extra.subCount === 0) resolve(['', 0]);
        },
      };
      sdk.get(InfrontSDK.symbolSearch(options));
    });
  };

  return {
    infront,
    getSymbolAndFeedByIsin,
  };
};
