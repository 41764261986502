import { useTranslation } from 'react-i18next';
import PageLayout from '../components/layout/PageLayout';
import TabMenu from '../components/menu/TabMenu';

const MyCoeli = () => {
  return <MyCoeliComponent></MyCoeliComponent>;
};

const MyCoeliComponent = () => {
  const { t } = useTranslation();

  return (
    <PageLayout>
      <TabMenu
        menuItems={[
          { label: t('myCoeli.Overview'), to: '/mycoeli/overview' },
          { label: t('myCoeli.News'), to: '/mycoeli/news' },
        ]}
      />
    </PageLayout>
  );
};

export default MyCoeli;
