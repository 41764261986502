export interface IKycCompanyDto {
  id: string;
  contact1Id: string;
  contact2Id: string;
  accountId: string;
  contactLogicalName: string;
  ownerName: string;
  statuscode: string;
  ownerId: string;
  email: string;
  phoneNumber: string;

  rejectComment: string;

  //Basic Info
  companyName: string;
  organizationNumber: string;
  legalPersonType: number;
  streetAddress: string;
  zipCode: string;
  city: string;
  country: string;

  // representative
  representative1Name: string;
  representative1SocialSecurityNo: string;
  representative1ConnToCustomer: string;
  representative2Name: string;
  representative2SocialSecurityNo: string;
  representative2ConnToCustomer: string;
  ownership: string;
  owner1Name: string;
  owner1Surname: string;
  owner1SocialSecurityNumber: string;
  owner1SharesNumberOfVotesInPercent: number | null;
  owner1TaxDomicileConnectionAbroad: string;
  owner1Country: string;
  owner1tinOrEquivalent: string;
  owner1UsCitizen: string;
  owner1CertifyToCustomerInfoCorrect: string;
  owner2Name: string;
  owner2Surname: string;
  owner2SocialSecurityNumber: string;
  owner2SharesNumberOfVotesInPercent: string;
  owner2TaxDomicileConnectionAbroad: string;
  owner2Country: string;
  owner2tinOrEquivalent: string;
  owner2UsCitizen: string;
  owner2CertifyToCustomerInfoCorrect: string;
  owner3Name: string;
  owner3Surname: string;
  owner3SocialSecurityNumber: string;
  owner3SharesNumberOfVotesInPercent: number | null;
  owner3TaxDomicileConnectionAbroad: string;
  owner3Country: string;
  owner3tinOrEquivalent: string;
  owner3UsCitizen: string;
  owner3CertifyToCustomerInfoCorrect: string;
  owner4Name: string;
  owner4Surname: string;
  owner4SocialSecurityNumber: string;
  owner4SharesNumberOfVotesInPercent: string;
  owner4TaxDomicileConnectionAbroad: string;
  owner4Country: string;
  owner4tinOrEquivalent: string;
  owner4UsCitizen: string;
  owner4CertifyToCustomerInfoCorrect: string;
  companyNameOwnerSection: string;
  organizationNumberOwnerSection: string;
  documentProveOwnershipControl: string;
  documentsDescriptionOwnerControl: string;

  //Politically exposed person
  isRealMandatorPep: string;
  pepName: string;
  pepSocialSecurityNumber: string;
  functionAsPep: string;
  pepFunctionCeased: string;
  anotherPepInLast18Months: string;
  anotherPepName: string;
  anotherPepSocialSecurityNumber: string;
  anotherFunctionAsPep: string;
  anotherPepFunctionCeased: string;
  isFamilyMemberPep: string;
  familyMemberPepName: string;
  familyMemberPepSsn: string;
  familyPepConnectionToCustomer: string;
  familyPepFunction: string;
  familyPepFunctionCeased: string;

  //Business and industry affiliation
  customerMainBusiness: string;
  customerSniCode: string;
  legalPerson: string;

  //Self-declaration regarding tax domicile
  isActualManagementLocatedOutsidese: string;
  taxDomicileOrAffiliation: string;
  taxDomicileOrAffiliationCountry: string;
  formw9Attached: string;
  selfdeclarationTinOrEquivalent: string;
  isAnyOfBelowApplicable: string;
  customerBusinessClassification: string;
  financialInstitutionInvestmentUnit: string;
  formw8bene: string;
  activeNonfinancialEntities: string;
  passiveNonfinancialUnit: string;
  globalIntermediaryIdNumber: string;
  certifyToLegalPersonInfoCorrect: string;

  operatingIncome: boolean;
  returnOnCapitalInvestments: boolean;
  realEstateSales: boolean;
  companiesales: boolean;

  svenskBankOrAnotherFinancialInstitution: boolean;
  foreignBankOrOtheFinancialInstitutionWithinESS: boolean;
  foreignBankOrOtherFinancialInstitutionOutsideESS: boolean;
  capitalInvestment: boolean;
  pension: boolean;
  other: boolean;
  averageExpectedDeposit: string;
  depositFrequency: string;
}

export enum LegalPersonType {
  None = -1,
  LimitedCompany = 963440000,
  TradingCompanyLimitedPartnership = 963440001,
  Foundation = 963440002,
  EconomicAssociation = 963440003,
  Nonprofitassociation = 963440004,
}
