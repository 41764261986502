import { useAuth } from 'react-oidc-context';
import { useAppDispatch } from '../../redux/hooks';
import { clearUsers } from '../../redux/slices/users';

const Logout = () => {
  var auth = useAuth();
  const dispatch = useAppDispatch();

  if (auth.isAuthenticated) {
    sessionStorage.removeItem('coeli_token');
    dispatch(clearUsers());
    auth.signoutRedirect({ id_token_hint: auth.user?.id_token });
    auth.removeUser();
  }
  return <>Logged out</>;
};

export default Logout;
