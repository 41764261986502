import React from 'react';
import { Field, Formik } from 'formik';
import { TextFormField } from '../formfields/TextFormField';
import { RadioGroupFormField } from '../formfields/RadioGroupFormField';

import {
  Grid,
  TextField,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormGroup,
  Checkbox,
} from '@mui/material';
import { withTranslation, WithTranslation } from 'react-i18next';

import {
  understandingCoeliPrivateEquityAdvisorHasTrainedMeTo,
  understandingCoeliRealEstateAdvisorHasTrainedMeTo,
  UnlistedEquitiesTradedPreviouslyOptions,
} from '../../Enums';
import { IInvestorProfileExperience } from '../../models/investorProfileExperience';

export interface IInvestorProfileExperienceProps extends WithTranslation {
  investorProfileExperience: IInvestorProfileExperience;
  isDisabled: boolean;
}

export interface IInvestorProfileExperienceState {}

class InvestorProfileExperience extends React.Component<
  IInvestorProfileExperienceProps,
  IInvestorProfileExperienceState
> {
  constructor(props: IInvestorProfileExperienceProps) {
    super(props);
  }

  handleSubmit = async () => {};

  render(): JSX.Element {
    const t = this.props.t;

    const yesNoOptions = [
      { label: t('Yes'), value: true },
      { label: t('No'), value: false },
    ];

    const investorProfileInvestmentWhenOptions = [
      { value: 963440000, label: t('investorProfile.LessThan3YearsAgo') },
      { value: 963440001, label: t('investorProfile.MoreThan3YearsAgo') },
    ];
    const investorProfileInvestmentSizeOptions = [
      { value: 963440000, label: t('investorProfile.0to300000sek') },
      { value: 963440001, label: t('investorProfile.MoreThan300000sek') },
    ];
    const investorProfileInvestmentFreqOptions = [
      { value: 963440000, label: t('investorProfile.LessThan6Occasions') },
      { value: 963440001, label: t('investorProfile.MoreThan6Occasions') },
    ];

    const unlistedEquitiesTradedWithPreviouslyOptions = [
      { label: t('Yes'), value: 963440000 },
      {
        label: t(
          'investorProfile.unlistedEquitiesTradedPreviously.YesOwnedCompany'
        ),
        value: 963440001,
      },
      {
        label: t(
          'investorProfile.unlistedEquitiesTradedPreviously.NoInterested'
        ),
        value: 963440002,
      },
      {
        label: t(
          'investorProfile.unlistedEquitiesTradedPreviously.NoNotRelevant'
        ),
        value: 963440003,
      },
    ];

    return (
      <>
        <Formik
          initialValues={{ ...this.props.investorProfileExperience }}
          onSubmit={this.handleSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            handleReset,
            setFieldValue,
            isSubmitting,
            touched,
            values,
            status,
          }) => (
            <>
              <Grid item xs={12} mt={4}>
                <Field
                  name="contactName"
                  component={TextFormField}
                  disabled
                  label={t('Representative')}
                />
              </Grid>
              <Grid item xs={12} mt={4}>
                <Field
                  name="experienceProfession"
                  component={RadioGroupFormField}
                  options={yesNoOptions}
                  isDisabled={this.props.isDisabled}
                  label={
                    t('investorProfile.Experience') +
                    ' - ' +
                    t('investorProfile.Profession')
                  }
                />
              </Grid>
              {values.experienceProfession ? (
                <Grid item xs={12} mt={4}>
                  <Field
                    name="professionWhich"
                    label={
                      t('investorProfile.Profession') +
                      ' - ' +
                      t('investorProfile.Which')
                    }
                    variant="outlined"
                    component={TextFormField}
                    disabled={this.props.isDisabled}
                  />
                </Grid>
              ) : null}

              <Grid item xs={12} mt={4}>
                <Field
                  name="experienceEducation"
                  component={RadioGroupFormField}
                  options={yesNoOptions}
                  isDisabled={this.props.isDisabled}
                  label={
                    t('investorProfile.Experience') +
                    ' - ' +
                    t('investorProfile.Education')
                  }
                />
              </Grid>
              {values.experienceEducation ? (
                <Grid item xs={12} mt={4}>
                  <Field
                    name="educationWhich"
                    label={
                      t('investorProfile.Education') +
                      ' - ' +
                      t('investorProfile.Which')
                    }
                    variant="outlined"
                    component={TextFormField}
                    disabled={this.props.isDisabled}
                  />
                </Grid>
              ) : null}

              <Grid item xs={12} mt={4}>
                <Field
                  name="listedEquityInvestPrev"
                  component={RadioGroupFormField}
                  options={yesNoOptions}
                  isDisabled={this.props.isDisabled}
                  label={
                    t('investorProfile.ListedEquity') +
                    ' - ' +
                    t('investorProfile.TradedPreviously')
                  }
                />
              </Grid>
              {values.listedEquityInvestPrev ? (
                <>
                  <Grid item xs={12} mt={4}>
                    <Field
                      name="listedEquityInvestWhen"
                      component={RadioGroupFormField}
                      options={investorProfileInvestmentWhenOptions}
                      isDisabled={this.props.isDisabled}
                      label={
                        t('investorProfile.ListedEquity') +
                        ' - ' +
                        t('investorProfile.When')
                      }
                    />
                  </Grid>
                  <Grid item xs={12} mt={4}>
                    <Field
                      name="listedEquityInvestSize"
                      component={RadioGroupFormField}
                      options={investorProfileInvestmentSizeOptions}
                      isDisabled={this.props.isDisabled}
                      label={
                        t('investorProfile.ListedEquity') +
                        ' - ' +
                        t('investorProfile.InvestmentSize')
                      }
                    />
                  </Grid>
                  <Grid item xs={12} mt={4}>
                    <Field
                      name="listedEquityInvestFreq"
                      component={RadioGroupFormField}
                      options={investorProfileInvestmentFreqOptions}
                      isDisabled={this.props.isDisabled}
                      label={
                        t('investorProfile.ListedEquity') +
                        ' - ' +
                        t('investorProfile.InvestmentFrequency')
                      }
                    />
                  </Grid>
                  <Grid item xs={12} mt={4}>
                    <Field
                      name="understandingRisksTradingListedEquity"
                      component={RadioGroupFormField}
                      options={yesNoOptions}
                      isDisabled={this.props.isDisabled}
                      label={t(
                        'investorProfile.UnderstandingTradingRisksShares'
                      )}
                    />
                  </Grid>
                </>
              ) : null}
              <Grid item xs={12} mt={4}>
                <Field
                  name="equityFundsInvestPrev"
                  component={RadioGroupFormField}
                  options={yesNoOptions}
                  isDisabled={this.props.isDisabled}
                  label={
                    t('investorProfile.EquityFunds') +
                    ' - ' +
                    t('investorProfile.TradedPreviously')
                  }
                />
              </Grid>
              {values.equityFundsInvestPrev ? (
                <>
                  <Grid item xs={12} mt={4}>
                    <Field
                      name="equityFundsInvestWhen"
                      component={RadioGroupFormField}
                      options={investorProfileInvestmentWhenOptions}
                      isDisabled={this.props.isDisabled}
                      label={
                        t('investorProfile.EquityFunds') +
                        ' - ' +
                        t('investorProfile.When')
                      }
                    />
                  </Grid>
                  <Grid item xs={12} mt={4}>
                    <Field
                      name="equityFundsInvestSize"
                      component={RadioGroupFormField}
                      options={investorProfileInvestmentSizeOptions}
                      isDisabled={this.props.isDisabled}
                      label={
                        t('investorProfile.EquityFunds') +
                        ' - ' +
                        t('investorProfile.InvestmentSize')
                      }
                    />
                  </Grid>
                  <Grid item xs={12} mt={4}>
                    <Field
                      name="equityFundsInvestFreq"
                      component={RadioGroupFormField}
                      options={investorProfileInvestmentFreqOptions}
                      isDisabled={this.props.isDisabled}
                      label={
                        t('investorProfile.EquityFunds') +
                        ' - ' +
                        t('investorProfile.InvestmentFrequency')
                      }
                    />
                  </Grid>
                  <Grid item xs={12} mt={4}>
                    <Field
                      name="understandRisksTradingEquityFunds"
                      component={RadioGroupFormField}
                      options={yesNoOptions}
                      isDisabled={this.props.isDisabled}
                      label={t(
                        'investorProfile.UnderstandingTradingRiskEquityFunds'
                      )}
                    />
                  </Grid>
                </>
              ) : null}
              <Grid item xs={12} mt={4}>
                <Field
                  name="mixFundsInvestPrev"
                  component={RadioGroupFormField}
                  options={yesNoOptions}
                  isDisabled={this.props.isDisabled}
                  label={
                    t(
                      'investorProfile.MixedFundsBroadDiscretionaryManagement'
                    ) +
                    ' - ' +
                    t('investorProfile.TradedPreviously')
                  }
                />
              </Grid>
              {values.mixFundsInvestPrev ? (
                <>
                  <Grid item xs={12} mt={4}>
                    <Field
                      name="mixFundsInvestWhen"
                      component={RadioGroupFormField}
                      options={investorProfileInvestmentWhenOptions}
                      isDisabled={this.props.isDisabled}
                      label={
                        t(
                          'investorProfile.MixedFundsBroadDiscretionaryManagement'
                        ) +
                        ' - ' +
                        t('investorProfile.When')
                      }
                    />
                  </Grid>
                  <Grid item xs={12} mt={4}>
                    <Field
                      name="mixFundsInvestSize"
                      component={RadioGroupFormField}
                      options={investorProfileInvestmentSizeOptions}
                      isDisabled={this.props.isDisabled}
                      label={
                        t(
                          'investorProfile.MixedFundsBroadDiscretionaryManagement'
                        ) +
                        ' - ' +
                        t('investorProfile.InvestmentSize')
                      }
                    />
                  </Grid>
                  <Grid item xs={12} mt={4}>
                    <Field
                      name="mixFundsInvestFreq"
                      component={RadioGroupFormField}
                      options={investorProfileInvestmentFreqOptions}
                      isDisabled={this.props.isDisabled}
                      label={
                        t(
                          'investorProfile.MixedFundsBroadDiscretionaryManagement'
                        ) +
                        ' - ' +
                        t('investorProfile.InvestmentFrequency')
                      }
                    />
                  </Grid>
                  <Grid item xs={12} mt={4}>
                    <Field
                      name="understandRisksTradingMixFunds"
                      component={RadioGroupFormField}
                      options={yesNoOptions}
                      isDisabled={this.props.isDisabled}
                      label={t(
                        'investorProfile.UnderstandingTradingRiskMFoundBdm'
                      )}
                    />
                  </Grid>
                </>
              ) : null}
              {/* certificate */}
              <Grid item xs={12} mt={4}>
                <Field
                  name="certificateInvestPrev"
                  component={RadioGroupFormField}
                  options={yesNoOptions}
                  isDisabled={this.props.isDisabled}
                  label={
                    t('investorProfile.Certificate') +
                    ' - ' +
                    t('investorProfile.TradedPreviously')
                  }
                />
              </Grid>
              {values.certificateInvestPrev ? (
                <>
                  <Grid item xs={12} mt={4}>
                    <Field
                      name="certificateInvestWhen"
                      component={RadioGroupFormField}
                      options={investorProfileInvestmentWhenOptions}
                      isDisabled={this.props.isDisabled}
                      label={
                        t('investorProfile.Certificate') +
                        ' - ' +
                        t('investorProfile.When')
                      }
                    />
                  </Grid>
                  <Grid item xs={12} mt={4}>
                    <Field
                      name="certificateInvestSize"
                      component={RadioGroupFormField}
                      options={investorProfileInvestmentSizeOptions}
                      isDisabled={this.props.isDisabled}
                      label={
                        t('investorProfile.Certificate') +
                        ' - ' +
                        t('investorProfile.InvestmentSize')
                      }
                    />
                  </Grid>
                  <Grid item xs={12} mt={4}>
                    <Field
                      name="certificateInvestFreq"
                      component={RadioGroupFormField}
                      options={investorProfileInvestmentFreqOptions}
                      isDisabled={this.props.isDisabled}
                      label={
                        t('investorProfile.Certificate') +
                        ' - ' +
                        t('investorProfile.InvestmentFrequency')
                      }
                    />
                  </Grid>
                </>
              ) : null}
              <Grid item xs={12} mt={4}>
                <Field
                  name="understandRisksTradingCertificate"
                  component={RadioGroupFormField}
                  options={yesNoOptions}
                  isDisabled={this.props.isDisabled}
                  label={t('investorProfile.UnderstandRisksTradingCertificate')}
                />
              </Grid>
              {/* etl */}
              <Grid item xs={12} mt={4}>
                <Field
                  name="etfInvestPrev"
                  component={RadioGroupFormField}
                  options={yesNoOptions}
                  isDisabled={this.props.isDisabled}
                  label={
                    t('investorProfile.Etf') +
                    ' - ' +
                    t('investorProfile.TradedPreviously')
                  }
                />
              </Grid>
              {values.etfInvestPrev ? (
                <>
                  <Grid item xs={12} mt={4}>
                    <Field
                      name="eTFInvestWhen"
                      component={RadioGroupFormField}
                      options={investorProfileInvestmentWhenOptions}
                      isDisabled={this.props.isDisabled}
                      label={
                        t('investorProfile.Etf') +
                        ' - ' +
                        t('investorProfile.When')
                      }
                    />
                  </Grid>
                  <Grid item xs={12} mt={4}>
                    <Field
                      name="etfInvestSize"
                      component={RadioGroupFormField}
                      options={investorProfileInvestmentSizeOptions}
                      isDisabled={this.props.isDisabled}
                      label={
                        t('investorProfile.Etf') +
                        ' - ' +
                        t('investorProfile.InvestmentSize')
                      }
                    />
                  </Grid>
                  <Grid item xs={12} mt={4}>
                    <Field
                      name="etfInvestFreq"
                      component={RadioGroupFormField}
                      options={investorProfileInvestmentFreqOptions}
                      isDisabled={this.props.isDisabled}
                      label={
                        t('investorProfile.Etf') +
                        ' - ' +
                        t('investorProfile.InvestmentFrequency')
                      }
                    />
                  </Grid>
                </>
              ) : null}
              <Grid item xs={12} mt={4}>
                <Field
                  name="understandRisksTradingETF"
                  component={RadioGroupFormField}
                  options={yesNoOptions}
                  isDisabled={this.props.isDisabled}
                  label={t('investorProfile.UnderstandRisksTradingEtf')}
                />
              </Grid>
              {/* end */}
              <Grid item xs={12} mt={4}>
                <Field
                  name="alternativeFundHedgeFundInvestPrev"
                  component={RadioGroupFormField}
                  options={yesNoOptions}
                  isDisabled={this.props.isDisabled}
                  label={
                    t('investorProfile.AlternativeFundsHedgeFunds') +
                    ' - ' +
                    t('investorProfile.TradedPreviously')
                  }
                />
              </Grid>
              {values.alternativeFundHedgeFundInvestPrev ? (
                <>
                  <Grid item xs={12} mt={4}>
                    <Field
                      name="alternativeFundHedgeFundInvestWhen"
                      component={RadioGroupFormField}
                      options={investorProfileInvestmentWhenOptions}
                      isDisabled={this.props.isDisabled}
                      label={
                        t('investorProfile.AlternativeFundsHedgeFunds') +
                        ' - ' +
                        t('investorProfile.When')
                      }
                    />
                  </Grid>
                  <Grid item xs={12} mt={4}>
                    <Field
                      name="alternativeFundHedgeFundInvestSize"
                      component={RadioGroupFormField}
                      options={investorProfileInvestmentSizeOptions}
                      isDisabled={this.props.isDisabled}
                      label={
                        t('investorProfile.AlternativeFundsHedgeFunds') +
                        ' - ' +
                        t('investorProfile.InvestmentSize')
                      }
                    />
                  </Grid>
                  <Grid item xs={12} mt={4}>
                    <Field
                      name="alternativeFundHedgeFundInvestFreq"
                      component={RadioGroupFormField}
                      options={investorProfileInvestmentFreqOptions}
                      isDisabled={this.props.isDisabled}
                      label={
                        t('investorProfile.AlternativeFundsHedgeFunds') +
                        ' - ' +
                        t('investorProfile.InvestmentFrequency')
                      }
                    />
                  </Grid>
                  <Grid item xs={12} mt={4}>
                    <Field
                      name="understandRisksTradingAlternativeFundHedgeFund"
                      component={RadioGroupFormField}
                      options={yesNoOptions}
                      isDisabled={this.props.isDisabled}
                      label={t(
                        'investorProfile.UnderstandTradingRiskAlterHedgeF'
                      )}
                    />
                  </Grid>
                </>
              ) : null}
              <Grid item xs={12} mt={4}>
                <Field
                  name="incomeFundsInvestPrev"
                  component={RadioGroupFormField}
                  options={yesNoOptions}
                  isDisabled={this.props.isDisabled}
                  label={
                    t('investorProfile.IncomeFunds') +
                    ' - ' +
                    t('investorProfile.TradedPreviously')
                  }
                />
              </Grid>
              {values.incomeFundsInvestPrev ? (
                <>
                  <Grid item xs={12} mt={4}>
                    <Field
                      name="incomeFundsInvestWhen"
                      component={RadioGroupFormField}
                      options={investorProfileInvestmentWhenOptions}
                      isDisabled={this.props.isDisabled}
                      label={
                        t('investorProfile.IncomeFunds') +
                        ' - ' +
                        t('investorProfile.When')
                      }
                    />
                  </Grid>
                  <Grid item xs={12} mt={4}>
                    <Field
                      name="incomeFundsInvestSize"
                      component={RadioGroupFormField}
                      options={investorProfileInvestmentSizeOptions}
                      isDisabled={this.props.isDisabled}
                      label={
                        t('investorProfile.IncomeFunds') +
                        ' - ' +
                        t('investorProfile.InvestmentSize')
                      }
                    />
                  </Grid>
                  <Grid item xs={12} mt={4}>
                    <Field
                      name="incomeFundsInvestFreq"
                      component={RadioGroupFormField}
                      options={investorProfileInvestmentFreqOptions}
                      isDisabled={this.props.isDisabled}
                      label={
                        t('investorProfile.IncomeFunds') +
                        ' - ' +
                        t('investorProfile.InvestmentFrequency')
                      }
                    />
                  </Grid>
                  <Grid item xs={12} mt={4}>
                    <Field
                      name="understandRisksTradingIncomeFunds"
                      component={RadioGroupFormField}
                      options={yesNoOptions}
                      isDisabled={this.props.isDisabled}
                      label={t(
                        'investorProfile.UnderstandingTradingRiskIncomeFund'
                      )}
                    />
                  </Grid>
                </>
              ) : null}
              <Grid item xs={12} mt={4}>
                <Field
                  name="interestBearingInstrumentsInvestPrev"
                  component={RadioGroupFormField}
                  options={yesNoOptions}
                  isDisabled={this.props.isDisabled}
                  label={
                    t('investorProfile.InterestBearingInstruments') +
                    ' - ' +
                    t('investorProfile.TradedPreviously')
                  }
                />
              </Grid>
              {values.interestBearingInstrumentsInvestPrev ? (
                <>
                  <Grid item xs={12} mt={4}>
                    <Field
                      name="interestBearingInstrumentsInvestWhen"
                      component={RadioGroupFormField}
                      options={investorProfileInvestmentWhenOptions}
                      isDisabled={this.props.isDisabled}
                      label={
                        t('investorProfile.InterestBearingInstruments') +
                        ' - ' +
                        t('investorProfile.When')
                      }
                    />
                  </Grid>
                  <Grid item xs={12} mt={4}>
                    <Field
                      name="interestBearingInstrumentsInvestSize"
                      component={RadioGroupFormField}
                      options={investorProfileInvestmentSizeOptions}
                      isDisabled={this.props.isDisabled}
                      label={
                        t('investorProfile.InterestBearingInstruments') +
                        ' - ' +
                        t('investorProfile.InvestmentSize')
                      }
                    />
                  </Grid>
                  <Grid item xs={12} mt={4}>
                    <Field
                      name="interestBearingInstrumentsInvestFreq"
                      component={RadioGroupFormField}
                      options={investorProfileInvestmentFreqOptions}
                      isDisabled={this.props.isDisabled}
                      label={
                        t('investorProfile.InterestBearingInstruments') +
                        ' - ' +
                        t('investorProfile.InvestmentFrequency')
                      }
                    />
                  </Grid>
                  <Grid item xs={12} mt={4}>
                    <Field
                      name="understandingRisksTradingInterestBearingInstruments"
                      component={RadioGroupFormField}
                      options={yesNoOptions}
                      isDisabled={this.props.isDisabled}
                      label={t(
                        'investorProfile.UnderstanTradeRiskInterestBearing'
                      )}
                    />
                  </Grid>
                </>
              ) : null}
              <Grid item xs={12} mt={4}>
                <Field
                  name="privateEquityInvestPrev"
                  component={RadioGroupFormField}
                  options={yesNoOptions}
                  isDisabled={this.props.isDisabled}
                  label={
                    t('investorProfile.CoeliPrivateEquity') +
                    ' - ' +
                    t('investorProfile.TradedPreviously')
                  }
                />
              </Grid>
              {!values.privateEquityInvestPrev ? (
                <>
                  <Grid item xs={12} mt={4}>
                    <FormControl
                      sx={{ m: 2 }}
                      component="fieldset"
                      variant="standard"
                    >
                      <FormLabel component="legend">
                        {t(
                          'investorProfile.UnderstanTradeRiskCoeliPrivateEquity'
                        )}
                      </FormLabel>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.understandPrivateEquityAdvTrainedMeValues?.some(
                                (p) =>
                                  p ==
                                  understandingCoeliPrivateEquityAdvisorHasTrainedMeTo.ThatInvestmentsAreAadeIndirectlyInANumberOfUnlistedShares
                              )}
                              onChange={handleChange}
                              name="operatingIncome"
                            />
                          }
                          disabled={this.props.isDisabled}
                          label={t(
                            'investorProfile.ThatInvestmentsAreAadeIndirectlyInANumberOfUnlistedShares'
                          )}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.understandPrivateEquityAdvTrainedMeValues?.some(
                                (p) =>
                                  p ==
                                  understandingCoeliPrivateEquityAdvisorHasTrainedMeTo.ThatTheShareMayHaveALimitedLiquidityBasedOnTurnover
                              )}
                              onChange={handleChange}
                              name="operatingIncome"
                            />
                          }
                          disabled={this.props.isDisabled}
                          label={t(
                            'investorProfile.ThatTheShareMayHaveALimitedLiquidityBasedOnTurnover'
                          )}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.understandPrivateEquityAdvTrainedMeValues?.some(
                                (p) =>
                                  p ==
                                  understandingCoeliPrivateEquityAdvisorHasTrainedMeTo.TheShareIsSuitableForTheLongTermInvestor
                              )}
                              onChange={handleChange}
                              name="operatingIncome"
                            />
                          }
                          disabled={this.props.isDisabled}
                          label={t(
                            'investorProfile.TheShareIsSuitableForTheLongTermInvestor'
                          )}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.understandPrivateEquityAdvTrainedMeValues?.some(
                                (p) =>
                                  p ==
                                  understandingCoeliPrivateEquityAdvisorHasTrainedMeTo.TheInvestmentIsNotRelevantToMe
                              )}
                              onChange={handleChange}
                              name="operatingIncome"
                            />
                          }
                          disabled={this.props.isDisabled}
                          label={t(
                            'investorProfile.TheInvestmentIsNotRelevantToMe'
                          )}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.understandPrivateEquityAdvTrainedMeValues?.some(
                                (p) =>
                                  p ==
                                  understandingCoeliPrivateEquityAdvisorHasTrainedMeTo.ThereIsAConflictOfInterest
                              )}
                              onChange={handleChange}
                              name="operatingIncome"
                            />
                          }
                          disabled={this.props.isDisabled}
                          label={t(
                            'investorProfile.ThereIsAConflictOfInterest'
                          )}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>
                </>
              ) : null}
              {(values.understandPrivateEquityAdvTrainedMeValues != undefined &&
                values.understandPrivateEquityAdvTrainedMeValues?.indexOf(
                  understandingCoeliPrivateEquityAdvisorHasTrainedMeTo.TheInvestmentIsNotRelevantToMe
                ) === -1) ||
              values.understandPrivateEquityAdvTrainedMeValues === null ? (
                <>
                  <Grid item xs={12} mt={4}>
                    <Field
                      name="privateEquityInvestWhen"
                      component={RadioGroupFormField}
                      options={investorProfileInvestmentWhenOptions}
                      isDisabled={this.props.isDisabled}
                      label={
                        t('investorProfile.CoeliPrivateEquity') +
                        ' - ' +
                        t('investorProfile.When')
                      }
                    />
                  </Grid>
                  <Grid item xs={12} mt={4}>
                    <Field
                      name="privateEquityInvestSize"
                      component={RadioGroupFormField}
                      options={investorProfileInvestmentSizeOptions}
                      isDisabled={this.props.isDisabled}
                      label={
                        t('investorProfile.CoeliPrivateEquity') +
                        ' - ' +
                        t('investorProfile.InvestmentSize')
                      }
                    />
                  </Grid>
                  <Grid item xs={12} mt={4}>
                    <Field
                      name="privateEquityInvestFreq"
                      component={RadioGroupFormField}
                      options={investorProfileInvestmentFreqOptions}
                      isDisabled={this.props.isDisabled}
                      label={
                        t('investorProfile.CoeliPrivateEquity') +
                        ' - ' +
                        t('investorProfile.InvestmentFrequency')
                      }
                    />
                  </Grid>
                </>
              ) : null}
              <Grid item xs={12} mt={4}>
                <Field
                  name="realEstateInvestPrev"
                  component={RadioGroupFormField}
                  options={yesNoOptions}
                  isDisabled={this.props.isDisabled}
                  label={
                    t('investorProfile.CoeliRealEstate') +
                    ' - ' +
                    t('investorProfile.TradedPreviously')
                  }
                />
              </Grid>
              {values.realEstateInvestPrev ? (
                <>
                  <Grid item xs={12} mt={4}>
                    <FormControl
                      sx={{ m: 2 }}
                      component="fieldset"
                      variant="standard"
                    >
                      <FormLabel component="legend">
                        {t(
                          'investorProfile.UnderstandTradeRiskCoeliRealEstateAdvisor'
                        )}
                      </FormLabel>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.understandRealeStateAdvTrainedMeValues?.some(
                                (p) =>
                                  p ==
                                  understandingCoeliRealEstateAdvisorHasTrainedMeTo.ThatInvestmentsAreAadeIndirectlyInANumberOfProperties
                              )}
                              onChange={handleChange}
                              name="operatingIncome"
                            />
                          }
                          disabled={this.props.isDisabled}
                          label={t(
                            'investorProfile.ThatInvestmentsAreAadeIndirectlyInANumberOfProperties'
                          )}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.understandRealeStateAdvTrainedMeValues?.some(
                                (p) =>
                                  p ==
                                  understandingCoeliPrivateEquityAdvisorHasTrainedMeTo.ThatTheShareMayHaveALimitedLiquidityBasedOnTurnover
                              )}
                              onChange={handleChange}
                              name="operatingIncome"
                            />
                          }
                          disabled={this.props.isDisabled}
                          label={t(
                            'investorProfile.ThatTheShareMayHaveALimitedLiquidityBasedOnTurnover'
                          )}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.understandRealeStateAdvTrainedMeValues?.some(
                                (p) =>
                                  p ==
                                  understandingCoeliRealEstateAdvisorHasTrainedMeTo.TheShareIsSuitableForTheLongTermInvestor
                              )}
                              onChange={handleChange}
                              name="operatingIncome"
                            />
                          }
                          disabled={this.props.isDisabled}
                          label={t(
                            'investorProfile.TheShareIsSuitableForTheLongTermInvestor'
                          )}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.understandRealeStateAdvTrainedMeValues?.some(
                                (p) =>
                                  p ==
                                  understandingCoeliRealEstateAdvisorHasTrainedMeTo.ThereIsAConflictOfInterest
                              )}
                              onChange={handleChange}
                              name="operatingIncome"
                            />
                          }
                          disabled={this.props.isDisabled}
                          label={t(
                            'investorProfile.ThereIsAConflictOfInterest'
                          )}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.understandRealeStateAdvTrainedMeValues?.some(
                                (p) =>
                                  p ==
                                  understandingCoeliRealEstateAdvisorHasTrainedMeTo.TheInvestmentIsNotRelevantToMe
                              )}
                              onChange={handleChange}
                              name="operatingIncome"
                            />
                          }
                          disabled={this.props.isDisabled}
                          label={t(
                            'investorProfile.TheInvestmentIsNotRelevantToMe'
                          )}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>
                </>
              ) : null}
              {values.understandRealeStateAdvTrainedMeValues != undefined &&
              values.understandRealeStateAdvTrainedMeValues?.indexOf(
                understandingCoeliRealEstateAdvisorHasTrainedMeTo.TheInvestmentIsNotRelevantToMe
              ) > -1 ? (
                <>
                  <Grid item xs={12} mt={4}>
                    <Field
                      name="realEstateInvestWhen"
                      component={RadioGroupFormField}
                      options={investorProfileInvestmentWhenOptions}
                      isDisabled={this.props.isDisabled}
                      label={
                        t('investorProfile.CoeliRealEstate') +
                        ' - ' +
                        t('investorProfile.When')
                      }
                    />
                  </Grid>
                  <Grid item xs={12} mt={4}>
                    <Field
                      name="realEstateInvestSize"
                      component={RadioGroupFormField}
                      options={investorProfileInvestmentSizeOptions}
                      isDisabled={this.props.isDisabled}
                      label={
                        t('investorProfile.CoeliRealEstate') +
                        ' - ' +
                        t('investorProfile.InvestmentSize')
                      }
                    />
                  </Grid>
                  <Grid item xs={12} mt={4}>
                    <Field
                      name="realEstateInvestFreq"
                      component={RadioGroupFormField}
                      options={investorProfileInvestmentFreqOptions}
                      isDisabled={this.props.isDisabled}
                      label={
                        t('investorProfile.CoeliRealEstate') +
                        ' - ' +
                        t('investorProfile.InvestmentFrequency')
                      }
                    />
                  </Grid>
                </>
              ) : null}
              <Grid item xs={12} mt={4}>
                <Field
                  name="understandRisksTradingRealEstate"
                  component={RadioGroupFormField}
                  options={yesNoOptions}
                  isDisabled={this.props.isDisabled}
                  label={t('investorProfile.UnderstanTradeRiskCoeliRealEstate')}
                />
              </Grid>
              <Grid item xs={12} mt={4}>
                <Field
                  name="unlistedEquitiesInvestPrev"
                  component={RadioGroupFormField}
                  options={unlistedEquitiesTradedWithPreviouslyOptions}
                  isDisabled={this.props.isDisabled}
                  label={
                    t('investorProfile.UnlistedEquities') +
                    ' - ' +
                    t('investorProfile.TradedPreviously')
                  }
                />
              </Grid>
              {values.unlistedEquitiesInvestPrev ==
                UnlistedEquitiesTradedPreviouslyOptions.Yes ||
              values.unlistedEquitiesInvestPrev ==
                UnlistedEquitiesTradedPreviouslyOptions.YesOwnedOrPartlyOwnedCompanies ? (
                <>
                  <Grid item xs={12} mt={4}>
                    <Field
                      name="unlistedEquitiesInvestWhen"
                      component={RadioGroupFormField}
                      options={investorProfileInvestmentWhenOptions}
                      isDisabled={this.props.isDisabled}
                      label={
                        t('investorProfile.UnlistedEquities') +
                        ' - ' +
                        t('investorProfile.When')
                      }
                    />
                  </Grid>
                  <Grid item xs={12} mt={4}>
                    <Field
                      name="unlistedEquitiesInvestSize"
                      component={RadioGroupFormField}
                      options={investorProfileInvestmentSizeOptions}
                      isDisabled={this.props.isDisabled}
                      label={
                        t('investorProfile.UnlistedEquities') +
                        ' - ' +
                        t('investorProfile.InvestmentSize')
                      }
                    />
                  </Grid>
                  <Grid item xs={12} mt={4}>
                    <Field
                      name="unlistedEquitiesInvestFreq"
                      component={RadioGroupFormField}
                      options={investorProfileInvestmentFreqOptions}
                      isDisabled={this.props.isDisabled}
                      label={
                        t('investorProfile.UnlistedEquities') +
                        ' - ' +
                        t('investorProfile.InvestmentFrequency')
                      }
                    />
                  </Grid>
                </>
              ) : null}
              {values.unlistedEquitiesInvestPrev ==
              UnlistedEquitiesTradedPreviouslyOptions.NoInterested ? (
                <>
                  <Grid item xs={12} mt={4}>
                    <Field
                      name="understandUnlistedEquitiesTrainedByAdvisor"
                      component={RadioGroupFormField}
                      options={yesNoOptions}
                      isDisabled={this.props.isDisabled}
                      label={
                        t('investorProfile.UnlistedEquities') +
                        ' - ' +
                        t('investorProfile.TrainedByAdvisors')
                      }
                    />
                  </Grid>
                </>
              ) : null}
              {values.unlistedEquitiesInvestPrev !=
              UnlistedEquitiesTradedPreviouslyOptions.NoNotRelevant ? (
                <>
                  <Grid item xs={12} mt={4}>
                    <Field
                      name="understandUnlistedEquitiesCanLoseAll"
                      component={RadioGroupFormField}
                      options={yesNoOptions}
                      isDisabled={this.props.isDisabled}
                      label={
                        t('investorProfile.UnderstandingUnlistedEquities') +
                        ' - ' +
                        t(
                          'investorProfile.UnderstandingUnlistedEquitiesLoseAllCapital'
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={12} mt={4}>
                    <Field
                      name="understandUnlistedEquitiesTransferRestrictions"
                      component={RadioGroupFormField}
                      options={yesNoOptions}
                      isDisabled={this.props.isDisabled}
                      label={
                        t('investorProfile.UnderstandingUnlistedEquities') +
                        ' - ' +
                        t(
                          'investorProfile.UnderstandingUnlistedEquitiesTransferRestrictionsInUnlistedShares'
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={12} mt={4}>
                    <Field
                      name="understandUnlistedEquitiesMayBeFees"
                      component={RadioGroupFormField}
                      options={yesNoOptions}
                      isDisabled={this.props.isDisabled}
                      label="Understanding Unlisted Equities - "
                    />
                  </Grid>
                  <Grid item xs={12} mt={4}>
                    <Field
                      name="understandUnlistedEquitiesDifficultyInfo"
                      component={RadioGroupFormField}
                      options={yesNoOptions}
                      isDisabled={this.props.isDisabled}
                      label={
                        t('investorProfile.UnderstandingUnlistedEquities') +
                        ' - ' +
                        t(
                          'investorProfile.UnderstandingUnlistedEquitiesUnlistedCompanies'
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={12} mt={4}>
                    <Field
                      name="understandUnlistedEquitiesDifficultySell"
                      component={RadioGroupFormField}
                      options={yesNoOptions}
                      isDisabled={this.props.isDisabled}
                      label={
                        t('investorProfile.UnderstandingUnlistedEquities') +
                        ' - ' +
                        t(
                          'investorProfile.UnderstandingUnlistedEquitiesUnlistedshares'
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={12} mt={4}>
                    <Field
                      name="understandUnlistedEquitiesSale5yOrMore"
                      component={RadioGroupFormField}
                      options={yesNoOptions}
                      isDisabled={this.props.isDisabled}
                      label={
                        t('investorProfile.UnderstandingUnlistedEquities') +
                        ' - ' +
                        t(
                          'investorProfile.UnderstandingUnlistedEquitiesInvestmentSuitableForSale'
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={12} mt={4}>
                    <Field
                      name="noSufficientUnderstanding"
                      component={RadioGroupFormField}
                      options={yesNoOptions}
                      isDisabled={this.props.isDisabled}
                      label={t(
                        'investorProfile.LacksUnderstandingOfUnlistedEquities'
                      )}
                    />
                  </Grid>
                </>
              ) : null}
              <Grid item xs={12} mt={4}>
                <TextField
                  name="summaryExperienceAndTraining"
                  label={t('investorProfile.SummaryExperienceAndTraining')}
                  value={values.summaryExperienceAndTraining}
                  fullWidth
                  multiline
                  minRows={4}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  variant="outlined"
                  disabled={this.props.isDisabled}
                />
              </Grid>
            </>
          )}
        </Formik>
      </>
    );
  }
}

export default withTranslation()(InvestorProfileExperience);
