import {
  DataGridPremium as DataGrid,
  GridColDef,
  GridComparatorFn,
  GridRenderCellParams,
} from '@mui/x-data-grid-premium';
import { useEffect, useMemo, useState } from 'react';
import { FaPortfolioPositionsDto } from '../../models/dto/faPortfolioPositionDto';
import { FaPositionDto } from '../../models/dto/faPositionDto';
import { FaFilteredSecurityDto } from '../../models/dto/faSecurityDto';
import { useAppSelector } from '../../redux/hooks';
import { getActiveUser } from '../../redux/stateFuncs';
import { useApiClient } from '../../hooks/apiClient';
import CurrencyField from '../formfields/CurrencyField';
import NumberField from '../formfields/NumberField';
import PercentageField from '../formfields/PercentageField';
import { getSecurityDetailLink } from '../../util/common';
import { useTranslation } from 'react-i18next';
import {
  Box,
  CircularProgress,
  Link,
  Typography,
  useMediaQuery,
} from '@mui/material';

interface IPosition extends FaPositionDto {
  key: string;
}

const FaFilteredSecurityDtoComparator: GridComparatorFn<
  FaFilteredSecurityDto
> = (s1, s2) => (s1.name > s2.name ? 1 : -1);

export default function PostionsWidget() {
  const matches1400 = useMediaQuery('(min-width:1400px)');
  const matches1280 = useMediaQuery('(min-width:1280px)');
  const matches1100 = useMediaQuery('(min-width:1100px)');
  const matches700 = useMediaQuery('(min-width:700px)');
  const matches560 = useMediaQuery('(min-width:560px)');

  const { t } = useTranslation();

  const columnsDesktop = useMemo<GridColDef<IPosition>[]>(
    () => [
      {
        field: 'security',
        headerName: t('Security'),
        flex: 2.15,
        minWidth: 140,
        aggregable: false,
        type: 'string',
        renderCell: (
          params: GridRenderCellParams<IPosition, FaFilteredSecurityDto>
        ) => {
          const link = getSecurityDetailLink(params.value!);

          if (link !== '') {
            return (
              <Link className="position-link" href={link} target="_blank">
                {params.value?.name}
              </Link>
            );
          } else {
            return <>{params.value?.name}</>;
          }
        },
        sortComparator: FaFilteredSecurityDtoComparator,
      },
      {
        field: 'valueChangeRelative',
        headerName: t('Progress') + ' %',
        headerAlign: 'right',
        type: 'number',
        aggregable: false,
        renderCell: (params: GridRenderCellParams<IPosition, number>) => (
          <PercentageField value={params.value}></PercentageField>
        ),
        align: 'right',
        flex: 0.5,
        minWidth: 64,
      },
      {
        field: 'amount',
        headerName: t('tradeOrders.Amount'),
        renderCell: (params: GridRenderCellParams<IPosition, number>) => (
          <NumberField value={params.value}></NumberField>
        ),
        align: 'right',
        headerAlign: 'right',
        flex: 0.6,
        minWidth: 56,
        aggregable: false,
      },
      {
        field: 'latestMarketPrice',
        headerName: t('MarketPrice'),
        renderCell: (params: GridRenderCellParams<IPosition, number>) => (
          <CurrencyField value={params.value}></CurrencyField>
        ),
        align: 'right',
        headerAlign: 'right',
        flex: 0.75,
        minWidth: 96,
      },
      {
        field: 'marketValue',
        headerName: t('Marketvalue'),
        type: 'number',
        renderCell: (params: GridRenderCellParams<IPosition, number>) => (
          <CurrencyField value={params.value}></CurrencyField>
        ),
        align: 'right',
        headerAlign: 'right',
        flex: 1,
        minWidth: 145,
      },
      {
        field: 'shareOfPortfolio',
        headerName: t('Share') + ' %',
        headerAlign: 'right',
        type: 'number',
        renderCell: (params: GridRenderCellParams<IPosition, number>) => (
          <PercentageField value={params.value}></PercentageField>
        ),
        align: 'right',
        flex: 0.5,
        minWidth: 72,
      },
      {
        field: 'purchaseValue',
        headerName: t('PurchaseValue'),
        renderCell: (params: GridRenderCellParams<IPosition, number>) => (
          <CurrencyField value={params.value}></CurrencyField>
        ),
        type: 'number',
        align: 'right',
        headerAlign: 'right',
        flex: 1,
        minWidth: 110,
      },
      {
        field: 'valueChangeAbsolute',
        headerName: t('Progress'),
        renderCell: (params: GridRenderCellParams<IPosition, number>) => (
          <CurrencyField value={params.value}></CurrencyField>
        ),
        align: 'right',
        type: 'number',
        headerAlign: 'right',
        flex: 1,
        minWidth: 110,
      },
    ],
    [t]
  );

  const columnsMobile = useMemo<GridColDef<IPosition>[]>(
    () => [
      {
        field: 'security',
        headerName: t('Security'),
        flex: 0.4,
        minWidth: 130,
        aggregable: false,
        sortable: false,
        type: 'string',
        renderHeader: () => (
          <Box>
            <Typography className="small-dg-header">{t('Security')}</Typography>
            <Typography className="small-dg-header italic">
              {t('MarketPrice')}
            </Typography>
          </Box>
        ),
        renderCell: (
          params: GridRenderCellParams<IPosition, FaFilteredSecurityDto>
        ) => {
          const link = getSecurityDetailLink(params.value!);

          if (link !== '') {
            return (
              <Box>
                <Link
                  className="position-link small-dg-text"
                  href={link}
                  target="_blank"
                >
                  {params.value?.name}
                </Link>
                <CurrencyField
                  className="small-dg-text2 italic"
                  value={params.row.latestMarketPrice}
                ></CurrencyField>
              </Box>
            );
          } else {
            return (
              <Box>
                <Typography className="small-dg-text">
                  {params.row.security?.name}
                </Typography>
                <CurrencyField
                  className="small-dg-text2 italic"
                  value={params.row.latestMarketPrice}
                ></CurrencyField>
              </Box>
            );
          }
        },
        sortComparator: FaFilteredSecurityDtoComparator,
      },
      {
        field: 'valueChangeAbsolute',
        headerName: t('Progress') + ' %',
        headerAlign: 'right',
        type: 'number',
        aggregable: true,
        sortable: false,
        renderHeader: () => (
          <Box>
            <Typography className="small-dg-header">
              {t('Progress') + ' %'}
            </Typography>
            <Typography className="small-dg-header italic">
              {t('Progress')}
            </Typography>
          </Box>
        ),
        renderCell: (params: GridRenderCellParams<IPosition, number>) => {
          if (params.aggregation) {
            return (
              <Box textAlign={'right'}>
                <Typography className="small-dg-text bold">
                  {t('TotalProgress')}
                </Typography>
                <CurrencyField
                  value={params.value}
                  className="small-dg-text2 bold"
                  decimals={0}
                ></CurrencyField>
              </Box>
            );
          } else {
            return (
              <Box textAlign={'right'}>
                <PercentageField
                  value={params.row.valueChangeRelative}
                  className="small-dg-text2"
                ></PercentageField>
                <CurrencyField
                  value={params.row.valueChangeAbsolute}
                  className="small-dg-text2 italic"
                  decimals={0}
                ></CurrencyField>
              </Box>
            );
          }
        },
        align: 'right',
        flex: 0.2,
        minWidth: 100,
      },

      {
        field: 'marketValue',
        headerName: t('Marketvalue'),
        aggregable: true,
        sortable: false,
        renderHeader: () => (
          <Box textAlign={'right'}>
            <Typography className="small-dg-header">
              {t('Marketvalue')}
            </Typography>
            <Typography className="small-dg-header italic">
              {t('PurchaseValue')}
            </Typography>
          </Box>
        ),
        renderCell: (params: GridRenderCellParams<IPosition, number>) => {
          if (params.aggregation) {
            return (
              <Box textAlign={'right'}>
                <Typography className="small-dg-text bold">
                  {t('TotalMarketValue')}
                </Typography>
                <CurrencyField
                  value={params.value}
                  className="small-dg-text2 bold"
                  decimals={0}
                ></CurrencyField>
              </Box>
            );
          } else
            return (
              <Box textAlign={'right'}>
                <CurrencyField
                  value={params.row.marketValue}
                  className="small-dg-text2"
                  decimals={0}
                ></CurrencyField>
                <CurrencyField
                  value={params.row.purchaseValue}
                  className="small-dg-text2 italic"
                  decimals={0}
                ></CurrencyField>
              </Box>
            );
        },
        align: 'right',
        headerAlign: 'right',
        flex: 0.4,
        minWidth: 120,
        type: 'number',
      },
    ],
    [t]
  );

  const [isLoading, setIsLoading] = useState<boolean>();
  const [error, setError] = useState<string | null>(null);
  const [portfolioPositions, setPortfolioPositions] = useState<
    FaPortfolioPositionsDto[]
  >([]);
  const [data, setData] = useState<IPosition[]>([]);
  const portfolios = useAppSelector((state) => state.portfolios);

  const api = useApiClient();
  const users = useAppSelector((state) => state.users);
  var activeUser = getActiveUser(users);

  useEffect(() => {
    setIsLoading(true);
    api
      .get<FaPortfolioPositionsDto[]>(
        'api/FaSecurity/GetPortfolioPositions/' +
          activeUser?.socialSecurityNumber
      )
      .then((res) => {
        portfolioPositions.splice(0);
        res.data.map((i) => {
          portfolioPositions.push(i);
          return null;
        });
        setIsLoading(false);
        setError(null);
        setData(createData());
      })
      .catch((err) => {
        console.error(err);
        setError('Error loading portfolio positions');
      });
  }, []);

  function createData(): IPosition[] {
    // Positions
    var ppData = portfolioPositions
      .filter((e) =>
        portfolios
          .filter((p) => p.show === true)
          .map((p) => p.id)
          .includes(e.id)
      )
      .map((pp, index) =>
        pp.positions.map((p) => ({ ...p, key: index.toString() + p.id }))
      )
      .flat()
      .sort((a, b) =>
        a.security.name > b.security.name ? 1 : -1
      ) as IPosition[];
    // Cash balance
    ppData.push({
      key: 'cash',
      marketValue: portfolioPositions
        .filter((e) =>
          portfolios
            .filter((p) => p.show === true)
            .map((p) => p.id)
            .includes(e.id)
        )
        .map((p) => p.cashBalance)
        .reduce((a, b) => a + b, 0),
      security: {
        currency: 'SEK',
        name: 'KASSA',
      },
    } as IPosition);

    return ppData;
  }

  useEffect(() => {
    setData(createData());
  }, [portfolios]);

  return (
    <>
      {error ? (
        <>
          <Box display="flex" justifyContent="center" my={6}>
            <Typography color="error.main">{error}</Typography>
          </Box>
        </>
      ) : (
        <>
          {isLoading ? (
            <Box display="flex" justifyContent="center" my={6}>
              <CircularProgress />
            </Box>
          ) : (
            <Box style={{ minHeight: 128, width: '98%' }}>
              <Box display={'flex'} height="100%">
                <Box flex={1} sx={{ display: { xs: 'none', sm: 'block' } }}>
                  <DataGrid
                    autoHeight
                    disableRowSelectionOnClick
                    columnVisibilityModel={{
                      valueChangeAbsolute: matches1280,
                      purchaseValue: matches1100,
                      latestMarketPrice: matches700,
                      amount: matches560,
                      shareOfPortfolio: matches1400,
                    }}
                    rows={data}
                    columns={columnsDesktop}
                    rowHeight={25}
                    //autoPageSize={true}
                    getRowId={(row) => row.key}
                    initialState={{
                      aggregation: {
                        model: {
                          marketValue: 'sum',
                          purchaseValue: 'sum',
                          valueChangeAbsolute: 'sum',
                        },
                      },
                      // sorting: {
                      //   sortModel: [{ field: 'security', sort: 'asc' }],
                      // },
                    }}
                  />
                </Box>
                <Box flex={1} sx={{ display: { xs: 'block', sm: 'none' } }}>
                  <DataGrid
                    autoHeight
                    disableRowSelectionOnClick
                    disableColumnMenu
                    hideFooterRowCount
                    rows={data}
                    columns={columnsMobile}
                    getRowId={(row) => row.key}
                    initialState={{
                      aggregation: {
                        model: {
                          marketValue: 'sum',
                          purchaseValue: 'sum',
                          valueChangeAbsolute: 'sum',
                        },
                      },
                    }}
                  />
                </Box>
              </Box>
            </Box>
          )}
        </>
      )}
    </>
  );
}
