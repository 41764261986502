import { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';

const Login = () => {
  const auth = useAuth();

  useEffect(() => {
    if (auth.isAuthenticated) {
      sessionStorage.removeItem('coeli_token');
      auth.removeUser();
      auth.signoutRedirect();
    } else {
      try {
        auth.signinRedirect();
      } catch {
        console.log('Error in login!');
      }
    }
  }, []);

  return <></>;
};

export default Login;
