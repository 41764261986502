import MyCoeliIcon from '@mui/icons-material/AccountBalanceOutlined';
import ProfileIcon from '@mui/icons-material/BadgeOutlined';
import InvestmentIcon from '@mui/icons-material/WorkOutline';

import Profile from './pages/Profile';
import Front from './pages/Front';
import Investments from './pages/Investments';
import SignIn from './pages/SignIn';

import Logout from './pages/Logout';
import { routeEntry } from './util/common';
import KycPage from './pages/profile/KycPage';
import InvestorProfile from './pages/profile/InvestorProfile';
import PortfolioAgreements from './pages/profile/PortfolioAgreements';
import ApprovalHistory from './pages/profile/ApprovalHistory';

import MyCoeli from './pages/MyCoeli';
import MyCoeliOverview from './pages/myCoeli/Overview';
import MyCoeliNews from './pages/myCoeli/TopNews';

import InvestmentsOverView from './pages/investments/Overview';
import InvestmentsProgress from './pages/investments/Progress';
import InvestmentsNews from './pages/investments/InvestmentNews';
import InvestmentsPositions from './pages/investments/Positions';
import InvestmentsTransactions from './pages/investments/Transactions';
import InvestmentsAllocationsPage from './pages/investments/AllocationsPage';
import InvestmentsDocuments from './pages/investments/DocumentsPage';
import InvestmentsReportsPage from './pages/investments/ReportsPage';
import InvestmentsRiskPage from './pages/investments/RiskPage';

// Navigation Routes
export const pages = [
  {
    title: 'topMenu.MyCoeli',
    icon: MyCoeliIcon,
    nav: 'mycoeli',
    element: MyCoeli,
    routes: new Array<routeEntry>(
      {
        nav: 'overview',
        element: MyCoeliOverview,
      },
      {
        nav: 'news',
        element: MyCoeliNews,
      }
    ),
  },
  {
    title: 'topMenu.MyInvestments',
    icon: InvestmentIcon,
    nav: 'investments',
    element: Investments,
    routes: new Array<routeEntry>(
      {
        nav: 'overview',
        element: InvestmentsOverView,
      },
      {
        nav: 'progress',
        element: InvestmentsProgress,
      },
      {
        nav: 'positions',
        element: InvestmentsPositions,
      },
      {
        nav: 'portfolionews',
        element: InvestmentsNews,
      },
      {
        nav: 'transactions',
        element: InvestmentsTransactions,
      },
      {
        nav: 'allocations',
        element: InvestmentsAllocationsPage,
      },
      {
        nav: 'risk',
        element: InvestmentsRiskPage,
      },
      {
        nav: 'reports',
        element: InvestmentsReportsPage,
      },
      {
        nav: 'documents',
        element: InvestmentsDocuments,
      }
    ),
  },
  {
    title: 'topMenu.MyProfile',
    icon: ProfileIcon,
    nav: 'profile',
    element: Profile,
    routes: new Array<routeEntry>(
      {
        nav: 'kyc',
        element: KycPage,
      },
      {
        nav: 'investorprofile',
        element: InvestorProfile,
      },
      {
        nav: 'agreements',
        element: PortfolioAgreements,
      },
      {
        nav: 'approvalhistory',
        element: ApprovalHistory,
      }
    ),
  },
  // {
  //   title: 'menu.ApprovalHistory',
  //   icon: ApprovalIcon,
  //   nav: 'approvalhistory',
  //   element: ApprovalHistory,
  //   routes: new Array<routeEntry>(),
  // },
  // Don't show this for now
  // **************************
  // {
  //   title: 'menu.TradeOrders',
  //   icon: TradeOrderIcon,
  //   nav: 'tradeorders',
  //   element: TradeOrders,
  //   routes: new Array<routeEntry>(),
  // },
  // {
  //   title: 'menu.OtherDocuments',
  //   icon: OtherDocsIcon,
  //   nav: 'otherdocuments',
  //   element: OtherDocuments,
  //   routes: new Array<routeEntry>(),
  // },
  // **************************
];

//export const routes = [{ nav: 'mydatasheet', element: MyDataSheet }];
export const routes: Array<routeEntry> = [];

export const authRoutes: Array<routeEntry> = [
  new routeEntry('', Front),
  new routeEntry('signin-oidc', SignIn),
  new routeEntry('logout', Logout),
  new routeEntry('prog', InvestmentsProgress),
];
