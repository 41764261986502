import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PageLayout from '../components/layout/PageLayout';
import TabMenu from '../components/menu/TabMenu';
import { FeatureFlagContext, IFeatureFlags } from '../components/featureFlags/FeatureFlagContext';

const Investments = () => {
    return <InvestmentsComponent></InvestmentsComponent>;
};

const InvestmentsComponent = () => {
    const { t } = useTranslation();

    const featureFlags: IFeatureFlags = useContext(FeatureFlagContext);

    let menuItems = [
        { label: t('investments.Overview'), to: '/investments/overview' },
        { label: t('investments.Positions'), to: '/investments/positions' },
        { label: t('investments.Progress'), to: '/investments/progress' },
        { label: t('investments.PortfolioNews'), to: '/investments/portfolionews', },
        { label: t('investments.Transactions'), to: '/investments/transactions', },
        { label: t('investments.Allocation'), to: '/investments/allocations', },
        { label: t('investments.Risk'), to: '/investments/risk', },
        { label: t('investments.Reports'), to: '/investments/reports', },
        { label: t('investments.Documents'), to: '/investments/documents', },
    ]

    if (!featureFlags.showRisk) {
        menuItems = menuItems.filter(x => x.to !== '/investments/risk');
    }

    return (
        <PageLayout>
            <TabMenu
                menuItems={menuItems}
            />
        </PageLayout>
    );
};

export default Investments;
