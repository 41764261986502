import { FeatureFlagContext, defaultInitialFeatureFlags } from "./FeatureFlagContext";

interface Props {
    children: React.ReactNode;
}

export default function FeatureFlagContextProvider({ children }: Props) {
    return (
        <FeatureFlagContext.Provider value={defaultInitialFeatureFlags}>
            {children }
        </FeatureFlagContext.Provider>
    )
}