import { createContext } from 'react';

export interface IFeatureFlags {
    showRisk:boolean
}

export const defaultInitialFeatureFlags: IFeatureFlags = {
    showRisk: false
};

export const FeatureFlagContext = createContext(defaultInitialFeatureFlags);
