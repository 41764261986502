import { useTranslation } from 'react-i18next';
import PageLayout from '../components/layout/PageLayout';
import TabMenu from '../components/menu/TabMenu';

const Profile = () => {
  return <ProfileComponent></ProfileComponent>;
};
const ProfileComponent = () => {
  const { t } = useTranslation();
  
  return (
    <PageLayout>
       <TabMenu
        menuItems={[
          { label: t('profile.MyInformation'), to: '/profile/kyc' },
          { label: t('profile.InvestorProfile'), to: '/profile/investorprofile' },
          { label: t('profile.PortfolioAgreements'), to: '/profile/agreements' },
          { label: t('profile.ApprovalHistory'), to: '/profile/approvalhistory' },
        ]}
      />
    </PageLayout>
  );
};

export default Profile;
