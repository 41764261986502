import React, { useEffect, useState } from 'react';
import { Field, Formik, useFormikContext } from 'formik';

import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormGroup,
  Checkbox,
  RadioGroup,
  Radio,
  Box,
  Link,
} from '@mui/material';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { RadioGroupFormField } from '../formfields/RadioGroupFormField';
import {
  IKycCompanyDto,
  LegalPersonType,
} from '../../models/kycCompanyStructure';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getActiveUser, getMainUser } from '../../redux/stateFuncs';
import { useApiClient } from '../../hooks/apiClient';
import { TextFormField } from '../formfields/TextFormField';
import { useTranslation } from 'react-i18next';
import { ToDoEnum } from '../../Enums';
import { setToDoComplete } from '../../redux/slices/todo';
import { DatePicker } from '@mui/x-date-pickers';

let initialValues: IKycCompanyDto = {
  id: '',
  contact1Id: '',
  contact2Id: '',
  accountId: '',
  contactLogicalName: '',
  ownerName: '',
  statuscode: '',
  ownerId: '',

  rejectComment: '',

  //Basic Info
  companyName: '',
  organizationNumber: '',
  legalPersonType: -1,
  email: '',
  phoneNumber: '',
  streetAddress: '',
  zipCode: '',
  city: '',
  country: '',

  //representative
  representative1Name: '',
  representative1SocialSecurityNo: '',
  representative1ConnToCustomer: '',
  representative2Name: '',
  representative2SocialSecurityNo: '',
  representative2ConnToCustomer: '',

  // ownership
  ownership: '',
  owner1Name: '',
  owner1Surname: '',
  owner1SocialSecurityNumber: '',
  owner1SharesNumberOfVotesInPercent: null,
  owner1TaxDomicileConnectionAbroad: '',
  owner1Country: '',
  owner1tinOrEquivalent: '',
  owner1UsCitizen: '',
  owner1CertifyToCustomerInfoCorrect: '',
  owner2Name: '',
  owner2Surname: '',
  owner2SocialSecurityNumber: '',
  owner2SharesNumberOfVotesInPercent: '',
  owner2TaxDomicileConnectionAbroad: '',
  owner2Country: '',
  owner2tinOrEquivalent: '',
  owner2UsCitizen: '',
  owner2CertifyToCustomerInfoCorrect: '',
  owner3Name: '',
  owner3Surname: '',
  owner3SocialSecurityNumber: '',
  owner3SharesNumberOfVotesInPercent: null,
  owner3TaxDomicileConnectionAbroad: '',
  owner3Country: '',
  owner3tinOrEquivalent: '',
  owner3UsCitizen: '',
  owner3CertifyToCustomerInfoCorrect: '',
  owner4Name: '',
  owner4Surname: '',
  owner4SocialSecurityNumber: '',
  owner4SharesNumberOfVotesInPercent: '',
  owner4TaxDomicileConnectionAbroad: '',
  owner4Country: '',
  owner4tinOrEquivalent: '',
  owner4UsCitizen: '',
  owner4CertifyToCustomerInfoCorrect: '',
  companyNameOwnerSection: '',
  organizationNumberOwnerSection: '',
  documentProveOwnershipControl: '',
  documentsDescriptionOwnerControl: '',

  //Politically exposed person
  isRealMandatorPep: '',
  pepName: '',
  pepSocialSecurityNumber: '',
  functionAsPep: '',
  pepFunctionCeased: '',
  anotherPepInLast18Months: '',
  anotherPepName: '',
  anotherPepSocialSecurityNumber: '',
  anotherFunctionAsPep: '',
  anotherPepFunctionCeased: '',
  isFamilyMemberPep: '',
  familyMemberPepName: '',
  familyMemberPepSsn: '',
  familyPepConnectionToCustomer: '',
  familyPepFunction: '',
  familyPepFunctionCeased: '',

  //Business and industry affiliation
  customerMainBusiness: '',
  customerSniCode: '',
  legalPerson: '',

  //Self-declaration regarding tax domicile
  isActualManagementLocatedOutsidese: '',
  taxDomicileOrAffiliation: '',
  taxDomicileOrAffiliationCountry: '',
  formw9Attached: '',
  selfdeclarationTinOrEquivalent: '',
  isAnyOfBelowApplicable: '',
  customerBusinessClassification: '',
  financialInstitutionInvestmentUnit: '',
  formw8bene: '',
  activeNonfinancialEntities: '',
  passiveNonfinancialUnit: '',
  globalIntermediaryIdNumber: '',
  certifyToLegalPersonInfoCorrect: '',

  //
  operatingIncome: false,
  returnOnCapitalInvestments: false,
  realEstateSales: false,
  companiesales: false,

  svenskBankOrAnotherFinancialInstitution: false,
  foreignBankOrOtheFinancialInstitutionWithinESS: false,
  foreignBankOrOtherFinancialInstitutionOutsideESS: false,
  capitalInvestment: false,
  pension: false,
  other: false,
  averageExpectedDeposit: '',
  depositFrequency: '',
};

function KycCompanyForm(props: any) {
  const users = useAppSelector((state) => state.users);
  const dispatch = useAppDispatch();
  const api = useApiClient();

  const IsToDo = props.isToDo;
  const { t } = useTranslation();

  const activeUserCanSign = users.find((c) => c.active === true)?.canSign;

  const [additional2Owner, setAdditional2Owner] = useState('False');
  const [additional3Owner, setAdditional3Owner] = useState('False');
  const [additional4Owner, setAdditional4Owner] = useState('False');

  const usCitizenOptions = [
    {
      label: t('kyc.usCitizenOptions.Option1'),
      value: '963440000',
    },
    {
      label: t('kyc.usCitizenOptions.Option2'),
      value: '963440001',
    },
  ];

  const customerBusinessClassificationOptions = [
    {
      label: t('kyc.customerBusinessClassificationOptions.Option1'),
      value: '963440000',
    },
    {
      label: t('kyc.customerBusinessClassificationOptions.Option2'),
      value: '963440001',
    },
    {
      label: t('kyc.customerBusinessClassificationOptions.Option3'),
      value: '963440002',
    },
  ];

  const financialInstitutionInvestmentUnitOptions = [
    {
      label: t('kyc.financialInstitutionInvestmentUnitOptions.Option1'),
      value: '963440000',
    },
    {
      label: t('kyc.financialInstitutionInvestmentUnitOptions.Option2'),
      value: '963440001',
    },
    {
      label: t('kyc.financialInstitutionInvestmentUnitOptions.Option3'),
      value: '963440002',
    },
    {
      label: t('kyc.financialInstitutionInvestmentUnitOptions.Option4'),
      value: '963440003',
    },
  ];

  const yesNoOptions = [
    { label: t('Yes'), value: 'True' },
    { label: t('No'), value: 'False' },
  ];

  const ownershipOptions = [
    {
      label: t('kyc.ownershipOptions.Option1'),
      value: '963440000',
    },
    {
      label: t('kyc.ownershipOptions.Option2'),
      value: '963440001',
    },
    {
      label: t('kyc.ownershipOptions.Option3'),
      value: '963440002',
    },
    {
      label: t('kyc.ownershipOptions.Option4'),
      value: '963440003',
    },
    {
      label: t('kyc.ownershipOptions.Option5'),
      value: '963440004',
    },
  ];

  const averageExpectedDepositOptions = [
    {
      label: t('kyc.averageExpectedDepositsChocies.Option1'),
      value: '963440000',
    },
    {
      label: t('kyc.averageExpectedDepositsChocies.Option2'),
      value: '963440001',
    },
    {
      label: t('kyc.averageExpectedDepositsChocies.Option3'),
      value: '963440002',
    },
    {
      label: t('kyc.averageExpectedDepositsChocies.Option4'),
      value: '963440003',
    },
  ];

  const depositFrequencyOptions = [
    { label: t('kyc.depositFrequencyChocies.Option1'), value: '963440000' },
    { label: t('kyc.depositFrequencyChocies.Option2'), value: '963440001' },
    { label: t('kyc.depositFrequencyChocies.Option3'), value: '963440002' },
  ];

  const legalPersonOptions = [
    {
      label: t('kyc.legalPersonCompanyOptions.ActiveCompany'),
      value: '963440000',
    },
    {
      label: t('kyc.legalPersonCompanyOptions.ListedCompany'),
      value: '963440001',
    },
    {
      label: t('kyc.legalPersonCompanyOptions.NewlyStartedCompany'),
      value: '963440002',
    },
    {
      label: t('kyc.legalPersonCompanyOptions.FinancialInstitution'),
      value: '963440003',
    },
    {
      label: t('kyc.legalPersonCompanyOptions.PassiveUnit'),
      value: '963440004',
    },
  ];

  const activeNonfinancialEntitiesOptions = [
    {
      label: t('kyc.activeNonfinancialEntitiesOptions.Option1'),
      value: '963440000',
    },
    {
      label: t('kyc.activeNonfinancialEntitiesOptions.Option2'),
      value: '963440001',
    },
    {
      label: t('kyc.activeNonfinancialEntitiesOptions.Option3'),
      value: '963440002',
    },
  ];

  //confirm
  function FormDialogConfirm(props: any) {
    const [open, setOpen] = React.useState(false);

    const { values } = useFormikContext();

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = (isSubmit: boolean) => {
      if (isSubmit) {
        props.handleIsFetch(false);
        const mainUserId = getMainUser(users)?.id;
        const activeCompanyId = getActiveUser(users)?.id;

        const kycData = values as IKycCompanyDto;
        const kycDataConfirmedStatuscode = {
          ...kycData,
          statuscode: 'Confirmed',
          approverId: mainUserId,
          ownerId: activeCompanyId,
        };
        if (props.isEdit && !IsToDo) {
          api.post('api/kycCompany', { ...kycData }).then((res) => {
            const guid: string = res.data;
            kycDataConfirmedStatuscode.id = guid;
            api
              .post('api/kycCompany/update', { ...kycDataConfirmedStatuscode })
              .then((res) => {
                props.handlekycDataChanged(!props.dataChangd);
                props.handleIsEdit(false);
              })
              .catch((e) => {
                props.handleIsFetch(true);
                setOpen(false);
              });
          });
        } else {
          api
            .post('api/kycCompany/update', { ...kycDataConfirmedStatuscode })
            .then((res) => {
              props.handlekycDataChanged(!props.dataChangd);
              if (IsToDo) {
                dispatch(setToDoComplete(ToDoEnum.KycCompany));
              }
            })
            .catch((e) => {
              props.handleIsFetch(true);
              setOpen(false);
            });
        }
      }

      setOpen(false);
    };

    return (
      <>
        <Button variant="contained" color="primary" onClick={handleClickOpen}>
          {t('Confirm')}
        </Button>
        <Dialog
          maxWidth="sm"
          fullWidth={true}
          open={open}
          onClose={handleClose}
        >
          <DialogTitle>{t('LegalNote')}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t('kyc.dialog.ConfirmCompanyText')}
              <br />
              <Link
                href="https://coeli.se/legal-information-coeli-wealth-management-ab/"
                target={'_blank'}
              >
                https://coeli.se/legal-information-coeli-wealth-management-ab/
              </Link>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleClose(true)}>{t('Confirm')}</Button>
            <Button onClick={() => handleClose(false)}>{t('Cancel')}</Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  //reject
  function FormDialogReject(props: any) {
    const [open, setOpen] = React.useState(false);
    const [rejectComment, setRejectComment] = React.useState<string>('');

    const { values } = useFormikContext();

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = (isSubmit: boolean) => {
      if (isSubmit) {
        props.handleIsFetch(false);
        const mainUserId = getMainUser(users)?.id;
        const activeCompanyId = getActiveUser(users)?.id;
        const kycData = values as IKycCompanyDto;
        kycData.statuscode = 'Rejected';
        kycData.rejectComment = rejectComment;

        api
          .post('api/KycCompany/update', {
            ...kycData,
            approverId: mainUserId,
            ownerId: activeCompanyId,
          })
          .then((res) => {
            props.handlekycDataChanged(!props.dataChangd);
            if (IsToDo) {
              dispatch(setToDoComplete(ToDoEnum.KycCompany));
            }
          });
      }
      setOpen(false);
    };

    return (
      <>
        <Button variant="contained" color="primary" onClick={handleClickOpen}>
          {t('Reject')}
        </Button>
        <Dialog
          maxWidth="sm"
          fullWidth={true}
          open={open}
          onClose={handleClose}
        >
          <DialogTitle>{t('LegalNote')}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t('kyc.dialog.RejectCompanyText')}
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="comment"
              label={t('RejectReason')}
              fullWidth
              multiline
              minRows={4}
              variant="outlined"
              required={true}
              value={rejectComment}
              onChange={(e) => setRejectComment(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button
              disabled={rejectComment === '' ? true : false}
              onClick={() => handleClose(true)}
            >
              {t('Reject')}
            </Button>
            <Button onClick={() => handleClose(false)}>{t('Cancel')}</Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
  function BasicForm() {
    const [isEditable, setIsEditable] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isKycEnabled, setIsKycEnabled] = useState(false);
    const [dataChanged, setDataChanged] = useState(false);
    const [isFetched, setIsFetched] = useState(false);
    const users = useAppSelector((state) => state.users);

    const handleEdit = (edit: boolean) => {
      setIsEditable(edit);
      setIsEdit(edit);
      setIsFetched(true);
    };

    const handleSubmitEdit = async (
      values: IKycCompanyDto,
      { resetForm, setErrors, setStatus, setSubmitting }: any
    ) => {};

    useEffect(() => {
      setIsFetched(false);
      const activeCompany = getActiveUser(users)?.id;
      const canSign = getActiveUser(users)?.canSign;

      api
        .get(`/api/kyccompany/GetKycCompanyForRevision/${activeCompany}`)
        .then((res) => {
          var item = res.data as IKycCompanyDto;

          if (item !== undefined) {
            if (canSign) {
              setIsKycEnabled(true);
            } else {
              setIsKycEnabled(false);
            }
          } else {
            setIsKycEnabled(false);
            initialValues = {} as IKycCompanyDto;
          }

          if (item !== undefined) {
            initialValues = { ...(item as IKycCompanyDto) };
            if (
              initialValues.owner2SocialSecurityNumber &&
              initialValues.owner2SocialSecurityNumber !== ''
            ) {
              setAdditional2Owner('True');
            }
            if (
              initialValues.owner3SocialSecurityNumber &&
              initialValues.owner3SocialSecurityNumber !== ''
            ) {
              setAdditional3Owner('True');
            }
            if (
              initialValues.owner4SocialSecurityNumber &&
              initialValues.owner4SocialSecurityNumber !== ''
            ) {
              setAdditional4Owner('True');
            }
          }
          setTimeout(() => {
            setIsFetched(true);
          }, 1000);
        });
    }, [users, dataChanged]);

    return (
      <div>
        {isFetched ? (
          <Formik
            initialValues={{ ...initialValues }}
            onSubmit={handleSubmitEdit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              handleReset,
              setFieldValue,
              isSubmitting,
              touched,
              values,
              status,
            }) =>
              values.id ? (
                <Card>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      {t('kyc.CompanyDataHeadline')}
                    </Typography>
                    {IsToDo ? (
                      <Typography variant="body2" gutterBottom>
                        {t('kyc.CompanyDataDesc')}
                      </Typography>
                    ) : (
                      <Typography variant="body2" gutterBottom>
                        {t('kyc.CompanyDataNonEditDesc')}
                      </Typography>
                    )}

                    <Typography display="inline">
                      Ansvarig hos Coeli:{' '}
                    </Typography>
                    <Typography
                      variant="body2"
                      display="inline"
                      fontWeight={'bold'}
                    >
                      {values.ownerName}
                    </Typography>
                    <form>
                      <Grid item xs={12} mt={1}>
                        {activeUserCanSign ? (
                          <>
                            {isKycEnabled &&
                            !isEditable &&
                            !IsToDo /* Activate when KYC should be editable when confirmed already*/ ? (
                              <>
                                {/* Edit */}
                                {/* <Button
                                    onClick={() => handleEdit(true)}
                                    variant="outlined"
                                    color="primary"
                                  >
                                    {t('Edit')}
                                  </Button> */}
                              </>
                            ) : isEditable || IsToDo ? (
                              <>
                                {/* Confirm / Cancel (edit) */}
                                {isEdit ? (
                                  <Grid container spacing={1}>
                                    <Grid item>
                                      <FormDialogConfirm
                                        handleIsFetch={setIsFetched}
                                        handlekycDataChanged={setDataChanged}
                                        dataChangd={dataChanged}
                                        isEdit={isEdit}
                                        handleIsEdit={handleEdit}
                                      ></FormDialogConfirm>
                                    </Grid>
                                    <Grid item>
                                      <Button
                                        onClick={() => handleEdit(false)}
                                        color="primary"
                                        variant="contained"
                                      >
                                        {t('Cancel')}
                                      </Button>
                                    </Grid>
                                  </Grid>
                                ) : (
                                  <>
                                    {/* Confirm / Reject */}
                                    <Grid container spacing={1}>
                                      <Grid item>
                                        <FormDialogConfirm
                                          handleIsFetch={setIsFetched}
                                          handlekycDataChanged={setDataChanged}
                                          dataChangd={dataChanged}
                                        ></FormDialogConfirm>
                                      </Grid>
                                      <Grid item>
                                        {/* Edit */}
                                        <Button
                                          onClick={() => handleEdit(true)}
                                          variant="outlined"
                                          color="primary"
                                        >
                                          {t('Edit')}
                                        </Button>
                                      </Grid>
                                      <Grid item>
                                        <FormDialogReject
                                          handleIsFetch={setIsFetched}
                                          handlekycDataChanged={setDataChanged}
                                          dataChangd={dataChanged}
                                        ></FormDialogReject>
                                      </Grid>
                                    </Grid>
                                  </>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        ) : null}
                      </Grid>
                      <Grid container spacing={6} mt={2}>
                        {/* Company name */}
                        <Grid item xs={12} mt={2}>
                          <Field
                            name="companyName"
                            label={t('CompanyName')}
                            variant="outlined"
                            component={TextFormField}
                            disabled={true}
                          />
                        </Grid>
                        {/* Organization number */}
                        <Grid item xs={12} mt={4}>
                          <Field
                            name="organizationNumber"
                            label={t('OrganizationNumber')}
                            variant="outlined"
                            component={TextFormField}
                            disabled={true}
                          />
                        </Grid>
                        {/* Type of legal person */}
                        <Grid item xs={12} mt={4}>
                          {
                            <FormControl component="fieldset">
                              <FormLabel component="legend">
                                {t('kyc.LegalPersonType')}
                              </FormLabel>
                              <RadioGroup
                                aria-label="legalPersonType"
                                name="legalPersonType"
                                value={values.legalPersonType}
                                onChange={handleChange}
                              >
                                <FormControlLabel
                                  value={LegalPersonType.LimitedCompany}
                                  disabled={!isEditable}
                                  control={<Radio />}
                                  label={t(
                                    'kyc.legalPersonTypeChoices.LimitedCompany'
                                  )}
                                />
                                <FormControlLabel
                                  value={
                                    LegalPersonType.TradingCompanyLimitedPartnership
                                  }
                                  disabled={!isEditable}
                                  control={<Radio />}
                                  label={t(
                                    'kyc.legalPersonTypeChoices.TradingCompanyLimitedPartnership'
                                  )}
                                />
                                <FormControlLabel
                                  value={LegalPersonType.Foundation}
                                  disabled={!isEditable}
                                  control={<Radio />}
                                  label={t(
                                    'kyc.legalPersonTypeChoices.Foundation'
                                  )}
                                />
                                <FormControlLabel
                                  value={LegalPersonType.EconomicAssociation}
                                  disabled={!isEditable}
                                  control={<Radio />}
                                  label={t(
                                    'kyc.legalPersonTypeChoices.EconomicAssociation'
                                  )}
                                />
                                <FormControlLabel
                                  value={LegalPersonType.Nonprofitassociation}
                                  disabled={!isEditable}
                                  control={<Radio />}
                                  label={t(
                                    'kyc.legalPersonTypeChoices.Nonprofitassociation'
                                  )}
                                />
                              </RadioGroup>
                            </FormControl>
                          }
                        </Grid>
                        <Grid item xs={12} mt={4}>
                          <Field
                            name="email"
                            label={t('Email')}
                            variant="outlined"
                            component={TextFormField}
                            disabled={!isEditable}
                          />
                        </Grid>
                        <Grid item xs={12} mt={4}>
                          <Field
                            name="phoneNumber"
                            label={t('PhoneNumber')}
                            variant="outlined"
                            component={TextFormField}
                            disabled={!isEditable}
                          />
                        </Grid>
                        {/* Street address */}
                        <Grid item xs={12} mt={4}>
                          <Field
                            name="streetAddress"
                            label={t('StreetAddress')}
                            variant="outlined"
                            component={TextFormField}
                            disabled={!isEditable}
                          />
                        </Grid>
                        {/* ZIP code */}
                        <Grid item xs={12} mt={4}>
                          <Field
                            name="zipCode"
                            label={t('ZipCode')}
                            variant="outlined"
                            component={TextFormField}
                            disabled={!isEditable}
                          />
                        </Grid>
                        {/* City */}
                        <Grid item xs={12} mt={4}>
                          <Field
                            name="city"
                            label={t('City')}
                            variant="outlined"
                            component={TextFormField}
                            disabled={!isEditable}
                          />
                        </Grid>
                        {/* Country */}
                        <Grid item xs={12} mt={4}>
                          <Field
                            name="country"
                            label={t('Country')}
                            variant="outlined"
                            component={TextFormField}
                            disabled={!isEditable}
                          />
                        </Grid>

                        <Grid item xs={12} mt={4}>
                          <Typography variant="h6" gutterBottom>
                            {t('RepresentativeInformation')}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} mt={4}>
                          <Field
                            name="representative1Name"
                            label={t('Representative') + ' - ' + t('Name')}
                            variant="outlined"
                            onChange={handleChange}
                            component={TextFormField}
                            disabled={true}
                          />
                        </Grid>
                        <Grid item xs={12} mt={4}>
                          <Field
                            name="representative1SocialSecurityNo"
                            label={
                              t('Representative') +
                              ' - ' +
                              t('SocialSecurityNumber')
                            }
                            variant="outlined"
                            component={TextFormField}
                            disabled={true}
                          />
                        </Grid>
                        <Grid item xs={12} mt={4}>
                          <Field
                            name="representative1ConnToCustomer"
                            label={
                              t('Representative') +
                              ' - ' +
                              t('kyc.ConnectionToCustomer')
                            }
                            variant="outlined"
                            component={TextFormField}
                            disabled={!isEditable}
                          />
                        </Grid>
                        {values.representative2SocialSecurityNo &&
                        values.representative2SocialSecurityNo !== '' ? (
                          <>
                            <Grid item xs={12} mt={4}>
                              <Field
                                name="representative2Name"
                                label={t('Representative') + ' - ' + t('Name')}
                                variant="outlined"
                                component={TextFormField}
                                disabled={true}
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Field
                                name="representative2SocialSecurityNo"
                                label={
                                  t('Representative') +
                                  ' - ' +
                                  t('SocialSecurityNumber')
                                }
                                variant="outlined"
                                component={TextFormField}
                                disabled={true}
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Field
                                name="representative2ConnToCustomer"
                                label={
                                  t('Representative') +
                                  ' - ' +
                                  t('kyc.ConnectionToCustomer')
                                }
                                variant="outlined"
                                component={TextFormField}
                                disabled={!isEditable}
                              />
                            </Grid>
                          </>
                        ) : null}

                        <Grid item xs={12} mt={4}>
                          <Typography variant="h6" gutterBottom>
                            {t('kyc.RealPrincipalAndOwnership')}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} mt={4}>
                          <Field
                            name="ownership"
                            component={RadioGroupFormField}
                            options={ownershipOptions}
                            isDisabled={!isEditable}
                            label={t('Ownership')}
                          />
                        </Grid>
                        {!(
                          values.ownership === ownershipOptions[1].value ||
                          values.ownership === ownershipOptions[2].value ||
                          values.ownership === ownershipOptions[4].value
                        ) ? (
                          <>
                            <Grid item xs={12} mt={4}>
                              <Field
                                name="owner1Name"
                                label={t('Firstname')}
                                variant="outlined"
                                component={TextFormField}
                                disabled={!isEditable}
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Field
                                name="owner1Surname"
                                label={t('Lastname')}
                                variant="outlined"
                                component={TextFormField}
                                disabled={!isEditable}
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Field
                                name="owner1SocialSecurityNumber"
                                label={t('SocialSecurityNumber')}
                                variant="outlined"
                                component={TextFormField}
                                disabled={!isEditable}
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Field
                                name="owner1SharesNumberOfVotesInPercent"
                                label={t('kyc.HoldingsInProcent')}
                                variant="outlined"
                                component={TextFormField}
                                disabled={!isEditable}
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Field
                                name="owner1TaxDomicileConnectionAbroad"
                                component={RadioGroupFormField}
                                options={yesNoOptions}
                                isDisabled={!isEditable}
                                label={t('kyc.TaxDomicileConnectionAbroad')}
                              />
                            </Grid>
                            {values.owner1TaxDomicileConnectionAbroad ===
                            'True' ? (
                              <>
                                <Grid item xs={12} mt={4}>
                                  <Field
                                    name="owner1Country"
                                    label={t('Country')}
                                    variant="outlined"
                                    component={TextFormField}
                                    disabled={!isEditable}
                                  />
                                </Grid>
                                <Grid item xs={12} mt={4}>
                                  <Field
                                    name="owner1tinOrEquivalent"
                                    label={t('kyc.TinOrEquivalent')}
                                    variant="outlined"
                                    component={TextFormField}
                                    disabled={!isEditable}
                                  />
                                </Grid>
                                <Grid item xs={12} mt={4}>
                                  <Field
                                    name="owner1UsCitizen"
                                    component={RadioGroupFormField}
                                    options={usCitizenOptions}
                                    isDisabled={!isEditable}
                                    label={t('kyc.USCitizen')}
                                  />
                                </Grid>
                                <Grid item xs={12} mt={4}>
                                  <Field
                                    name="owner1CertifyToCustomerInfoCorrect"
                                    component={RadioGroupFormField}
                                    options={yesNoOptions}
                                    isDisabled={!isEditable}
                                    label={t(
                                      'kyc.CertifyToCustomerInfoCorrect'
                                    )}
                                  />
                                </Grid>
                              </>
                            ) : (
                              <></>
                            )}

                            <Grid item xs={12} mt={4}>
                              <Field
                                name="additional2Owner"
                                component={RadioGroupFormField}
                                options={yesNoOptions}
                                isDisabled={!isEditable}
                                value={additional2Owner}
                                onChange={(e: {
                                  target: {
                                    value: React.SetStateAction<string>;
                                  };
                                }) => setAdditional2Owner(e.target.value)}
                                label={t('kyc.AdditionalOwnerQuestion')}
                              />
                            </Grid>
                            {additional2Owner === 'True' ? (
                              <>
                                <Grid item xs={12} mt={4}>
                                  <Field
                                    name="owner2Name"
                                    label={t('Firstname')}
                                    variant="outlined"
                                    component={TextFormField}
                                    disabled={!isEditable}
                                  />
                                </Grid>
                                <Grid item xs={12} mt={4}>
                                  <Field
                                    name="owner2Surname"
                                    label={t('Lastname')}
                                    variant="outlined"
                                    component={TextFormField}
                                    disabled={!isEditable}
                                  />
                                </Grid>
                                <Grid item xs={12} mt={4}>
                                  <Field
                                    name="owner2SocialSecurityNumber"
                                    label={t('SocialSecurityNumber')}
                                    variant="outlined"
                                    component={TextFormField}
                                    disabled={!isEditable}
                                  />
                                </Grid>
                                <Grid item xs={12} mt={4}>
                                  <Field
                                    name="owner2SharesNumberOfVotesInPercent"
                                    label={t('kyc.HoldingsInProcent')}
                                    variant="outlined"
                                    component={TextFormField}
                                    type="number"
                                    disabled={!isEditable}
                                  />
                                </Grid>

                                <Grid item xs={12} mt={4}>
                                  <Field
                                    name="owner2TaxDomicileConnectionAbroad"
                                    component={RadioGroupFormField}
                                    options={yesNoOptions}
                                    isDisabled={!isEditable}
                                    label={t('kyc.TaxDomicileConnectionAbroad')}
                                  />
                                </Grid>
                                {values.owner2TaxDomicileConnectionAbroad ===
                                'True' ? (
                                  <>
                                    <Grid item xs={12} mt={4}>
                                      <Field
                                        name="owner2Country"
                                        label={t('Country')}
                                        variant="outlined"
                                        component={TextFormField}
                                        disabled={!isEditable}
                                      />
                                    </Grid>
                                    <Grid item xs={12} mt={4}>
                                      <Field
                                        name="owner2tinOrEquivalent"
                                        label={t('kyc.TinOrEquivalent')}
                                        variant="outlined"
                                        component={TextFormField}
                                        disabled={!isEditable}
                                      />
                                    </Grid>
                                    <Grid item xs={12} mt={4}>
                                      <Field
                                        name="owner2UsCitizen"
                                        component={RadioGroupFormField}
                                        options={usCitizenOptions}
                                        isDisabled={!isEditable}
                                        label={t('kyc.USCitizen')}
                                      />
                                    </Grid>
                                    <Grid item xs={12} mt={4}>
                                      <Field
                                        name="owner2CertifyToCustomerInfoCorrect"
                                        component={RadioGroupFormField}
                                        options={yesNoOptions}
                                        isDisabled={!isEditable}
                                        label={t(
                                          'kyc.CertifyToCustomerInfoCorrect'
                                        )}
                                      />
                                    </Grid>
                                  </>
                                ) : (
                                  <></>
                                )}

                                <Grid item xs={12} mt={4}>
                                  <Field
                                    name="additional3Owner"
                                    component={RadioGroupFormField}
                                    options={yesNoOptions}
                                    isDisabled={!isEditable}
                                    value={additional3Owner}
                                    onChange={(e: {
                                      target: {
                                        value: React.SetStateAction<string>;
                                      };
                                    }) => setAdditional3Owner(e.target.value)}
                                    label={t('kyc.AdditionalOwnerQuestion')}
                                  />
                                </Grid>
                                {additional3Owner === 'True' ? (
                                  <>
                                    <Grid item xs={12} mt={4}>
                                      <Field
                                        name="owner3Name"
                                        label={t('Firstname')}
                                        variant="outlined"
                                        component={TextFormField}
                                        disabled={!isEditable}
                                      />
                                    </Grid>
                                    <Grid item xs={12} mt={4}>
                                      <Field
                                        name="owner3Surname"
                                        label={t('Lastname')}
                                        variant="outlined"
                                        component={TextFormField}
                                        disabled={!isEditable}
                                      />
                                    </Grid>
                                    <Grid item xs={12} mt={4}>
                                      <Field
                                        name="owner3SocialSecurityNumber"
                                        label={t('SocialSecurityNumber')}
                                        variant="outlined"
                                        component={TextFormField}
                                        disabled={!isEditable}
                                      />
                                    </Grid>
                                    <Grid item xs={12} mt={4}>
                                      <Field
                                        name="owner3SharesNumberOfVotesInPercent"
                                        label={t('kyc.HoldingsInProcent')}
                                        variant="outlined"
                                        component={TextFormField}
                                        disabled={!isEditable}
                                      />
                                    </Grid>
                                    <Grid item xs={12} mt={4}>
                                      <Field
                                        name="owner3TaxDomicileConnectionAbroad"
                                        component={RadioGroupFormField}
                                        options={yesNoOptions}
                                        isDisabled={!isEditable}
                                        label={t(
                                          'kyc.TaxDomicileConnectionAbroad'
                                        )}
                                      />
                                    </Grid>
                                    {values.owner3TaxDomicileConnectionAbroad ===
                                    'True' ? (
                                      <>
                                        <Grid item xs={12} mt={4}>
                                          <Field
                                            name="owner3Country"
                                            label={t('Country')}
                                            variant="outlined"
                                            component={TextFormField}
                                            disabled={!isEditable}
                                          />
                                        </Grid>
                                        <Grid item xs={12} mt={4}>
                                          <Field
                                            name="owner3tinOrEquivalent"
                                            label={t('kyc.TinOrEquivalent')}
                                            variant="outlined"
                                            component={TextFormField}
                                            disabled={!isEditable}
                                          />
                                        </Grid>
                                        <Grid item xs={12} mt={4}>
                                          <Field
                                            name="owner3UsCitizen"
                                            component={RadioGroupFormField}
                                            options={usCitizenOptions}
                                            isDisabled={!isEditable}
                                            label={t('kyc.USCitizen')}
                                          />
                                        </Grid>
                                        <Grid item xs={12} mt={4}>
                                          <Field
                                            name="owner3CertifyToCustomerInfoCorrect"
                                            component={RadioGroupFormField}
                                            options={yesNoOptions}
                                            isDisabled={!isEditable}
                                            label={t(
                                              'kyc.CertifyToCustomerInfoCorrect'
                                            )}
                                          />
                                        </Grid>
                                      </>
                                    ) : null}

                                    <Grid item xs={12} mt={4}>
                                      <Field
                                        name="additional4Owner"
                                        component={RadioGroupFormField}
                                        options={yesNoOptions}
                                        isDisabled={!isEditable}
                                        label={t('kyc.AdditionalOwnerQuestion')}
                                        value={additional4Owner}
                                        onChange={(e: {
                                          target: {
                                            value: React.SetStateAction<string>;
                                          };
                                        }) =>
                                          setAdditional4Owner(e.target.value)
                                        }
                                      />
                                    </Grid>
                                    {additional4Owner === 'True' ? (
                                      <>
                                        <Grid item xs={12} mt={4}>
                                          <Field
                                            name="owner4Name"
                                            label={t('Firstname')}
                                            variant="outlined"
                                            component={TextFormField}
                                            disabled={!isEditable}
                                          />
                                        </Grid>
                                        <Grid item xs={12} mt={4}>
                                          <Field
                                            name="owner4Surname"
                                            label={t('Lastname')}
                                            variant="outlined"
                                            component={TextFormField}
                                            disabled={!isEditable}
                                          />
                                        </Grid>
                                        <Grid item xs={12} mt={4}>
                                          <Field
                                            name="owner4SocialSecurityNumber"
                                            label={t('SocialSecurityNumber')}
                                            variant="outlined"
                                            component={TextFormField}
                                            disabled={!isEditable}
                                          />
                                        </Grid>
                                        <Grid item xs={12} mt={4}>
                                          <Field
                                            name="owner4SharesNumberOfVotesInPercent"
                                            label={t('kyc.HoldingsInProcent')}
                                            variant="outlined"
                                            component={TextFormField}
                                            disabled={!isEditable}
                                          />
                                        </Grid>
                                        <Grid item xs={12} mt={4}>
                                          <Field
                                            name="owner4TaxDomicileConnectionAbroad"
                                            component={RadioGroupFormField}
                                            options={yesNoOptions}
                                            isDisabled={!isEditable}
                                            label={t(
                                              'kyc.TaxDomicileConnectionAbroad'
                                            )}
                                          />
                                        </Grid>
                                        {values.owner4TaxDomicileConnectionAbroad ===
                                        'True' ? (
                                          <>
                                            <Grid item xs={12} mt={4}>
                                              <Field
                                                name="owner4Country"
                                                label={t('Country')}
                                                variant="outlined"
                                                component={TextFormField}
                                                disabled={!isEditable}
                                              />
                                            </Grid>
                                            <Grid item xs={12} mt={4}>
                                              <Field
                                                name="owner4tinOrEquivalent"
                                                label={t('kyc.TinOrEquivalent')}
                                                variant="outlined"
                                                component={TextFormField}
                                                disabled={!isEditable}
                                              />
                                            </Grid>
                                            <Grid item xs={12} mt={4}>
                                              <Field
                                                name="owner4UsCitizen"
                                                component={RadioGroupFormField}
                                                options={usCitizenOptions}
                                                isDisabled={!isEditable}
                                                label={t('kyc.USCitizen')}
                                              />
                                            </Grid>
                                            <Grid item xs={12} mt={4}>
                                              <Field
                                                name="owner4CertifyToCustomerInfoCorrect"
                                                component={RadioGroupFormField}
                                                options={yesNoOptions}
                                                isDisabled={!isEditable}
                                                label={t(
                                                  'kyc.CertifyToCustomerInfoCorrect'
                                                )}
                                              />
                                            </Grid>
                                          </>
                                        ) : null}
                                      </>
                                    ) : null}
                                  </>
                                ) : null}
                              </>
                            ) : null}
                          </>
                        ) : values.ownership === ownershipOptions[1].value ? (
                          <>
                            <Grid item xs={12} mt={4}>
                              <Field
                                name="companyNameOwnerSection"
                                label={t('CompanyName')}
                                variant="outlined"
                                component={TextFormField}
                                disabled={!isEditable}
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Field
                                name="organizationNumberOwnerSection"
                                label={t('OrganizationNumber')}
                                variant="outlined"
                                component={TextFormField}
                                disabled={!isEditable}
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Field
                                name="documentProveOwnershipControl"
                                component={RadioGroupFormField}
                                options={yesNoOptions}
                                isDisabled={!isEditable}
                                label={t('kyc.DocumentProveOwnershipControl')}
                              />
                            </Grid>
                            {values.documentProveOwnershipControl === 'True' ? (
                              <Grid item xs={12} mt={4}>
                                <Field
                                  name="documentsDescriptionOwnerControl"
                                  label={t('kyc.PleaseSpecify')}
                                  variant="outlined"
                                  component={TextFormField}
                                  disabled={!isEditable}
                                />
                              </Grid>
                            ) : null}
                          </>
                        ) : (
                          <></>
                        )}

                        <Grid item xs={12} mt={4}>
                          <Typography variant="h6" gutterBottom>
                            {t('kyc.PEP')}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} mt={4}>
                          <Field
                            name="isRealMandatorPep"
                            component={RadioGroupFormField}
                            options={yesNoOptions}
                            isDisabled={!isEditable}
                            label={t('kyc.RealMandatorPep')}
                          />
                        </Grid>
                        {values.isRealMandatorPep === 'True' ? (
                          <>
                            <Grid item xs={12} mt={4}>
                              <Field
                                name="pepName"
                                label={t('kyc.RealMandatorPepName')}
                                variant="outlined"
                                component={TextFormField}
                                disabled={!isEditable}
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Field
                                name="pepSocialSecurityNumber"
                                label={t(
                                  'kyc.RealMandatorPepSocialSecurityNumber'
                                )}
                                variant="outlined"
                                component={TextFormField}
                                disabled={!isEditable}
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Field
                                name="functionAsPep"
                                label={t('kyc.FunctionAsPep')}
                                variant="outlined"
                                component={TextFormField}
                                disabled={!isEditable}
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Typography>{t('FunctionCeased')}</Typography>
                              <DatePicker
                                format="dd-MM-yyyy"
                                value={
                                  values.pepFunctionCeased != null
                                    ? new Date(values.pepFunctionCeased)
                                    : null
                                }
                                onChange={(val) =>
                                  setFieldValue('pepFunctionCeased', val)
                                }
                                disabled={!isEditable}
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Field
                                name="anotherPepInLast18Months"
                                component={RadioGroupFormField}
                                options={yesNoOptions}
                                isDisabled={!isEditable}
                                label={t('kyc.AnotherPepInLast18Months')}
                              />
                            </Grid>
                            {values.anotherPepInLast18Months === 'True' ? (
                              <>
                                <Grid item xs={12} mt={4}>
                                  <Field
                                    name="anotherPepName"
                                    label={t('kyc.RealMandatorPepName')}
                                    variant="outlined"
                                    component={TextFormField}
                                    disabled={!isEditable}
                                  />
                                </Grid>
                                <Grid item xs={12} mt={4}>
                                  <Field
                                    name="anotherPepSocialSecurityNumber"
                                    label={t(
                                      'kyc.RealMandatorPepSocialSecurityNumber'
                                    )}
                                    variant="outlined"
                                    component={TextFormField}
                                    disabled={!isEditable}
                                  />
                                </Grid>
                                <Grid item xs={12} mt={4}>
                                  <Field
                                    name="anotherFunctionAsPep"
                                    label={t('kyc.FunctionAsPep')}
                                    variant="outlined"
                                    component={TextFormField}
                                    disabled={!isEditable}
                                  />
                                </Grid>
                                <Grid item xs={12} mt={4}>
                                  <Typography>{t('FunctionCeased')}</Typography>
                                  <DatePicker
                                    format="dd-MM-yyyy"
                                    value={
                                      values.anotherPepFunctionCeased != null
                                        ? new Date(
                                            values.anotherPepFunctionCeased
                                          )
                                        : null
                                    }
                                    onChange={(val) =>
                                      setFieldValue(
                                        'anotherPepFunctionCeased',
                                        val
                                      )
                                    }
                                    disabled={!isEditable}
                                  />
                                </Grid>
                              </>
                            ) : null}
                          </>
                        ) : null}

                        <Grid item xs={12} mt={4}>
                          <Field
                            name="isFamilyMemberPep"
                            component={RadioGroupFormField}
                            options={yesNoOptions}
                            isDisabled={!isEditable}
                            label={t('kyc.FamilyMemberPep')}
                          />
                        </Grid>
                        {values.isFamilyMemberPep === 'True' ? (
                          <>
                            <Grid item xs={12} mt={4}>
                              <Field
                                name="familyMemberPepName"
                                label={t('kyc.FamilyMemberPepName')}
                                variant="outlined"
                                component={TextFormField}
                                disabled={!isEditable}
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Field
                                name="familyMemberPepSsn"
                                label={t(
                                  'kyc.FamilyMemberPepSocialSecurityNumber'
                                )}
                                variant="outlined"
                                component={TextFormField}
                                disabled={!isEditable}
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Field
                                name="familyPepConnectionToCustomer"
                                label={t('kyc.ConnectionToCustomer')}
                                variant="outlined"
                                component={TextFormField}
                                disabled={!isEditable}
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Field
                                name="familyPepFunction"
                                label={t('kyc.FunctionAsPep')}
                                variant="outlined"
                                component={TextFormField}
                                disabled={!isEditable}
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Typography>{t('FunctionCeased')}</Typography>
                              <DatePicker
                                format="dd-MM-yyyy"
                                value={
                                  values.familyPepFunctionCeased != null
                                    ? new Date(values.familyPepFunctionCeased)
                                    : null
                                }
                                onChange={(val) =>
                                  setFieldValue('familyPepFunctionCeased', val)
                                }
                                disabled={!isEditable}
                              />
                            </Grid>
                          </>
                        ) : null}

                        <Grid item xs={12} mt={4}>
                          <Typography variant="h6" gutterBottom>
                            {t('kyc.BusinessAndIndustryAffiliation')}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} mt={4}>
                          <Field
                            name="customerMainBusiness"
                            label={t('kyc.CustomerMainBusiness')}
                            variant="outlined"
                            component={TextFormField}
                            disabled={!isEditable}
                          />
                        </Grid>
                        <Grid item xs={12} mt={4}>
                          <Field
                            name="customerSniCode"
                            label={t('kyc.CustomerSniCode')}
                            variant="outlined"
                            component={TextFormField}
                            disabled={!isEditable}
                          />
                        </Grid>
                        <Grid item xs={12} mt={4}>
                          <Field
                            name="legalPerson"
                            component={RadioGroupFormField}
                            options={legalPersonOptions}
                            isDisabled={!isEditable}
                            label={t('kyc.LegalPerson')}
                          />
                        </Grid>

                        <Grid item xs={12} mt={4}>
                          <Typography variant="h6" gutterBottom>
                            {t('kyc.SelfDeclarationRegardingTaxDomicile')}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} mt={4}>
                          <Field
                            name="isActualManagementLocatedOutsidese"
                            component={RadioGroupFormField}
                            options={yesNoOptions}
                            isDisabled={!isEditable}
                            label={t('kyc.IsActualManagementLocatedOutsideSwe')}
                          />
                        </Grid>
                        <Grid item xs={12} mt={4}>
                          <Field
                            name="taxDomicileOrAffiliation"
                            component={RadioGroupFormField}
                            options={yesNoOptions}
                            isDisabled={!isEditable}
                            label={t('kyc.TaxDomicileOrAffiliation')}
                          />
                        </Grid>
                        {values.taxDomicileOrAffiliation === 'True' ? (
                          <>
                            <Grid item xs={12} mt={4}>
                              <Field
                                name="taxDomicileOrAffiliationCountry"
                                label={t('Country')}
                                variant="outlined"
                                component={TextFormField}
                                disabled={!isEditable}
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Field
                                name="formw9Attached"
                                component={RadioGroupFormField}
                                options={yesNoOptions}
                                isDisabled={!isEditable}
                                label={t('kyc.FormW9Attached')}
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Field
                                name="selfdeclarationTinOrEquivalent"
                                label={t('kyc.SelfDeclarationTIN')}
                                variant="outlined"
                                component={TextFormField}
                                disabled={!isEditable}
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Field
                                name="isAnyOfBelowApplicable"
                                component={RadioGroupFormField}
                                options={yesNoOptions}
                                isDisabled={!isEditable}
                                label={t('kyc.IsAnyOfBelowApplicable')}
                              />
                              <Typography sx={{ fontStyle: 'italic' }}>
                                <ul>
                                  <li>
                                    {t('kyc.companyApplicableChoices.Choice1')}
                                  </li>
                                  <li>
                                    {t('kyc.companyApplicableChoices.Choice2')}
                                  </li>
                                  <li>
                                    {t('kyc.companyApplicableChoices.Choice3')}
                                  </li>
                                  <li>
                                    {t('kyc.companyApplicableChoices.Choice4')}
                                  </li>
                                </ul>
                              </Typography>
                            </Grid>
                          </>
                        ) : null}

                        {values.isAnyOfBelowApplicable === 'True' ? (
                          <>
                            <Grid item xs={12} mt={4}>
                              <Field
                                name="customerBusinessClassification"
                                component={RadioGroupFormField}
                                options={customerBusinessClassificationOptions}
                                isDisabled={!isEditable}
                                label={t('kyc.CustomerBusinessClassification')}
                              />
                            </Grid>

                            {values.customerBusinessClassification ===
                            customerBusinessClassificationOptions[0].value ? (
                              <>
                                <Grid item xs={12} mt={4}>
                                  <Field
                                    name="financialInstitutionInvestmentUnit"
                                    component={RadioGroupFormField}
                                    options={
                                      financialInstitutionInvestmentUnitOptions
                                    }
                                    isDisabled={!isEditable}
                                    label={t(
                                      'kyc.FinancialInstitutionInvestmentUnit'
                                    )}
                                  />
                                </Grid>
                                {values.financialInstitutionInvestmentUnit ===
                                financialInstitutionInvestmentUnitOptions[2]
                                  .value ? (
                                  <Grid item xs={12} mt={4}>
                                    <Field
                                      name="formw8bene"
                                      component={RadioGroupFormField}
                                      options={yesNoOptions}
                                      isDisabled={!isEditable}
                                      label={t('kyc.FormW8BENE')}
                                    />
                                  </Grid>
                                ) : null}
                              </>
                            ) : null}

                            {values.customerBusinessClassification ===
                              customerBusinessClassificationOptions[2].value ||
                            values.financialInstitutionInvestmentUnit !==
                              financialInstitutionInvestmentUnitOptions[2]
                                .value ? null : (
                              <Grid item xs={12} mt={4}>
                                <Field
                                  name="activeNonfinancialEntities"
                                  component={RadioGroupFormField}
                                  options={activeNonfinancialEntitiesOptions}
                                  isDisabled={!isEditable}
                                  label={t('kyc.ActiveNonfinancialEntities')}
                                />
                              </Grid>
                            )}

                            {!(
                              values.financialInstitutionInvestmentUnit ===
                                financialInstitutionInvestmentUnitOptions[2]
                                  .value &&
                              values.activeNonfinancialEntities ===
                                activeNonfinancialEntitiesOptions[0].value
                            ) ? null : (
                              <Grid item xs={12} mt={4}>
                                <Field
                                  name="passiveNonfinancialUnit"
                                  component={RadioGroupFormField}
                                  options={yesNoOptions}
                                  isDisabled={!isEditable}
                                  label={t('kyc.PassiveNonfinancialUnit')}
                                />
                              </Grid>
                            )}

                            <Grid item xs={12} mt={4}>
                              <Field
                                name="globalIntermediaryIdNumber"
                                label={t('kyc.GlobalIntermediaryIdNumber')}
                                variant="outlined"
                                component={TextFormField}
                                disabled={!isEditable}
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Field
                                name="certifyToLegalPersonInfoCorrect"
                                component={RadioGroupFormField}
                                options={yesNoOptions}
                                isDisabled={!isEditable}
                                label={t('kyc.CertifyToLegalPersonInfoCorrect')}
                              />
                            </Grid>
                          </>
                        ) : null}

                        <Grid item xs={12} mt={4}>
                          <Typography variant="h6" gutterBottom>
                            {t('kyc.OriginOfInvestment')}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} mt={4}>
                          {/* <Field
                        name="originTransfersdMoneyAndSecurities"
                        component={CheckboxFormField}
                        options={originTransfersdMoneyAndSecuritiesOptions}
                        isDisabled={!isEditable}
                        label="What is the origin of money or the securities that are intended to be transferred to Coeli?"
                      /> */}
                          <FormControl
                            sx={{ m: 3 }}
                            component="fieldset"
                            variant="standard"
                          >
                            <FormLabel component="legend">
                              {t('kyc.OriginOfMoneyQuestion')}
                            </FormLabel>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={values.operatingIncome}
                                    onChange={handleChange}
                                    name="operatingIncome"
                                  />
                                }
                                disabled={!isEditable}
                                label={t(
                                  'kyc.originOfMoneyChoices.OperatingIncome'
                                )}
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={values.returnOnCapitalInvestments}
                                    onChange={handleChange}
                                    disabled={!isEditable}
                                    name="returnOnCapitalInvestments"
                                  />
                                }
                                label={t(
                                  'kyc.originOfMoneyChoices.ReturnOnCapitalInvestments'
                                )}
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={values.realEstateSales}
                                    onChange={handleChange}
                                    disabled={!isEditable}
                                    name="realEstateSales"
                                  />
                                }
                                label={t(
                                  'kyc.originOfMoneyChoices.RealEstateSales'
                                )}
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={values.companiesales}
                                    onChange={handleChange}
                                    disabled={!isEditable}
                                    name="companiesales"
                                  />
                                }
                                label={t(
                                  'kyc.originOfMoneyChoices.CompanieSales'
                                )}
                              />
                            </FormGroup>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} mt={4}>
                          {/* <Field
                        component={CheckboxFormField}
                        options={mockCheckboxOptions}
                        isDisabled={!isEditable}
                        label="Deposits from"
                      /> */}
                          <FormControl
                            sx={{ m: 3 }}
                            component="fieldset"
                            variant="standard"
                          >
                            <FormLabel component="legend">
                              {t('kyc.DepositsFrom')}
                            </FormLabel>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      values.svenskBankOrAnotherFinancialInstitution
                                    }
                                    onChange={handleChange}
                                    name="svenskBankOrAnotherFinancialInstitution"
                                  />
                                }
                                disabled={!isEditable}
                                label={t('kyc.depositChoices.SwedishBank')}
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      values.foreignBankOrOtheFinancialInstitutionWithinESS
                                    }
                                    onChange={handleChange}
                                    disabled={!isEditable}
                                    name="foreignBankOrOtheFinancialInstitutionWithinESS"
                                  />
                                }
                                label={t('kyc.depositChoices.ForeignBankESS')}
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      values.foreignBankOrOtherFinancialInstitutionOutsideESS
                                    }
                                    onChange={handleChange}
                                    disabled={!isEditable}
                                    name="foreignBankOrOtherFinancialInstitutionOutsideESS"
                                  />
                                }
                                label={t(
                                  'kyc.depositChoices.ForeignBankNonESS'
                                )}
                              />
                            </FormGroup>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} mt={4}>
                          {/* <Field
                        component={CheckboxFormField}
                        options={mockCheckboxOptions}
                        isDisabled={!isEditable}
                        label="Main reason for investment"
                      /> */}
                          <FormControl
                            sx={{ m: 3 }}
                            component="fieldset"
                            variant="standard"
                          >
                            <FormLabel component="legend">
                              {t('kyc.InvestReason')}
                            </FormLabel>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={values.capitalInvestment}
                                    onChange={handleChange}
                                    name="capitalInvestment"
                                  />
                                }
                                disabled={!isEditable}
                                label={t('kyc.investChoices.CapitalInvestment')}
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={values.pension}
                                    onChange={handleChange}
                                    disabled={!isEditable}
                                    name="pension"
                                  />
                                }
                                label={t('kyc.investChoices.Pension')}
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={values.other}
                                    onChange={handleChange}
                                    disabled={!isEditable}
                                    name="other"
                                  />
                                }
                                label={t('kyc.investChoices.Other')}
                              />
                            </FormGroup>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} mt={4}>
                          <Field
                            name="averageExpectedDeposit"
                            component={RadioGroupFormField}
                            options={averageExpectedDepositOptions}
                            isDisabled={!isEditable}
                            label={t('kyc.AverageExpectedDeposits')}
                          />
                        </Grid>
                        <Grid item xs={12} mt={4}>
                          <Field
                            name="depositFrequency"
                            component={RadioGroupFormField}
                            options={depositFrequencyOptions}
                            isDisabled={!isEditable}
                            label={t('kyc.DepositFrequency')}
                          />
                        </Grid>

                        <Grid item xs={12} mt={4}>
                          {activeUserCanSign ? (
                            <>
                              {isKycEnabled &&
                              !isEditable &&
                              !IsToDo /* Activate when KYC should be editable when confirmed already*/ ? (
                                <>
                                  {/* Edit */}
                                  {/* <Button
                                    onClick={() => handleEdit(true)}
                                    variant="outlined"
                                    color="primary"
                                  >
                                    {t('Edit')}
                                  </Button> */}
                                </>
                              ) : isEditable || IsToDo ? (
                                <>
                                  {/* Confirm / Cancel (edit) */}
                                  {isEdit ? (
                                    <Grid container spacing={1}>
                                      <Grid item>
                                        <FormDialogConfirm
                                          handleIsFetch={setIsFetched}
                                          handlekycDataChanged={setDataChanged}
                                          dataChangd={dataChanged}
                                          isEdit={isEdit}
                                          handleIsEdit={handleEdit}
                                        ></FormDialogConfirm>
                                      </Grid>
                                      <Grid item>
                                        <Button
                                          onClick={() => handleEdit(false)}
                                          color="primary"
                                          variant="contained"
                                        >
                                          {t('Cancel')}
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  ) : (
                                    <>
                                      {/* Confirm / Reject */}
                                      <Grid container spacing={1}>
                                        <Grid item>
                                          <FormDialogConfirm
                                            handleIsFetch={setIsFetched}
                                            handlekycDataChanged={
                                              setDataChanged
                                            }
                                            dataChangd={dataChanged}
                                          ></FormDialogConfirm>
                                        </Grid>
                                        <Grid item>
                                          {/* Edit */}
                                          <Button
                                            onClick={() => handleEdit(true)}
                                            variant="outlined"
                                            color="primary"
                                          >
                                            {t('Edit')}
                                          </Button>
                                        </Grid>
                                        <Grid item>
                                          <FormDialogReject
                                            handleIsFetch={setIsFetched}
                                            handlekycDataChanged={
                                              setDataChanged
                                            }
                                            dataChangd={dataChanged}
                                          ></FormDialogReject>
                                        </Grid>
                                      </Grid>
                                    </>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : null}
                        </Grid>
                      </Grid>
                    </form>
                  </CardContent>
                </Card>
              ) : (
                <>
                  <Typography>{t('kyc.NoInfo')}</Typography>
                </>
              )
            }
          </Formik>
        ) : (
          <div>
            <Box display="flex" justifyContent="center" my={6}>
              <CircularProgress />
            </Box>
            <Box display="flex" justifyContent="center" my={6}>
              <Typography> {t('LongLoading')}</Typography>
            </Box>
          </div>
        )}
      </div>
    );
  }

  return (
    <React.Fragment>
      <BasicForm />
    </React.Fragment>
  );
}

export default KycCompanyForm;
