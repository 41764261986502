import {
  Box,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  OutlinedInput,
  Checkbox,
  ListItemText,
  CircularProgress,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Collapse,
  useMediaQuery,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { FaPortfolioDto } from '../../models/portfolio';
import { useApiClient } from '../../hooks/apiClient';
import { getActiveUser } from '../../redux/stateFuncs';
import {
  showPortfolios,
  setPortfolios,
  hidePortfolio,
  showPortfoliosForCustomer,
  hidePortfoliosForCustomer,
  showAllPortfolios,
} from '../../redux/slices/portfolios';
import { showCustomers, setCustomers } from '../../redux/slices/customer';
import { ICustomer } from '../../models/customer';
import { FaContactDto } from '../../models/dto/faContactDto';

interface Props {
  children: React.ReactNode;
  hidePortfolioPicker?: boolean;
}

const InvestmentLayout: React.FunctionComponent<Props> = (props: Props) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>();
  const [error, setError] = useState<string | null>(null);
  const [loadedUser, setLoadedUser] = useState<string | undefined>();
  const api = useApiClient();
  const dispatch = useAppDispatch();

  const users = useAppSelector((state) => state.users);

  const customers = useAppSelector((state) => state.customers);

  const [customerNames, setCustomerNames] = React.useState<string[]>([]);

  const portfolios = useAppSelector((s) => s.portfolios);
  const [portfolioNames, setPortfolioNames] = React.useState<string[]>([]);

  useEffect(() => {
    var activeUser = getActiveUser(users);

    if (
      portfolios.length == 0 ||
      loadedUser != activeUser?.socialSecurityNumber
    ) {
      setIsLoading(true);
      api
        .get<FaContactDto>(
          'api/FaSecurity/GetContactAndPortfolios/' +
            activeUser?.socialSecurityNumber
        )
        .then((res) => {
          // To avoid reloads, set user as loaded
          setLoadedUser(activeUser?.socialSecurityNumber);

          const loadedPortfolios: FaPortfolioDto[] = [];
          res.data.portfolios.map((i) => {
            loadedPortfolios.push({
              id: i.id,
              name: i.name,
              shortName: i.shortName,
              show: false,
              approverId: '',
              customerType: '',
              faPortfolioKey: '',
              ownerId: i.ownerId,
              portfolioAgreement: '',
              portfolioAgreementValue: 0,
              portfolioId: '',
              portfolioType: '',
              statuscode: '',
              type: i.type,
              modelPortfolio: i.modelPortfolio,
              documents: i.documents,
            } as FaPortfolioDto);
          });
          // If not mainUser, only show selected user
          const c = activeUser?.mainUser
            ? users.map((u) => {
                return {
                  contactId: u.socialSecurityNumber,
                  name: u.name,
                  show: false,
                  isCompany: u.isCompany,
                } as ICustomer;
              })
            : users
                .filter((c) => c.id == activeUser?.id)
                .map((u) => {
                  return {
                    contactId: u.socialSecurityNumber,
                    name: u.name,
                    show: false,
                    isCompany: u.isCompany,
                  } as ICustomer;
                });
          dispatch(setCustomers(c));
          // Show all if mainUser
          const activeUsersCustomer = activeUser?.mainUser
            ? c.map((c) => c.contactId)
            : c
                .filter((s) => s.contactId == activeUser?.socialSecurityNumber)
                .map((c) => c.contactId);
          dispatch(showCustomers(activeUsersCustomer));
          setCustomerNames(activeUsersCustomer);

          dispatch(setPortfolios(loadedPortfolios));

          if (activeUsersCustomer.length > 0) {
            if (activeUser?.mainUser) {
              dispatch(showAllPortfolios());
            } else {
              dispatch(showPortfoliosForCustomer(activeUsersCustomer[0]));
            }
          }

          setIsLoading(false);
          setError(null);
        })
        .catch((err) => {
          setError('Error loading portfolio overview');
        });
    } else {
      setCustomerNames(
        customers.filter((c) => c.show == true).map((c) => c.contactId)
      );
    }

    const ports = portfolios.filter((s) => s.show == true).map((p) => p.id);
    setPortfolioNames(ports);
  }, [users]);

  const handlePortfolioChange = (
    event: SelectChangeEvent<typeof portfolioNames>
  ) => {
    const {
      target: { value },
    } = event;

    // On autofill we get a stringified value.
    const v = typeof value === 'string' ? value.split(',') : value;
    setPortfolioNames(v);
    dispatch(showPortfolios(v));
  };

  const handleCustomerChange = (
    event: SelectChangeEvent<typeof portfolioNames>
  ) => {
    const {
      target: { value },
    } = event;
    // On autofill we get a stringified value.
    const v = typeof value === 'string' ? value.split(',') : value;

    //  Find which user is the newly toogled one...
    var missing = customerNames.filter((u) => v.indexOf(u) < 0)[0];
    var added = v.filter((u) => customerNames.indexOf(u) < 0)[0];

    setCustomerNames(v);
    dispatch(showCustomers(v));

    if (added) dispatch(showPortfoliosForCustomer(added));
    if (missing) dispatch(hidePortfoliosForCustomer(missing));
  };

  const handleRemovePortfolio = (portfolioId: string) => {
    dispatch(hidePortfolio(portfolioId));
    const ports = portfolios.filter((s) => s.show == true).map((p) => p.id);
    setPortfolioNames(ports);
  };

  const collapseList = useMediaQuery('(min-width:900px)');

  return (
    <Box>
      {error ? (
        <>
          <Box display="flex" justifyContent="center" my={6}>
            <Typography color="error.main">{error}</Typography>
          </Box>
        </>
      ) : (
        <>
          {isLoading ? (
            <Box display="flex" justifyContent="center" my={6}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Grid
                visibility={
                  props.hidePortfolioPicker != undefined &&
                  props.hidePortfolioPicker == true
                    ? 'hidden'
                    : 'visible'
                }
                display={
                  props.hidePortfolioPicker != undefined &&
                  props.hidePortfolioPicker == true
                    ? 'none'
                    : 'flex'
                }
                container
                className="investment-table round-corner"
                maxWidth={656}
                m={1}
                pb={1}
              >
                <Grid item xs={2} md={2} minWidth={320}>
                  <Box pl={1} pt={2}>
                    <InputLabel id="users-label">
                      {t('PersonCompany')}
                    </InputLabel>
                    <Select
                      labelId="users-label"
                      id="users"
                      displayEmpty
                      multiple
                      value={customerNames}
                      label={t('PersonCompany')}
                      onChange={handleCustomerChange}
                      input={<OutlinedInput label="Tag" />}
                      renderValue={(selected) => (
                        <>
                          {t('ChoosePersons')}...({selected.length}{' '}
                          {t('Chosen').toLowerCase()})
                        </>
                      )}
                      sx={{
                        minWidth: '310px',
                        maxWidth: '310px',
                        height: '32px',
                        '& .MuiOutlinedInput-notchedOutline legend': {
                          width: 0,
                        },
                      }}
                    >
                      {customers.map((p) => {
                        return (
                          <MenuItem value={p.contactId} key={p.contactId}>
                            <Checkbox
                              checked={p.show}
                              value={p.contactId.toString()}
                            />
                            <ListItemText primary={p.name} />
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Box>
                </Grid>
                <Grid item xs={2} md={6} minWidth={320}>
                  <Box pl={1} pt={2} pb={2}>
                    <InputLabel id="portfolios-label">
                      {t('Portfolio')}
                    </InputLabel>
                    <Select
                      labelId="portfolios-label"
                      id="portfolios"
                      displayEmpty
                      multiple
                      value={portfolios
                        .filter((p) => p.show == true)
                        .map((p) => {
                          return p.id;
                        })}
                      label={t('Portfolio')}
                      onChange={handlePortfolioChange}
                      input={<OutlinedInput label="Tag" />}
                      renderValue={(selected) => (
                        <>
                          {' '}
                          {t('ChoosePortfolios')}...({selected.length}{' '}
                          {t('Chosen').toLowerCase()})
                        </>
                      )}
                      sx={{
                        minWidth: '310px',
                        maxWidth: '310px',
                        height: '32px',
                        '& .MuiOutlinedInput-notchedOutline legend': {
                          width: 0,
                        },
                      }}
                    >
                      {portfolios
                        .filter((p) =>
                          customers
                            .filter((c) => c.show == true)
                            .map((c) => c.contactId)
                            .includes(p.ownerId)
                        )
                        .map((p) => {
                          return (
                            <MenuItem value={p.id} key={p.id}>
                              <Checkbox
                                checked={p.show}
                                value={p.id.toString()}
                              />
                              <ListItemText
                                primary={
                                  customers.find(
                                    (c) => c.contactId == p.ownerId
                                  )?.name +
                                  ' - ' +
                                  p.shortName
                                }
                              />
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Collapse in={collapseList} collapsedSize={2}>
                    <Box pl={1} pb={1}>
                      <Typography>
                        {t('Chosen')} {t('Portfolios').toLowerCase()}
                      </Typography>
                      <Box
                        pb={0.625}
                        pt={1}
                        borderBottom={'1px solid var(--main-color)'}
                        borderTop={'1px solid var(--main-color)'}
                        sx={{ maxWidth: 620 }}
                      >
                        <TableContainer component={Box}>
                          <Table
                            sx={{ minWidth: 380, maxWidth: 750 }}
                            size="small"
                            aria-label="portfolios"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell padding="none">
                                  <Typography
                                    variant="body2"
                                    className="investment-table-text"
                                  >
                                    {t('Portfolio')}
                                  </Typography>
                                </TableCell>
                                <TableCell padding="none">
                                  <Typography
                                    variant="body2"
                                    className="investment-table-text"
                                  >
                                    {t('Owner')}
                                  </Typography>
                                </TableCell>
                                <TableCell padding="none">
                                  <Typography
                                    variant="body2"
                                    className="investment-table-text"
                                  >
                                    {t('Type')}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  padding="none"
                                  sx={{
                                    display: { xs: 'none', sm: 'table-cell' },
                                  }}
                                >
                                  <Typography
                                    variant="body2"
                                    className="investment-table-text"
                                  >
                                    {t('Modelportfolio')}
                                  </Typography>
                                </TableCell>
                                <TableCell padding="none"></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {portfolios
                                .filter((p) => p.show == true)
                                .map((p) => {
                                  return (
                                    <TableRow
                                      className="investment-table-row"
                                      key={'pu' + p.id}
                                      sx={{
                                        '&:last-child td, &:last-child th': {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <TableCell padding="none">
                                        <Typography className="investment-table-text">
                                          {p.shortName}
                                        </Typography>
                                      </TableCell>
                                      <TableCell padding="none">
                                        <Typography>
                                          {
                                            customers.find(
                                              (c) => c.contactId == p.ownerId
                                            )?.name
                                          }
                                        </Typography>
                                      </TableCell>
                                      <TableCell padding="none">
                                        <Typography className="investment-table-text">
                                          {p.type}
                                        </Typography>
                                      </TableCell>
                                      <TableCell padding="none">
                                        <Typography className="investment-table-text">
                                          {p.modelPortfolio}
                                        </Typography>
                                      </TableCell>
                                      <TableCell
                                        padding="none"
                                        className="investment-table-delete"
                                        onClick={() =>
                                          handleRemovePortfolio(p.id)
                                        }
                                      >
                                        <Box
                                          width={'100%'}
                                          textAlign={'center'}
                                        >
                                          X
                                        </Box>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    </Box>
                  </Collapse>
                </Grid>
              </Grid>
              <Box>{props.children}</Box>
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default InvestmentLayout;
