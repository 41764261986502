import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUser } from '../../models/user';

const initialState: IUser[] = [];

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    addUsers: (state, action: PayloadAction<IUser[]>) => {
      action.payload.forEach((e) => state.push(e));
    },
    setActiveUser: (state, action: PayloadAction<string>) => {
      const oldActiveUser: IUser | undefined = state.find(
        (user) => user.active === true
      );
      if (oldActiveUser) {
        oldActiveUser.active = false;
      }
      const newActiveUser: IUser | undefined = state.find(
        (user) => user.id === action.payload
      );
      if (newActiveUser) {
        newActiveUser.active = true;
      }
    },
    deactivateUsers: (state) => {
      state.forEach((c) => (c.active = false));
    },
    clearUsers: (state) => {
      state = [];
    },
    
  },
});

export const { addUsers, setActiveUser, deactivateUsers, clearUsers } =
  usersSlice.actions;
export default usersSlice.reducer;
