import InvestmentProfileUser from './forms/InvestmentProfileUser';
import InvestmentProfileCompany from './forms/InvestmentProfileCompany';
import { useAppSelector } from '../redux/hooks';
import { getActiveUser } from '../redux/stateFuncs';
import { Box, Button } from '@mui/material';
import ReactToPrint from 'react-to-print';
import { useRef } from 'react';

const InvestmentProfile = () => {
  const users = useAppSelector((state) => state.users);

  var activeUser = getActiveUser(users);
  const componentRef = useRef<HTMLDivElement>(null)

  return (
    <>
      <div ref={componentRef}>
        {activeUser?.isCompany == true ? (
          <InvestmentProfileCompany />
        ) : (
          <InvestmentProfileUser />
        )}
      </div>
      <Box m={2}>
        <ReactToPrint
          trigger={() => <Button variant="contained" color="primary">Print</Button>}
          content={() => componentRef.current}
        />
      </Box>
    </>
  );
};

export default InvestmentProfile;
