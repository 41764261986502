import React, { useEffect, useState } from 'react';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Grid from '@mui/material/Unstable_Grid2';
import {
  DataGridPremium as DataGrid,
  GridColDef,
  GridRenderCellParams,
} from '@mui/x-data-grid-premium';

import {
  Button,
  Divider,
  Typography,
  Box,
  CircularProgress,
  Stack,
  TextField,
} from '@mui/material';
import {
  ToDoEnum,
  TradeOrderBaseTypeEnum,
  TradeOrderOperationEnum,
} from '../../Enums';
import { Common } from '../../util/common';
import { useApiClient } from '../../hooks/apiClient';
import SecurityDetails, { SecurityDetailsSize } from '../SecurityDetails';
import { AxiosInstance } from 'axios';
import { getActiveUser, getMainUser } from '../../redux/stateFuncs';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { setToDoComplete } from '../../redux/slices/todo';
import { getOrderTypeName } from '../../util/translations';
import CurrencyField from '../formfields/CurrencyField';
import {
  IMultipleTradeOrdersDto,
  ITradeOrderDto,
} from '../../models/dto/tradeOrderDto';
import StringField from '../formfields/StringField';

function TradeOrder(props: any) {
  const api = useApiClient();
  const users = useAppSelector((state) => state.users);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const activeUserCanSign = getActiveUser(users)?.canSign;

  const [isFetched, setIsFetched] = useState(false);

  const [opportunityTradeorders, setOpportunityTradeorders] = useState<
    IMultipleTradeOrdersDto[]
  >([]);

  const handleClickAccept = (id: string, isAccept: boolean) => {
    setIsFetched(false);
    const opportunityTradeorder: IMultipleTradeOrdersDto | undefined =
      opportunityTradeorders.find((p) => p.opportunityId === id);

    var activeUser = getActiveUser(users);
    var activeEntityId = activeUser?.id;
    var entityType = activeUser?.isCompany == true ? 'account' : 'contact';

    const mainUserId = getMainUser(users)?.id;
    let statuscode = isAccept ? 'Won' : 'Canceled';
    api
      .post('api/tradeorder/update', {
        ...opportunityTradeorder,
        statuscode: statuscode,
        approverId: mainUserId,
        ownerId: activeEntityId,
        customerType: entityType,
      })
      .then((res) => {
        const newOpportunityTradeorders = opportunityTradeorders.filter((p) => {
          if (p.opportunityId !== opportunityTradeorder?.opportunityId) {
            return true;
          }
          return false;
        });
        if (newOpportunityTradeorders.length > 0) {
          setOpportunityTradeorders(newOpportunityTradeorders);
          setIsFetched(true);
        } else {
          dispatch(setToDoComplete(ToDoEnum.TradeOrders));
        }
      });
  };

  const handleClickGetAllExante = (orders: ITradeOrderDto[]) => {
    setIsFetched(false);
    let ids = orders.map((p) => p.tradeOrderRequestId).join(',');
    api
      .get(
        `api/DocumentGenerator/ExecutePortfolioTradeorderFlowForMultipleFiles/${ids}`,
        {
          method: 'GET',
          responseType: 'text',
        }
      )
      .then(
        (res) => {
          let result = JSON.parse(res.data);
          if (result.status == 'Running' || result.status == 'Queued') {
            checkFlowResult(result.id);
          } else if (result.status == 'Error') {
            alert(result.message);
            setIsFetched(true);
          } else {
            let documentObject = Common.getObject(result.model, 'document');
            getDocumentContent(documentObject.document.id);
          }
        },
        (err) => {
          setIsFetched(true);
        }
      );
  };

  const handleClickExante = (tradeOrderRequestId: string) => {
    setIsFetched(false);
    api
      .get(
        `api/DocumentGenerator/ExecuteTradeorderFlow/${tradeOrderRequestId}`,
        {
          method: 'GET',
          responseType: 'text',
        }
      )
      .then(
        (res) => {
          let result = JSON.parse(res.data);
          if (result.status == 'Running' || result.status == 'Queued') {
            checkFlowResult(result.id);
          } else if (result.status == 'Error' || result.status == 500) {
            alert(result.message);
            setIsFetched(true);
          } else {
            getDocumentIds(result);
          }
        },
        (err) => {
          setIsFetched(true);
        }
      );
  };

  const getDocumentIds = (result: any) => {
    if (Object.keys(result.model).length > 1) {
      for (var prop in result.model) {
        setIsFetched(false);
        let documentObject = Common.getObject(result.model[prop], 'document');
        getDocumentContent(documentObject.document.id);
      }
    } else {
      let documentObject = Common.getObject(result.model, 'document');
      getDocumentContent(documentObject.document.id);
    }
  };

  const checkFlowResult = (executionId: string) => {
    api
      .get(`api/DocumentGenerator/CheckDocumentExecution/${executionId}`, {
        method: 'GET',
        responseType: 'text',
      })
      .then(
        (res) => {
          let result = JSON.parse(res.data);
          if (result.status == 'Running' || result.status == 'Queued') {
            checkFlowResult(result.id);
          } else if (result.status == 'Error') {
            setIsFetched(true);
          } else {
            getDocumentIds(result);
          }
        },
        (err) => {
          setIsFetched(true);
        }
      );
  };

  const getDocumentContent = (documentId: string) => {
    api
      .get(`api/DocumentGenerator/GetDocumentContent/${documentId}`, {
        method: 'GET',
        responseType: 'text',
      })
      .then(
        (res) => {
          const url = 'data:application/octet-stream;base64,' + res.data;
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `Exante_${documentId}.pdf`);
          document.body.appendChild(link);
          setIsFetched(true);
          link.click();
        },
        (err) => {
          setIsFetched(true);
        }
      );
  };

  const getTradeUnitTitle = (order: ITradeOrderDto): string => {
    if (
      order.tradeOrderType == TradeOrderOperationEnum.BuyUnitsB ||
      order.tradeOrderType == TradeOrderOperationEnum.SellUnitsS
    ) {
      return t('tradeOrders.TradeAmountUnit');
    }

    return t('tradeOrders.TradeAmount');
  };

  const columnsDeskstop: GridColDef[] = [
    {
      field: 'securityName',
      headerClassName: 'trade-order-header',
      headerName: t('tradeOrders.Security'),
      flex: 0.3,
      renderCell: (params: GridRenderCellParams) => (
        <Typography>{params.value}</Typography>
      ),
    },
    {
      field: 'tradeOrderType',
      headerClassName: 'trade-order-header',
      headerName: t('tradeOrders.TradeOrderType'),
      flex: 0.12,
      renderCell: (params: GridRenderCellParams) => (
        <Typography>{getOrderTypeName(params.value)}</Typography>
      ),
    },
    {
      field: 'transactionFee',
      headerClassName: 'trade-order-header',
      headerName: t('tradeOrders.Fee'),
      flex: 0.115,
      renderCell: (params: GridRenderCellParams) =>
        params.row.tradeOrderType == TradeOrderOperationEnum.BuyUnitsB ||
        params.row.tradeOrderType == TradeOrderOperationEnum.SellUnitsS ? (
          <StringField value={'TBC'}></StringField>
        ) : (
          <CurrencyField value={params.value}></CurrencyField>
        ),
    },
    {
      field: 'tradeAmount',
      headerClassName: 'trade-order-header',
      headerName: t('tradeOrders.TradeAmount'),
      flex: 0.185,
      renderCell: (params: GridRenderCellParams) =>
        params.row.tradeOrderType == TradeOrderOperationEnum.BuyUnitsB ||
        params.row.tradeOrderType == TradeOrderOperationEnum.SellUnitsS ? (
          <CurrencyField
            value={params.row.amount}
            suffix={'andelar'}
            decimals={6}
            fixedDecimals={false}
          ></CurrencyField>
        ) : (
          <CurrencyField
            value={params.row.tradeAmount}
            suffix={params.row.currencyCode}
          ></CurrencyField>
        ),
    },
    {
      field: 'isinCode',
      headerClassName: 'trade-order-header',
      headerName: t('Details'),
      flex: 0.125,
      renderCell: (params: GridRenderCellParams) => (
        <SecurityDetails
          isinCode={params.value}
          api={api}
          size={SecurityDetailsSize.Large}
        />
      ),
    },
    {
      field: 'tradeOrderRequestId',
      headerClassName: 'trade-order-header',
      headerName: '',
      flex: 0.255,
      renderCell: (params: GridRenderCellParams) => (
        <Button
          size="small"
          variant="outlined"
          color="primary"
          disabled={
            params.row.tradeOrderType == TradeOrderOperationEnum.BuyUnitsB ||
            params.row.tradeOrderType == TradeOrderOperationEnum.SellUnitsS
          }
          startIcon={<InsertDriveFileIcon />}
          onClick={() => handleClickExante(params.row.tradeOrderRequestId)}
        >
          {t('Exante')}
        </Button>
      ),
    },
  ];

  const columnsDeskstopMedium: GridColDef[] = [
    {
      field: 'securityName',
      headerClassName: 'trade-order-header',
      headerName: t('tradeOrders.Security'),
      flex: 0.3,
      renderHeader: () => (
        <Box>
          <Typography className="small-dg-header">
            {t('tradeOrders.Security')}
          </Typography>
        </Box>
      ),
      renderCell: (params: GridRenderCellParams) => (
        <Typography className="small-dg-text2">{params.value}</Typography>
      ),
    },
    {
      field: 'tradeOrderType',
      headerClassName: 'trade-order-header',
      headerName: t('tradeOrders.TradeOrderType'),
      flex: 0.12,
      renderHeader: () => (
        <Box>
          <Typography className="small-dg-header">
            {t('tradeOrders.TradeOrderType')}
          </Typography>
        </Box>
      ),
      renderCell: (params: GridRenderCellParams) => (
        <Typography className="small-dg-text2">
          {getOrderTypeName(params.value)}
        </Typography>
      ),
    },
    {
      field: 'transactionFee',
      headerClassName: 'trade-order-header',
      headerName: t('tradeOrders.Fee'),
      flex: 0.115,
      renderHeader: () => (
        <Box>
          <Typography className="small-dg-header">
            {t('tradeOrders.Fee')}
          </Typography>
        </Box>
      ),
      renderCell: (params: GridRenderCellParams) =>
        params.row.tradeOrderType == TradeOrderOperationEnum.BuyUnitsB ||
        params.row.tradeOrderType == TradeOrderOperationEnum.SellUnitsS ? (
          <StringField value={'TBC'} className="small-dg-text2"></StringField>
        ) : (
          <CurrencyField
            value={params.value}
            className="small-dg-text2"
          ></CurrencyField>
        ),
    },
    {
      field: 'tradeAmount',
      headerClassName: 'trade-order-header',
      headerName: t('tradeOrders.TradeAmount'),
      flex: 0.185,
      renderHeader: () => (
        <Box>
          <Typography className="small-dg-header">
            {t('tradeOrders.TradeAmount')}
          </Typography>
        </Box>
      ),
      renderCell: (params: GridRenderCellParams) =>
        params.row.tradeOrderType == TradeOrderOperationEnum.BuyUnitsB ||
        params.row.tradeOrderType == TradeOrderOperationEnum.SellUnitsS ? (
          <CurrencyField
            value={params.row.amount}
            suffix={'andelar'}
            decimals={6}
            fixedDecimals={false}
          ></CurrencyField>
        ) : (
          <CurrencyField
            value={params.row.tradeAmount}
            suffix={params.row.currencyCode}
          ></CurrencyField>
        ),
    },
    {
      field: 'isinCode',
      headerClassName: 'trade-order-header',
      headerName: t('InformationShort'),
      flex: 0.125,
      renderHeader: () => (
        <Box>
          <Typography className="small-dg-header">
            {t('InformationShort')}
          </Typography>
        </Box>
      ),
      renderCell: (params: GridRenderCellParams) => (
        <SecurityDetails
          isinCode={params.value}
          api={api}
          size={SecurityDetailsSize.Medium}
        />
      ),
    },
    {
      field: 'tradeOrderRequestId',
      headerClassName: 'trade-order-header',
      headerName: '',
      flex: 0.255,
      renderCell: (params: GridRenderCellParams) => (
        <Button
          size="small"
          variant="outlined"
          color="primary"
          className="small-dg-text2"
          disabled={
            params.row.tradeOrderType == TradeOrderOperationEnum.BuyUnitsB ||
            params.row.tradeOrderType == TradeOrderOperationEnum.SellUnitsS
          }
          startIcon={<InsertDriveFileIcon />}
          onClick={() => handleClickExante(params.row.tradeOrderRequestId)}
        >
          {t('Exante')}
        </Button>
      ),
    },
  ];

  const columnsDesktopSmall: GridColDef[] = [
    {
      field: 'securityName',
      headerClassName: 'trade-order-header',
      headerName: t('tradeOrders.Security'),
      flex: 0.4,
      renderHeader: () => (
        <Box>
          <Typography className="small-dg-header">
            {t('tradeOrders.Security')}
          </Typography>
          <Typography className="small-dg-header italic">
            {t('tradeOrders.TradeOrderType')}
          </Typography>
        </Box>
      ),
      renderCell: (params: GridRenderCellParams) => (
        <Box>
          <Typography className="small-dg-text2">
            {params.row.securityName}
          </Typography>
          <Typography className="small-dg-text2 italic">
            {getOrderTypeName(params.row.tradeOrderType)}
          </Typography>
        </Box>
      ),
    },
    {
      field: 'tradeAmount',
      headerClassName: 'trade-order-header',
      headerName: t('tradeOrders.TradeAmount'),
      flex: 0.3,
      renderHeader: () => (
        <Box>
          <Typography className="small-dg-header">
            {t('tradeOrders.TradeAmount')}
          </Typography>
          <Typography className="small-dg-header italic">
            {t('tradeOrders.Fee')}
          </Typography>
        </Box>
      ),
      renderCell: (params: GridRenderCellParams) => (
        <Box>
          {params.row.tradeOrderType == TradeOrderOperationEnum.BuyUnitsB ||
          params.row.tradeOrderType == TradeOrderOperationEnum.SellUnitsS ? (
            <CurrencyField
              value={params.row.amount}
              suffix={'andelar'}
              className="small-dg-text2"
              decimals={6}
              fixedDecimals={false}
            ></CurrencyField>
          ) : (
            <CurrencyField
              value={params.row.tradeAmount}
              suffix={params.row.currencyCode}
              className="small-dg-text2"
            ></CurrencyField>
          )}
          {params.row.tradeOrderType == TradeOrderOperationEnum.BuyUnitsB ||
          params.row.tradeOrderType == TradeOrderOperationEnum.SellUnitsS ? (
            <StringField
              value={'TBC'}
              className="small-dg-text2 italic"
            ></StringField>
          ) : (
            <CurrencyField
              value={params.row.transactionFee}
              className="small-dg-text2 italic"
            ></CurrencyField>
          )}
        </Box>
      ),
    },
    {
      field: 'isinCode',
      headerClassName: 'trade-order-header',
      headerName: t('InformationShort'),
      flex: 0.3,
      renderHeader: () => (
        <Box>
          <Typography className="small-dg-header">
            {t('InformationShort')}
          </Typography>
        </Box>
      ),
      renderCell: (params: GridRenderCellParams) => (
        <Box>
          <SecurityDetails
            isinCode={params.value}
            api={api}
            size={SecurityDetailsSize.Small}
          />
          <br></br>
          <Button
            size="small"
            variant="outlined"
            disabled={
              params.row.tradeOrderType == TradeOrderOperationEnum.BuyUnitsB ||
              params.row.tradeOrderType == TradeOrderOperationEnum.SellUnitsS
            }
            color="primary"
            className="small-dg-text2 small-btn"
            onClick={() => handleClickExante(params.row.tradeOrderRequestId)}
          >
            {t('Exante')}
          </Button>
        </Box>
      ),
    },
  ];

  const columnsMobile: GridColDef[] = [
    {
      field: 'securityName',
      headerClassName: 'trade-order-header',
      headerName: t('tradeOrders.Security'),
      flex: 0.4,
      renderHeader: () => (
        <Box>
          <Typography className="small-dg-header">
            {t('tradeOrders.Security')}
          </Typography>
          <Typography className="small-dg-header italic">
            {t('tradeOrders.TradeOrderType')}
          </Typography>
        </Box>
      ),
      renderCell: (params: GridRenderCellParams) => (
        <Box>
          <Typography className="small-dg-text2">
            {params.row.securityName}
          </Typography>
          <Typography className="small-dg-text2 italic">
            {getOrderTypeName(params.row.tradeOrderType)}
          </Typography>
          <Button
            size="small"
            variant="outlined"
            color="primary"
            className="small-dg-text2 small-btn"
            disabled={
              params.row.tradeOrderType == TradeOrderOperationEnum.BuyUnitsB ||
              params.row.tradeOrderType == TradeOrderOperationEnum.SellUnitsS
            }
            onClick={() => handleClickExante(params.row.tradeOrderRequestId)}
          >
            {t('Exante')}
          </Button>
        </Box>
      ),
    },
    {
      field: 'tradeAmount',
      headerClassName: 'trade-order-header',
      headerName: t('tradeOrders.TradeAmount'),
      flex: 0.3,
      renderHeader: () => (
        <Box>
          <Typography className="small-dg-header">
            {t('tradeOrders.TradeAmount')}
          </Typography>
          <Typography className="small-dg-header italic">
            {t('tradeOrders.Fee')}
          </Typography>
        </Box>
      ),
      renderCell: (params: GridRenderCellParams) => (
        <Box>
          {params.row.tradeOrderType == TradeOrderOperationEnum.BuyUnitsB ||
          params.row.tradeOrderType == TradeOrderOperationEnum.SellUnitsS ? (
            <CurrencyField
              value={params.row.amount}
              suffix={'andelar'}
              className="small-dg-text2"
              decimals={6}
              fixedDecimals={false}
            ></CurrencyField>
          ) : (
            <CurrencyField
              value={params.row.tradeAmount}
              suffix={params.row.currencyCode}
              className="small-dg-text2"
            ></CurrencyField>
          )}
          {params.row.tradeOrderType == TradeOrderOperationEnum.BuyUnitsB ||
          params.row.tradeOrderType == TradeOrderOperationEnum.SellUnitsS ? (
            <StringField
              value={'TBC'}
              className="small-dg-text2 italic"
            ></StringField>
          ) : (
            <CurrencyField
              value={params.row.transactionFee}
              className="small-dg-text2 italic"
            ></CurrencyField>
          )}
          <SecurityDetails
            isinCode={params.value}
            api={api}
            size={SecurityDetailsSize.Small}
          />
        </Box>
      ),
    },
  ];

  function Order(props: any) {
    return (
      <>
        {props.Orders.length > 0 ? (
          <Box>
            <DataGrid
              rows={props.Orders as ITradeOrderDto[]}
              columns={props.Columns}
              pageSizeOptions={[]}
              autoHeight
              disableColumnMenu
              getRowId={(row) => row.tradeOrderRequestId}
              rowHeight={props.RowHeight}
              sx={{
                width: '100%',
                border: 0,
                '& .MuiDataGrid-columnSeparator': {
                  display: 'none',
                },
                '& .MuiDataGrid-columnHeader': {
                  paddingLeft: props.Padding,
                  paddingRight: props.Padding,
                },
                '& .MuiDataGrid-cell': {
                  paddingLeft: props.Padding,
                  paddingRight: props.Padding,
                },
              }}
            />
          </Box>
        ) : (
          <></>
        )}
      </>
    );
  }

  useEffect(() => {
    let activeEntityId = getActiveUser(users)?.id;

    api.get<IMultipleTradeOrdersDto[]>(`api/tradeorder/${activeEntityId}`).then(
      (res) => {
        setIsFetched(false);
        setOpportunityTradeorders(res.data);
        setIsFetched(true);
      },
      (err) => {
        console.error(err);
      }
    );
  }, []);

  return (
    <React.Fragment>
      <Box display={'flex'} height="100%" sx={{ overflowX: 'hidden' }}>
        <Box flex={1} sx={{ display: { xs: 'none', lg: 'block' } }}>
          <Grid container spacing={1} minWidth="1070px" margin={0}>
            <Grid xs={12} md={12}>
              {!isFetched && (
                <Box display="flex" justifyContent="center" my={6}>
                  <CircularProgress />
                </Box>
              )}
              <Box marginLeft="16px" marginBottom={1}>
                {isFetched &&
                  opportunityTradeorders &&
                  opportunityTradeorders.map((c) => {
                    return (
                      <Grid container key={c.opportunityId}>
                        <Grid xs={12}>
                          <Typography>{c.name}</Typography>
                        </Grid>
                        <Grid xs={12}>
                          <Typography>{t('ResponsibleCoeli')}:</Typography>
                          <Typography variant="body2" fontWeight={'bold'}>
                            {c.ownerName}
                          </Typography>
                        </Grid>
                        <Grid xs={12}>
                          <Order
                            Orders={c.orders}
                            Columns={columnsDeskstop}
                            RowHeight={52}
                            Padding={'8px'}
                          />
                        </Grid>
                        <Grid xs={12}>
                          <TextField
                            id="outlined-multiline-static"
                            label="Motivation"
                            fullWidth
                            multiline
                            disabled
                            rows={4}
                            defaultValue={c.motivation ?? ''}
                          />
                        </Grid>
                        <Grid xs={12} pt={2}>
                          {activeUserCanSign ? (
                            <>
                              <Button
                                size="small"
                                sx={{ ml: 2 }}
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                  handleClickAccept(c.opportunityId, true)
                                }
                                startIcon={<CheckCircleOutlineIcon />}
                              >
                                {t('Accept')}
                              </Button>
                              <Button
                                size="small"
                                sx={{ ml: 2 }}
                                variant="outlined"
                                color="primary"
                                onClick={() =>
                                  handleClickAccept(c.opportunityId, false)
                                }
                                startIcon={<HighlightOffIcon />}
                              >
                                {t('Reject')}
                              </Button>
                            </>
                          ) : null}
                          <Button
                            size="small"
                            sx={{ ml: 2 }}
                            variant="outlined"
                            color="primary"
                            onClick={() => handleClickGetAllExante(c.orders)}
                            startIcon={<InsertDriveFileIcon />}
                          >
                            {t('AllExante')}
                          </Button>
                        </Grid>
                      </Grid>
                    );
                  })}
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box flex={1} sx={{ display: { xs: 'none', md: 'block', lg: 'none' } }}>
          <Grid container spacing={1} minWidth="800px" margin={0}>
            <Grid xs={12} md={12}>
              {!isFetched && (
                <Box display="flex" justifyContent="center" my={6}>
                  <CircularProgress />
                </Box>
              )}
              <Box marginLeft="16px" marginRight="16px">
                {isFetched &&
                  opportunityTradeorders &&
                  opportunityTradeorders.map((c) => {
                    return (
                      <Grid container key={c.opportunityId}>
                        <Grid xs={12}>
                          <Typography>{c.name}</Typography>
                        </Grid>
                        <Grid xs={12}>
                          <Typography>{t('ResponsibleCoeli')}:</Typography>
                          <Typography variant="body2" fontWeight={'bold'}>
                            {c.ownerName}
                          </Typography>
                        </Grid>
                        <Grid xs={12}>
                          <Order
                            Orders={c.orders}
                            Columns={columnsDeskstopMedium}
                            RowHeight={52}
                            Padding={'8px'}
                          />
                        </Grid>
                        <Grid xs={12}>
                          <TextField
                            id="outlined-multiline-static"
                            label="Motivation"
                            fullWidth
                            multiline
                            disabled
                            rows={4}
                            defaultValue={c.motivation ?? ''}
                          />
                        </Grid>
                        <Grid xs={12} pt={2}>
                          {activeUserCanSign ? (
                            <>
                              <Button
                                size="small"
                                sx={{ ml: 2 }}
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                  handleClickAccept(c.opportunityId, true)
                                }
                                startIcon={<CheckCircleOutlineIcon />}
                              >
                                {t('Accept')}
                              </Button>
                              <Button
                                size="small"
                                sx={{ ml: 2 }}
                                variant="outlined"
                                color="primary"
                                onClick={() =>
                                  handleClickAccept(c.opportunityId, false)
                                }
                                startIcon={<HighlightOffIcon />}
                              >
                                {t('Reject')}
                              </Button>
                            </>
                          ) : null}
                          <Button
                            size="small"
                            sx={{ ml: 2 }}
                            variant="outlined"
                            color="primary"
                            onClick={() => handleClickGetAllExante(c.orders)}
                            startIcon={<InsertDriveFileIcon />}
                          >
                            {t('AllExante')}
                          </Button>
                        </Grid>
                      </Grid>
                    );
                  })}
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box flex={1} sx={{ display: { xs: 'none', sm: 'block', md: 'none' } }}>
          <Grid container spacing={1} margin={0}>
            <Grid xs={12} md={12}>
              {!isFetched && (
                <Box display="flex" justifyContent="center" my={6}>
                  <CircularProgress />
                </Box>
              )}
              <Box marginLeft="8px" marginRight="8px">
                {isFetched &&
                  opportunityTradeorders &&
                  opportunityTradeorders.map((c) => {
                    return (
                      <Grid container key={c.opportunityId}>
                        <Grid xs={12}>
                          <Typography>{c.name}</Typography>
                        </Grid>
                        <Grid xs={12}>
                          <Typography>{t('ResponsibleCoeli')}:</Typography>
                          <Typography variant="body2" fontWeight={'bold'}>
                            {c.ownerName}
                          </Typography>
                        </Grid>
                        <Grid xs={12}>
                          <Order
                            Orders={c.orders}
                            Columns={columnsDesktopSmall}
                            RowHeight={64}
                            Padding={'0px'}
                          />
                        </Grid>
                        <Grid xs={12}>
                          <TextField
                            id="outlined-multiline-static"
                            label="Motivation"
                            fullWidth
                            multiline
                            disabled
                            rows={4}
                            defaultValue={c.motivation ?? ''}
                          />
                        </Grid>
                        <Grid xs={12} pt={2}>
                          {activeUserCanSign ? (
                            <>
                              <Button
                                size="small"
                                sx={{ ml: 2 }}
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                  handleClickAccept(c.opportunityId, true)
                                }
                                startIcon={<CheckCircleOutlineIcon />}
                              >
                                {t('Accept')}
                              </Button>
                              <Button
                                size="small"
                                sx={{ ml: 2 }}
                                variant="outlined"
                                color="primary"
                                onClick={() =>
                                  handleClickAccept(c.opportunityId, false)
                                }
                                startIcon={<HighlightOffIcon />}
                              >
                                {t('Reject')}
                              </Button>
                            </>
                          ) : null}
                          <Button
                            size="small"
                            sx={{ ml: 2, mt: 2 }}
                            variant="outlined"
                            color="primary"
                            onClick={() => handleClickGetAllExante(c.orders)}
                            startIcon={<InsertDriveFileIcon />}
                          >
                            {t('AllExante')}
                          </Button>
                        </Grid>
                      </Grid>
                    );
                  })}
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box flex={1} sx={{ display: { xs: 'block', sm: 'none' } }}>
          <Grid container spacing={1} margin={0}>
            <Grid xs={12} md={12}>
              {!isFetched && (
                <Box display="flex" justifyContent="center" my={6}>
                  <CircularProgress />
                </Box>
              )}
              <Box>
                {isFetched &&
                  opportunityTradeorders &&
                  opportunityTradeorders.map((c) => {
                    return (
                      <Grid container key={c.opportunityId}>
                        <Grid xs={12}>
                          <Typography>{c.name}</Typography>
                        </Grid>
                        <Grid xs={12}>
                          <Typography>{t('ResponsibleCoeli')}:</Typography>
                          <Typography variant="body2" fontWeight={'bold'}>
                            {c.ownerName}
                          </Typography>
                        </Grid>
                        <Grid xs={12}>
                          <Order
                            Orders={c.orders}
                            Columns={columnsMobile}
                            RowHeight={64}
                            Padding={'0px'}
                          />
                        </Grid>
                        <Grid xs={12}>
                          <TextField
                            id="outlined-multiline-static"
                            label="Motivation"
                            fullWidth
                            multiline
                            disabled
                            rows={4}
                            defaultValue={c.motivation ?? ''}
                          />
                        </Grid>
                        <Grid xs={12} pt={2}>
                          {activeUserCanSign ? (
                            <>
                              <Button
                                size="small"
                                sx={{ ml: 2 }}
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                  handleClickAccept(c.opportunityId, true)
                                }
                                startIcon={<CheckCircleOutlineIcon />}
                              >
                                {t('Accept')}
                              </Button>
                              <Button
                                size="small"
                                sx={{ ml: 2 }}
                                variant="outlined"
                                color="primary"
                                onClick={() =>
                                  handleClickAccept(c.opportunityId, false)
                                }
                                startIcon={<HighlightOffIcon />}
                              >
                                {t('Reject')}
                              </Button>
                            </>
                          ) : null}
                          <Button
                            size="small"
                            sx={{ ml: 2, mt: 2 }}
                            variant="outlined"
                            color="primary"
                            onClick={() => handleClickGetAllExante(c.orders)}
                            startIcon={<InsertDriveFileIcon />}
                          >
                            {t('AllExante')}
                          </Button>
                        </Grid>
                      </Grid>
                    );
                  })}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </React.Fragment>
  );
}

export default TradeOrder;
