import { CircularProgress, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { format } from 'date-fns';
import { use } from 'i18next';
import { useCallback, useEffect, useState } from 'react';
import { useInfront } from '../../../hooks/InFront';
import { FaIsinCodeDto } from '../../../models/dto/faIsinCodeDto';
import { useAppSelector } from '../../../redux/hooks';
import { getActiveUser } from '../../../redux/stateFuncs';
import { useApiClient } from '../../../hooks/apiClient';

declare var Infront: any; //Loaded in HTML

interface INewsWidgetProps {
  symbols?: string[];
  feeds?: number[];
  id: string;
}

export const NewsWidget = (props: INewsWidgetProps) => {
  const { getSymbolAndFeedByIsin, infront } = useInfront();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [elementId, setElementId] = useState<string>(props.id);
  const [error, setError] = useState<string | null>(null);

  const [instruments, setInstruments] = useState<any[]>([]);
  const [feeds, setFeeds] = useState<number[]>([]);

  const api = useApiClient();
  const users = useAppSelector((state) => state.users);
  var activeUser = getActiveUser(users);

  useEffect(() => {
    async function setupWidget() {
      if (infront?.ready) {
        var symbolList: any[] = [];
        // If defined symbols, use them
        if (props.symbols) {
          for (var sym of props.symbols) {
            var st = new Infront.Instrument(
              ...(await getSymbolAndFeedByIsin(
                infront?.infrontService.infrontUI.sdk,
                sym
              ))
            );
            symbolList.push(st);
          }
          console.log(symbolList);
          setInstruments(symbolList);
          setIsLoading(false);
        } // If no defined symbols, get users portfolio symbols
        else if (props.feeds) {
          setFeeds(props.feeds);
          setIsLoading(false);
        } else {
          var now = format(new Date(), 'yyyy-MM-dd');

          api
            .get<FaIsinCodeDto[]>(
              'api/FaSecurity/GetContactHoldingsIsin/' +
                activeUser?.socialSecurityNumber
            )
            .then(async (res) => {
              setError(null);

              for (var sym of res.data) {
                var st = new Infront.Instrument(
                  ...(await getSymbolAndFeedByIsin(
                    infront?.infrontService.infrontUI.sdk,
                    sym.isinCode
                  ))
                );
                symbolList.push(st);
              }

              setInstruments(symbolList);
              setIsLoading(false);
            })
            .catch((err) => {
              setError('Error loading portfolio symbols');
            });
        }
      }
    }

    setupWidget();
  }, [infront, props.symbols]);

  useEffect(() => {
    var ui = infront?.infrontService.infrontUI;
    var opts = new Infront.NewsListWidgetOptions();
    opts.columns = ['TIME', 'HEADLINE', 'SHORT_SOURCE'];

    if (instruments.length > 0) {
      opts.instruments = instruments;
      opts.paging = true;
    } else {
      opts.feeds = feeds;
      // 18031 = coeli top news
      if (feeds?.includes(18031)) opts.paging = false;
      else opts.paging = true;
    }

    opts.streaming = true;
    opts.pageItems = 12;

    opts.enableRegionSelector = true;
    opts.enableSourceSelector = true;

    const container = document.getElementById(elementId);
    if (container != null && (feeds.length > 0 || instruments.length > 0)) {
      container.innerHTML = '';
      ui.newsListWidget(elementId, opts);
    }
  }, [feeds, instruments]);

  return (
    <>
      <Box className="news-notitle">
        {error ? (
          <>
            <Box display="flex" justifyContent="center" my={6}>
              <Typography color="error.main">{error}</Typography>
            </Box>
          </>
        ) : (
          <>
            {isLoading ? (
              <Box m={6}>
                <CircularProgress></CircularProgress>
              </Box>
            ) : (
              <></>
            )}
          </>
        )}
        <Box id={elementId} className="infront" />
      </Box>
    </>
  );
};
