import React from "react";
import { FieldProps, getIn } from "formik";
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";

export const RadioGroupFormField: React.FC<FieldProps & {
    isDisabled: boolean;
    options: Array<{ label: string, value: string }>;
    label: string;
}> = ({
    field,
    form,
    isDisabled,
    options,
    label,
    ...props
}) => {
        const errorText: string = getIn(form.touched, field.name) && getIn(form.errors, field.name);
        return (
            <FormControl component="fieldset">
                <FormLabel component="legend">{label}</FormLabel>
                <RadioGroup
                    {...field}
                    {...props}
                >
                    {options.map(op => (
                        <FormControlLabel key={op.value} value={op.value} disabled={isDisabled} control={<Radio />} label={op.label} />
                    ))}
                </RadioGroup>
            </FormControl >
        );
    };