import { Grid, Typography } from '@mui/material';
import CoeliNews from '../../components/widgets/CoeliNews';

export default function TopNews() {
  return (
    <>
      <Grid container>
        <Grid item xs={12} p={1}>
          <CoeliNews></CoeliNews>
        </Grid>
      </Grid>
    </>
  );
}
