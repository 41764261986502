import { BottomNavigation, styled } from '@mui/material';
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { pages } from '../../routes';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setNavigation } from '../../redux/slices/navigation';
import { getNavigationLink } from '../../util/common';

export default function BottomBar() {
  const { t } = useTranslation();
  const [value, setValue] = React.useState('');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const navigation = useAppSelector((state) => state.navigation);

  useEffect(() => {
    setValue(navigation);
  }, [navigation]);

 
  return (
    <BottomNavigation
      className="bottomBar"
      showLabels
      value={value}
      onChange={(event, nav) => {
        dispatch(setNavigation(nav));
        navigate(nav);
      }}
    >
      {pages.map((page, index) => (
        <BottomNavigationAction
          key={page.nav}
          label={t(page.title)}
          icon={<page.icon className="side-bar-icon"></page.icon>}
          value={getNavigationLink(page)}
          sx={{      
            '&.Mui-selected': {
              backgroundColor: '#f5e8e0'
            },      
            '& .Mui-selected': {
              fontSize: '0.75rem !important'              
            }
          }}
        />
      ))}
    </BottomNavigation>
  );
}
