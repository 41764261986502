import { Typography } from '@mui/material';
import CurrencyFormat from '../../util/currency-format/currency-format';

interface NumberFieldProps {
  value?: number;
  decimals?: number;
  className?: string;
}

export default function NumberField(props: NumberFieldProps) {
  const { decimals = 2 } = props;

  return (
    <>
      {props.value == null ? (
        ''
      ) : (
        <CurrencyFormat
          value={props.value}
          displayType="text"
          thousandSeparator={' '}
          decimalSeparator={','}
          decimalScale={decimals}
          className={props.className}
          renderText={(value: any) => (
            <Typography variant="body1" className={props.className}>
              {value}
            </Typography>
          )}
        ></CurrencyFormat>
      )}
    </>
  );
}
