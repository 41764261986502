import {
  Box,
  Button,
  Grid,
  Icon,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import PageLayout from '../components/layout/PageLayout';
import MyInvestIcon from '@mui/icons-material/ShowChart';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import { getActiveUserName } from '../redux/stateFuncs';
import { useAppSelector } from '../redux/hooks';

export default function Front() {
  return (
    <PageLayout>
      <FrontWidget />
    </PageLayout>
  );
}

function FrontWidget() {
  const users = useAppSelector((u) => u.users);
  const activeUserName = getActiveUserName(users);
  return (
    <Box marginTop={4}>
      <Grid container spacing={1} gap={1}>
        <Grid
          item
          xs={12}
          sm={12}
          lg={5}
          className="page-info-box white-bg round-corner"
        >
          <Grid container direction="row" style={{ gap: 1, height: '100%' }}>
            <Grid
              item
              xs={12}
              sx={{ marginRight: '16px', marginLeft: '8px', height: '90%' }}
            >
              <Box
                marginTop={0}
                className="light2-bg round-corner"
                sx={{
                  marginRight: '16px',
                  marginBottom: '16px',
                  padding: '1.0rem',
                }}
              >
                <Typography variant="h2">
                  Välkommen till Coeli, {activeUserName}
                </Typography>
                <Typography mb={1} variant="body2">
                  Välkommen till Coelis nya kundportal, version 1.0
                </Typography>
                <Typography>
                  Detta är början på en resa med kontinuerliga förbättringar och
                  ny funktionalitet som löpande kommer släppas. Klicka här nedan
                  om du saknar något från den gamla portalen och dela gärna
                  feedback när du använder den nya. Vår vision är att erbjuda en
                  användarvänlig plattform med bra analys och skräddarsydd
                  kommunikation för att skapa betydande kundvärde. Vi ser fram
                  emot att fortsätta utveckla och förbättra vår service för dig,
                  med sikte på att göra din upplevelse ännu bättre i framtiden.{' '}
                </Typography>
                <List>
                  <ListItem
                    key="myinvestment"
                    disablePadding
                    component="a"
                    href="https://online.coeli.se/#!login/?user-sv"
                    style={{ textDecoration: 'none' }}
                  >
                    <ListItemButton className="side-bar-button">
                      <ListItemText
                        className="side-bar-text"
                        sx={{
                          '& .MuiTypography-root': { fontSize: '0.875rem' },
                          '& .MuiTypography-root:hover': { color: '#fff' },
                        }}
                      >
                        Gamla kundwebben
                      </ListItemText>
                      <Icon className="side-bar-icon">
                        {
                          <MyInvestIcon
                            sx={{
                              color: 'var(--main-color)',
                            }}
                          ></MyInvestIcon>
                        }
                      </Icon>
                    </ListItemButton>
                  </ListItem>
                </List>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          lg={6}
          className="page-info-slider white-bg round-corner"
          ml={1}
        >
          <Box
            sx={{
              display: 'inline-block',
              position: 'relative',
              margin: 'auto',
              top: '85.5%',
              left: '40%',
            }}
          >
            <Button
              variant="outlined"
              endIcon={<ArrowCircleRightOutlinedIcon />}
              className="opp-movie-btn"
              href="https://www.youtube.com/watch?v=RfvH3JE_HDs&t=5s"
              target="_blank"
              rel="noreferrer"
            >
              Se filmen här
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
