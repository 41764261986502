import { Provider } from 'react-redux';
import { store } from './redux/store';
import { ThemeProvider } from '@mui/material/styles';
import theme from './Theme';
import AuthContext from './components/auth/Auth';

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import XHR from 'i18next-http-backend';

import { AuthProvider } from 'react-oidc-context';
import { Auth } from './components/auth/Auth';
import AppLayout from './components/layout/AppLayout';
import { StrictMode, Suspense } from 'react';
import { InFrontProvider } from './hooks/InFront';
import { CookiesProvider } from 'react-cookie';

import { clarity } from 'react-microsoft-clarity';

import CookieBot from 'react-cookiebot';
import FeatureFlagContextProvider from './components/featureFlags/FeatureFlagContextProvider';

const domainGroupId = '8a1fc39a-33e8-4cc3-8023-9fdf0f3020e1';

function App() {
  // console.log(process.env.REACT_APP_AXIOS_BASE_URL);
  // console.log(process.env.REACT_APP_OAUTH_AUTHORITY);
  // console.log(process.env.REACT_APP_OAUTH_REDIRECT_URI);
  // console.log(process.env.REACT_APP_OAUTH_CLIENT_ID);
  // console.log(process.env.NODE_ENV);
  // console.log(process.env);

  // useEffect(() => {
  //   clarity.init('kmoymjj7o4');
  //   clarity.consent();
  //   clarity.setTag('start', '1');
  //   // clarity.start({
  //   //   projectId: 'kmoymjj7o4',
  //   //   upload: 'https://m.clarity.ms/collect',
  //   //   track: true,
  //   //   content: true,
  //   // });
  //   // clarity.set('start', '1');
  // }, []);
  // Init translations
  i18n
    .use(Backend)
    .use(XHR)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      fallbackLng: 'se',
      supportedLngs: ['se', 'en'],
      interpolation: {
        escapeValue: false,
      },
      react: {
        useSuspense: true,
      },
      detection: {
        caches: ['cookie'],
        cookieOptions: { path: '/', sameSite: 'strict', secure: true },
      },
    });

  return (
    <>
      {/* <CookieBot domainGroupId={domainGroupId} /> */}
      <Suspense fallback="Loading... ">
        <AuthProvider {...Auth.oidcConfig}>
          <FeatureFlagContextProvider>
            <ThemeProvider theme={theme}>
              <Provider store={store}>
                <AuthContext>
                  <CookiesProvider
                    defaultSetOptions={{
                      path: '/',
                      secure: true,
                      httpOnly: false,
                    }}
                  >
                    <InFrontProvider>
                      <AppLayout />
                    </InFrontProvider>
                  </CookiesProvider>
                </AuthContext>
              </Provider>
            </ThemeProvider>
          </FeatureFlagContextProvider>
        </AuthProvider>
      </Suspense>
    </>
  );
}

export default App;
