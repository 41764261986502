export enum InvestorCategory {
  NonProfessional = 963440000,
  Professional = 963440001,
  ComparableCounterparty = 963440002,
}

export interface IInvestorProfile {
  id: string | null;
  statuscode: string;
  approverId: string;
  ownerId: string;
  rejectComment: string;
  ownerName: string;

  //Basic Info
  investorCategory: number;
  professionalInvestor: string;
  professionalInvestorAssetUnderManagement: string;

  managementDecisionStructureOwnDecisions: boolean;
  managementDecisionStructureDiscretionary: boolean;
  managementDecisionStructurePresentedInvestmentCase: boolean;

  shareEquity: string;
  shareEquityFunds: string;
  shareInterestFunds: string;
  shareInterestInstruments: string;
  shareHedgeFunds: string;
  sharePrivateEquityFunds: string;
  shareRealEstateFunds: string;
  shareUnlistedEquity: string;
  summarySituationProfessionalInvestors: string;

  //Family Situation and Employment
  employmentEmployed: boolean;
  employmentEntrepreneur: boolean;
  employmentRetired: boolean;
  employmentLiveOfWealth: boolean;
  employmentStudent: boolean;
  employmentUnemployed: boolean;

  familySituation: number;
  children: number;
  noOfMinorsChildrenFinancialResponsibilities: number;
  summaryFamilySituationEmployment: string;
  incomeEmploymentAndPensionMonthAfterTax: number | null;
  annualIncomeFromCapital: number | null;
  monthlyCost: number | null;
  plannedRetirement: number;
  externalAssetsCash: number | null;

  externalAssetsEquity: number | null;
  externalAssetsInterestFunds: number | null;
  externalAssetsBonds: number | null;
  externalAssetsEquityFunds: number | null;
  externalAssetsAlternativInvestments: number | null;
  externalAssetsMixtFundsOrDisk: number | null;
  externalAssetsPrivateEquity: number | null;
  externalAssetsRealEstate: number | null;
  externalAssetsUnlistedEquity: number | null;
  externalAssetsOwnedCompanies: number | null;
  pensionAssets: number | null;
  realEstateValue: number | null;
  realEstateLoans: number | null;
  otherLoans: number | null;
  assessedRiskDiscrepancyClientAdviser: string;
  riskAssessmentDiscrepancyDescription: string;
  expectedChangeAssetsAndLiabilities: string;
  whatAreExpectedChangeInAssetsAndLiabilities: string;
  expectedChangesRevenueAndExpenses: string;
  whatAreExpectedChangesRevenueAndExpenses: string;
  summaryOfIncomeExpensesAndAssets: string;

  investmentObjectives: number;
  investmentGoalsDescribe: string;
  investmentHorizon: number;
  investmentHorizonDescribe: string;
  dependentOnCapitalShortTerm: string;
  annualCapitalDependency1to3y: string;
  annualCapitalDependency3to5y: string;
  dependentOnCapitalLongTerm: string;
  annualcapitaldependence5to10y: string;
  annualcapitaldependencymore10y: string;
  investmentSummaryObjectivesHorizon: string;

  experienceProfession: string;
  professionWhich: string;
  experienceEducation: string;
  educationWhich: string;
  listedEquityTradedPreviously: string;
  listedEquityWhen: number;
  listEdequityInvestmentSize: number;
  listedEquityInvestmentFrequency: number;
  understandingTradingRisksShares: string;
  equityFundsTradedBefore: string;
  equityFundsWhen: number;
  equityFundsInvestmentSize: number;
  equityFundsInvestmentFrequency: number;
  understandingTradingRiskIncomeFund: string;
  mixedFundsBroadDiscretionaryManagementTradedBefore: string;
  mixFundBroadDiscManagWhen: number;
  mixFundBroadBiscManagInvestSize: number;
  mixfundBroaddiscManagInvestFreq: number;
  understandingTradingRiskMFoundBdm: string;
  certificateTradedWithBefore: string;
  certificateWhen: number;
  certificateInvestmentSize: number;
  certificateInvestmentFrequency: number;
  understandRisksTradingCertificate: string;
  etftTadedWithBefore: string;
  etfWhen: number;
  etfInvestmentSize: number;
  etfInvestmentFrequency: number;
  understandRisksTradingEtf: string;
  alternativeFundsHedgeFundsTradedPreviously: string;
  alternativeFundHedgFundWhen: number;
  alternativeFundHedgeFundInvestSize: number;
  alternativeFundHedgeFundInvestFreq: number;
  understandTradingRiskAlterHedgeF: string;
  incomeFundsTradedBefore: string;
  incomeFundsWhen: number;
  incomeFundsInvestmentFrequency: number;
  incomeFundsInvestmentsSize: number;

  interestBearingInstrumentsTradedPreviously: string;
  interestBearingInstrumentsWhen: number;
  interestBearingInstrumentInvestSiz: number;
  interestBearingInstrumentInvFreq: number;
  understanTradeRiskInterestBearing: string;

  coeliPrivateEquityTradedBefore: string;

  coeliRealEstateTradedBefore: string;
  unlistedEquitiesTradedPreviously: number;
  unlistedEquitiesTrainedByAdvisors: string;
  understandingUnlistedEquitiesLoseAllCapital: string;
  understandingUnlistedEquitiesTransferRestrictionsInUnlistedShares: string;
  understandingUnlistedEquitiesPerformanceBasedFeesMayAffectValue: string;
  understandingUnlistedEquitiesUnlistedCompanies: string;
  understandingUnlistedEquitiesUnlistedshares: string;
  understandingUnlistedEquitiesInvestmentSuitableForSale: string;
  assetCustomerDoesNotWantInvested: string;
  understandPrivEquityAdvTrainedMeValue?: number[];
  understandRealestateAdvTrainedMeValue?: number[];
  coeliPrivateEquityWhen: number;
  coeliPrivateEquityInvestmentSize: number;
  coeliPrivateEquityInvestmentFreq: number;
  coeliRealestateTradedBefore: string;
  coeliRealstateWhen: number;
  coeliRealEstateInvestSize: number;
  coelirealestateInvestFrequence: number;
  understandRisksTradCoeliRealestate: string;
  unlistedEquitiesWhen: number;
  unlistedEquitiesInvestmentsSize: number;
  unlistedEquitiesInvestmentFreq: number;
  summaryExperienceAndTraining: string;
  // whatDoesRiskMeanFortheInvestor: number;
  // riskScenarioIndividualYear: number;
  // riskScenario10years: number;
  investRisk: number;
  investRiskAction: number;
  annualTargetReturn: number;
  investmentAlternativetoBankAccount: string;
  investmentWithAllocationStrategy: string;
  investmentPppListedandUnlisted: string;
  summaryRiskAssessment: string;

  assetTypeWhichDontWant: string;
  assetTypeWhichValue?: number[];
  acceptanceCanNegAffectReturnsRisk: string;
  summaryInvestmentPreferences: string;
  specifyWhy: string;

  recommendationAmountInterestFunds: string;
  recommendationAmoListEquityEquityF: string;
  recommendationamoPrivEquityFund: string;
  recommendationAmoAlterinvestHedge: string;
  recommendationAmoRealestateFund: string;
  recommendationAmoListUnlinvesCase: string;
  advisorRecomRiskLevelforCustomer: number;
  summaryofOverallManagementStrategy: string;

  birthday: string;
  jobTitle: string;
  representativeName: string;
  representativeSocialSecurityNumber: string;
  idCopyValidity: string;
  companyName: string;
  contactClassification: string;
  assestDepositOrTransfer: string;
  investmentPurpose: string;
  avarageInvestment: string;
  deposityFrequency: string;
  beneficialOwnerPep: false;
  citizenshipInAnotherCountry: false;
  connectionToUSA: false;
  contactIdOnFile: false;
  familyMemberPEP: false;
  isLivingInAnotherCountry: false;
  otherBeneficialOwner: false;
  pep: false;
  relationshipToCustomer: string;
  representativeId: false;
  taxREsidencyOtherThanSweeden: false;
  doYouHaveaSustainabilityPreference?: boolean;

  interestSustainableInvestments?: number;
  greenhouseGasesEmission?: number;
  carbonFootprint?: number;
  greenhouseGasEmissionsCompany?: number;
  fossilFuel?: number;
  nonRenewableEnergy?: number;
  energyConsumption?: number;
  releaseIntoWater?: number;
  hazardousWaste?: number;
  violationGlobalAgreement?: number;
  deficiencyGlobalAgreement?: number;
  controversialWeapons?: number;
  genderPayGap?: number;
  genderDistribution?: number;
  interestProducts?: number;

  allocationsAssetsHighLiquidityDec?: number;
  allocationsAssetsLimitedLiquidityDec?: number;
  allowedDriftOnOverallRecommendedAllocation?: number;
}
