import PageLayout from '../components/layout/PageLayout';
import SignInCallback from '../components/auth/SignInCallback';

export default function SignIn() {
  return (
    <PageLayout>
      <SignInCallback></SignInCallback>
    </PageLayout>
  );
}
