import {
  Box,
  CircularProgress,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {
  DataGridPremium as DataGrid,
  GridColDef,
  GridColumnHeaderParams,
  GridRenderCellParams,
  GridValueFormatterParams,
} from '@mui/x-data-grid-premium';
import { useEffect, useMemo, useState } from 'react';
import { FaTransactionDto } from '../../models/dto/faTransactionDto';
import { useAppSelector } from '../../redux/hooks';
import { useApiClient } from '../../hooks/apiClient';
import CurrencyField from '../formfields/CurrencyField';
import NumberField from '../formfields/NumberField';
import { format } from 'date-fns';
import { getTransactionType } from '../../util/translations';
import { useTranslation } from 'react-i18next';

interface IPosition extends FaTransactionDto {
  key: string;
}
export default function Transactions() {
  const matches560 = useMediaQuery('(min-width:560px)');
  const matches660 = useMediaQuery('(min-width:660px)');

  const { t } = useTranslation();

  const columnsDesktop = useMemo<GridColDef<IPosition>[]>(
    () => [
      {
        field: 'transactionDate',
        headerName: t('Date'),
        type: 'date',
        flex: 0.25,
        minWidth: 100,
        valueFormatter: (params: GridValueFormatterParams<Date>) => {
          return format(new Date(params.value), 'yyyy-MM-dd');
        },
      },
      {
        field: 'settlementDate',
        headerName: t('SettlementDate'),
        type: 'date',
        flex: 0.25,
        minWidth: 100,
        valueFormatter: (params: GridValueFormatterParams<Date>) => {
          return format(new Date(params.value), 'yyyy-MM-dd');
        },
      },
      {
        field: 'type',
        headerName: t('tradeOrders.Type'),
        headerAlign: 'left',
        renderCell: (params: GridRenderCellParams<IPosition, string>) => (
          <>{getTransactionType(params.value!)}</>
        ),
        align: 'left',
        flex: 0.25,
        minWidth: 50,
      },
      {
        field: 'securityName',
        headerName: t('Security'),
        headerAlign: 'left',
        renderCell: (params: GridRenderCellParams<IPosition, string>) => (
          <>{params.value}</>
        ),
        align: 'left',
        flex: 1.0,
        minWidth: 100,
      },
      {
        field: 'amount',
        headerName: t('tradeOrders.Amount'),
        renderCell: (params: GridRenderCellParams<IPosition, number>) =>
          params.row.type == 'Deposit' ? (
            <></>
          ) : (
            <NumberField value={params.value}></NumberField>
          ),
        align: 'right',
        headerAlign: 'right',
        flex: 0.6,
        minWidth: 56,
      },
      {
        field: 'tradeAmount',
        headerName: t('Sum'),
        renderCell: (params: GridRenderCellParams<IPosition, number>) => (
          <CurrencyField
            value={params.value}
            suffix={params.row.currencyCode}
          ></CurrencyField>
        ),
        align: 'right',
        headerAlign: 'right',
        flex: 0.75,
        minWidth: 145,
      },
    ],
    [t]
  );

  const columnsMobile = useMemo<GridColDef<IPosition>[]>(
    () => [
      {
        field: 'transactionDate',
        headerName: 'Datum',
        flex: 0.2,
        minWidth: 90,
        sortable: false,
        renderHeader: () => (
          <Box>
            <Typography className="small-dg-header">{t('Date')}</Typography>
            <Typography className="small-dg-header italic">
              {t('SettlementDate')}
            </Typography>
          </Box>
        ),
        renderCell: (params: GridRenderCellParams<IPosition, string>) => (
          <div>
            <Typography className="small-dg-text">
              {format(new Date(params.row.transactionDate), 'yyyy-MM-dd')}
            </Typography>
            <Typography className="small-dg-text italic">
              {format(new Date(params.row.settlementDate), 'yyyy-MM-dd')}
            </Typography>
          </div>
        ),
        align: 'left',
      },
      {
        field: 'type',
        headerName: 'Typ',
        headerAlign: 'left',
        sortable: false,
        renderHeader: () => (
          <Box>
            <Typography className="small-dg-header">{t('Security')}</Typography>
            <Typography className="small-dg-header italic">
              {t('tradeOrders.Type')}
            </Typography>
          </Box>
        ),
        renderCell: (params: GridRenderCellParams<IPosition, string>) => (
          <div>
            <Typography className="small-dg-text">
              {params.row.securityName}
            </Typography>
            <Typography className="small-dg-text italic">
              {getTransactionType(params.row.type)}
            </Typography>
          </div>
        ),
        align: 'left',
        flex: 0.4,
        minWidth: 100,
      },
      {
        field: 'tradeAmount',
        headerName: 'Summa',
        renderHeader: () => (
          <Box>
            <Typography className="small-dg-header" textAlign={'right'}>
              {t('Sum')}
            </Typography>
            <Typography className="small-dg-header italic" textAlign={'right'}>
              {t('tradeOrders.Amount')}
            </Typography>
          </Box>
        ),
        renderCell: (params: GridRenderCellParams<IPosition, number>) => (
          <Box textAlign={'right'}>
            <CurrencyField
              value={params.row.tradeAmount}
              suffix={params.row.currencyCode}
              className="small-dg-text2"
            ></CurrencyField>
            {params.row.type == 'Deposit' ? (
              <Typography className="small-dg-text2" textAlign={'right'}>
                {' - '}
              </Typography>
            ) : (
              <NumberField
                className="small-dg-text2 italic"
                value={params.row.amount}
              ></NumberField>
            )}
          </Box>
        ),
        align: 'right',
        headerAlign: 'right',
        flex: 0.4,
        minWidth: 125,
      },
    ],
    [t]
  );

  const [isLoading, setIsLoading] = useState<boolean>();
  const [error, setError] = useState<string | null>(null);
  const [portfolioPositions, setPortfolioPositions] = useState<
    FaTransactionDto[]
  >([]);

  const [data, setData] = useState<IPosition[]>([]);
  const portfolios = useAppSelector((state) => state.portfolios);

  const api = useApiClient();

  useEffect(() => {
    setIsLoading(true);
    const postData = {
      portfolioIds: portfolios.filter((p) => p.show === true).map((p) => p.id),
      fromDate: '2023-01-01',
      toDate: format(new Date(), 'yyyy-MM-dd'),
    };
    api
      .post<FaTransactionDto[]>(
        'api/FaSecurity/GetPortfolioTransactions',
        postData
      )
      .then((res) => {
        portfolioPositions.splice(0);
        res.data.map((i) => {
          portfolioPositions.push(i);
          return null;
        });
        setError(null);
        setData(
          portfolioPositions
            .filter((e) =>
              portfolios
                .filter((p) => p.show === true)
                .map((p) => p.id)
                .includes(e.portfolioId)
            )
            .map((p, index) => ({
              ...p,
              key: index.toString() + p.portfolioId,
            })).sort((t2,t1) => {
              if(t1.transactionDate > t2.transactionDate)
                return 1;
              else if(t1.transactionDate < t2.transactionDate)
                return -1;
              else
                return 0;
            })
        );
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setError('Error loading portfolio positions');
      });
  }, []);

  useEffect(() => {
    setData(
      portfolioPositions
        .filter((e) =>
          portfolios
            .filter((p) => p.show === true)
            .map((p) => p.id)
            .includes(e.portfolioId)
        )
        .map((p, index) => ({ ...p, key: index.toString() + p.portfolioId }))
    );
  }, [portfolios]);

  return (
    <>
      {error ? (
        <>
          <Box display="flex" justifyContent="center" my={6}>
            <Typography color="error.main">{error}</Typography>
          </Box>
        </>
      ) : (
        <>
          {isLoading ? (
            <Box display="flex" justifyContent="center" my={6}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Box style={{ minHeight: 128, width: '98%' }}>
                <Box display={'flex'} height="100%">
                  <Box flex={1} sx={{ display: { xs: 'none', sm: 'block' } }}>
                    <DataGrid
                      disableRowSelectionOnClick
                      columnVisibilityModel={{
                        amount: matches560,
                        settlementDate: matches660,
                      }}
                      rows={data}
                      columns={columnsDesktop}
                      rowHeight={25}
                      autoPageSize={true}
                      pageSizeOptions={[25, 50]}
                      getRowId={(row) => row.key}
                    />

                  </Box>
                  <Box flex={1} sx={{ display: { xs: 'block', sm: 'none' } }}>
                    <DataGrid
                      autoHeight
                      disableRowSelectionOnClick
                      disableColumnMenu
                      rows={data}
                      columns={columnsMobile}
                      hideFooterRowCount
                      getRowId={(row) => row.key}
                    />
                  </Box>
                </Box>
              </Box>
            </>
          )}
        </>
      )}
    </>
  );
}
