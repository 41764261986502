import { Box, Grid } from '@mui/material';
import React from 'react';
import { Outlet } from 'react-router-dom';

interface Props {
  children: React.ReactNode;
}

const PageLayout: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <Box className="page-content white-bg">
      <Grid container>
        <Grid item>{props.children}</Grid>
      </Grid>
      <Outlet />
    </Box>
  );
};

export default PageLayout;
