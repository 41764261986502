import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ToDoEnum } from '../../Enums';
import { IToDo } from '../../models/todo';

const initialState: IToDo[] = [];

export const todosSlice = createSlice({
  name: 'todos',
  initialState,
  reducers: {
    removeTodos: (state, action: PayloadAction<IToDo[]>) => {
      action.payload.forEach((e) => {
        const todoIdx = state.findIndex((todo) => todo.id === e.id);
        if (todoIdx >= 0) {
          state.splice(todoIdx, 1);
        }
      });
    },
    setTodos: (state, action: PayloadAction<IToDo[]>) => {
      state.splice(0);
      action.payload.forEach((e) => state.push(e));
    },
    clearTodos: (state) => {
      state.splice(0);
    },
    setToDoComplete: (state, action: PayloadAction<ToDoEnum>) => {
      const todo: IToDo | undefined = state.find(
        (todo) => todo.type === action.payload
      );
      if (todo) {
        todo.complete = true;
      }
    },
  },
});

export const { removeTodos, setTodos, clearTodos, setToDoComplete } =
  todosSlice.actions;
export default todosSlice.reducer;
