import Risk from '../../components/widgets/Risk';
import InvestmentLayout from '../../components/layout/InvestmentLayout';

export default function AllocationsPage() {
  return (
    <>
      <InvestmentLayout hidePortfolioPicker={true}>
        <Risk />
      </InvestmentLayout>
    </>
  );
}
