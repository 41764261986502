export enum OpportunityState {
  Open = 0,
  Won = 1,
  Lost = 2,
}

export enum Opportunity_statuscode {
  InProgress = 1,
  OnHold = 2,
  Won = 3,
  UnderRevision = 963440000,
  Canceled = 4,
  OutSold = 5,
}

export enum TradeOrderBaseTypeEnum {
  Stock = 963440000,
  Fund = 963440001,
  Bond = 963440002,
}

export enum TradeOrderOperationEnum {
  BuyUnitsB = 963440002,
  BuyForCashBFC = 963440001,
  SellUnitsS = 963440003,
  SellForCashSFC = 963440000,
}

export const enum PortfolioAgreementEnum {
  Advisory = 963440000,
  DiscretionarySafeRisk = 963440005,
  DiscretionaryTotal = 963440006,
  DiscretionaryOffensiv = 963440007,
  DiscretionaryFokus = 963440010,
  DiscretionaryGlobalOpportunities = 963440004,
  DiscretionarySwedishStock = 963440002,
  DiscretionaryOffensivPlus = 963440009,
  DiscretionaryCoeliLegacy = 963440003,
  DiscretionaryIndivid = 963440001,
  NoAgreement = 963440008,
}

export const enum ToDoEnum {
  Kyc = 0,
  KycCompany = 1,
  InvestorProfile = 2,
  InvestorProfileCompany = 3,
  PortfolioAgreement = 4,
  TradeOrders = 5,
  OtherDocuments = 6,
  NewPortfolioAgreement = 7,
}

/* Investment profile Enums */
export const enum investorProfilecompaniestatusCode {
  Draft = 1,
  Submitted = 963440000,
  Confirmed = 2,
  Archived = 963440001,
  Rejected = 963440002,
  ClientRevision = 963440003,
}

export const enum investorCategory {
  NonProfessional = 963440000,
  Professional = 963440001,
  ComparableCounterparty = 963440002,
  SimplifiedInvestorProfile = 963440003,
}

export const enum consequenscesFactor {
  NoPreference = 963440000,
  ActiveInfluence = 963440001,
  Exclude = 963440002,
  SimplifiedInvestorProfile = 963440003,
}

export const enum InvestmentHorizon {
  _1_3_Years = 963440000,
  _4_6_Years = 963440001,
  _7_9_Years = 963440002,
  MoreThan10 = 963440003,
  UntilFurtherNotice = 963440004,
  Other = 963440005,
}

export const enum managementDecisionStructure {
  OwnDecisions = 963440000,
  Discretionary = 963440001,
  PresentedInvestmentCaseOwnDecisions = 963440002,
  SpecificInvestmentGoals = 963440003,
}

export const enum investmentObjectivesDescription {
  NextGenerations = 963440000,
  SecureOwnPension = 963440001,
  ReturnOnCapital = 963440002,
  SpecificInvestmentGoals = 963440003,
}

export const enum witchdoesntwant {
  ListedEquties = 963440000,
  EquityFunds = 963440001,
  HedgeFunds = 963440002,
  InterestFunds = 963440003,
  PrivateEquityFunds = 963440004,
  RealEstateFunds = 963440005,
  UnlistedEquities = 963440006,
}

// export const enum UnlistedEquitiesTradedWithpreviously {
//   Yes = 963440000,
//   YesLong = 963440001,
//   NoInterested = 963440002,
//   NoNotReleveant = 963440003,
// }

export enum UnlistedEquitiesTradedPreviouslyOptions {
  Yes = 963440000,
  YesOwnedOrPartlyOwnedCompanies = 963440001,
  NoInterested = 963440002,
  NoNotRelevant = 963440003,
}

export const enum understandingCoeliPrivateEquityAdvisorHasTrainedMeTo {
  ThatInvestmentsAreAadeIndirectlyInANumberOfUnlistedShares = 963440000,
  ThatTheShareMayHaveALimitedLiquidityBasedOnTurnover = 963440001,
  TheShareIsSuitableForTheLongTermInvestor = 963440002,
  TheInvestmentIsNotRelevantToMe = 963440003,
  ThereIsAConflictOfInterest = 963440004,
}

export const enum understandingCoeliRealEstateAdvisorHasTrainedMeTo {
  ThatInvestmentsAreAadeIndirectlyInANumberOfProperties = 963440000,
  ThatTheShareMayHaveALimitedLiquidityBasedOnTurnover = 963440001,
  TheShareIsSuitableForTheLongTermInvestor = 963440002,
  TheInvestmentIsNotRelevantToMe = 963440003,
  ThereIsAConflictOfInterest = 963440004,
}

export const enum investmentPrefrencesPercentages {
  Number_10 = 963440010,
  Number_20 = 963440020,
  Number_30 = 963440030,
  Number_40 = 963440040,
  Number_50 = 963440050,
  Number_60 = 963440060,
  Number_70 = 963440070,
  Number_80 = 963440080,
  Number_90 = 963440090,
  Number_100 = 963440100,
  NoPreferences = 963440000,
}

/* UI Enums */
export const enum InfoTableWidgetType {
  Percentage = 1,
  Currecny = 2,
  Number = 3,
  Text = 4,
}

export const enum AllocationType {
  Group = 1,
  Country = 2,
  Sector = 3,
  Husvy = 4,
  Risk = 5,
}

export enum TimePeriodType {
  All = 1,
  YTD = 2,
  Month = 3,
  Month_3 = 4,
  Year = 5,
  Year_2 = 6,
  Year_5 = 7,
}

export enum SecurityType {
  Unknown = 'UNKNOWN',
  Autocall = 'AUTOCALL',
  Bond = 'BOND',
  Stock = 'STOCK',
  Fund = 'FUND',
}

export enum DocumentCategoryType {
  QuarterlyReport = 1,
  AnnualReport = 2,
  Exante = 3,
  DeclarationDocuments = 4,
  Other = 5,
  Signed = 6,
  SecurityInformation = 7,
}
