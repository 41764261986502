import { createTheme } from '@mui/material/styles';
import type {} from '@mui/x-data-grid-premium/themeAugmentation';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    desktop450: true;
    sm: true;
    desktop675: true;
    md: true;
    lg: true;
    xl: true;
    desktop1300: true;
  }
}

const theme = createTheme({
  spacing: 8,
  breakpoints: {
    values: {
      xs: 0,
      desktop450: 450,
      sm: 600,
      desktop675: 675,
      md: 900,
      lg: 1200,
      desktop1300: 1300,
      xl: 1536,
    },
  },
  typography: {
    fontFamily: 'Gotham',
    h1: {
      fontSize: '2rem',
      color: '#c26e3d',
      fontWeight: 400,
    },
    h2: {
      fontSize: '1.75rem',
      color: '#5d4c37b0',
      fontWeight: 'bold',
    },
    h3: {
      fontSize: '1.15rem',
      color: '#5d4c37b0',
      fontWeight: 'bold',
    },
    h4: {
      fontSize: '1rem',
      color: '#c26e3d',
      fontWeight: 'bold',
    },
    h5: {
      fontSize: '1rem',
      color: '#000',
      fontWeight: 400,
    },
    body1: {
      fontSize: '0.825rem',
      color: '#c26e3d',
    },
    body2: {
      fontSize: '0.825rem',
      color: '#000',
    },
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#c26e3d',
      contrastText: '#fff',
    },
    secondary: {
      main: '#5E412F',
      contrastText: '#fff',
    },
    error: {
      main: '#ff2222',
      contrastText: '#ff0000',
    },
  },
  components: {
    MuiAvatar: {
      styleOverrides: {
        colorDefault: {
          backgroundColor: '#fff',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          main: '#c26e3d',
          backgroundColor: '#fff',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        dense: {
          height: 52,
          minHeight: 52,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          borderRadius: 16,
        },
        outlined: {
          borderRadius: 16,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: '0.75rem',
          padding: '8px 12px',
          minHeight: '32px',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: '32px',
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        aggregationColumnHeaderLabel: {
          display: 'none',
        },
        root: {
          fontSize: '0.75rem',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#f7ede7',
          },
        },
        selected: {},
      },
    },
    // MuiBottomNavigationAction: {
    //   styleOverrides: {
    //     root: {
    //       "&.Mui-selected": {
    //         color: 'red',
    //         fontSize: '0.75rem !important',
    //         "&.MuiBottomNavigationAction-label": {
    //           fontSize: '0.7r5em !important',
    //         }
    //       }
    //     }
    //   }
    // }
  },
});

export default theme;
