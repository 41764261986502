import { configureStore } from '@reduxjs/toolkit';
import usersReducer from './slices/users';
import todosReducer from './slices/todo';
import navigationReducer from './slices/navigation';
import portfoliosReducer from './slices/portfolios';
import customerReducer from './slices/customer';

export const store = configureStore({
  reducer: {
    users: usersReducer,
    todos: todosReducer,
    navigation: navigationReducer,
    portfolios: portfoliosReducer,
    customers: customerReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
