import { Box, Typography, Grid, CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../redux/hooks';
import {
  FaCustomerProgressDto,
  FaProgressDto,
} from '../../models/dto/faProgressDto';
import { useApiClient } from '../../hooks/apiClient';
import { getActiveUser } from '../../redux/stateFuncs';
import { useTranslation } from 'react-i18next';
import PercentageField from '../formfields/PercentageField';
import { TimePeriodType } from '../../Enums';
import CurrencyField from '../formfields/CurrencyField';

function ProgressBox(title: string, value?: number) {
  return (
    <Grid item xs={6} desktop1300={3}>
      <Grid container justifyContent="flex-end">
        <Grid item xs={12} textAlign={'end'}>
          <Typography className="title_text">{title}</Typography>
        </Grid>
        <Grid item xs={12} textAlign={'end'}>
          <PercentageField
            decimals={2}
            value={value}
            className="trend_text"
          ></PercentageField>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default function InvestmentProgress() {
  const [isLoading, setIsLoading] = useState<boolean>();
  const [error, setError] = useState<string | null>(null);
  const portfolios = useAppSelector((state) => state.portfolios);
  const [progressData, setProgressData] = useState<FaCustomerProgressDto>();

  const api = useApiClient();
  const users = useAppSelector((state) => state.users);
  var activeUser = getActiveUser(users);
  const { t } = useTranslation();

  useEffect(() => {
    setIsLoading(true);
    api
      .get<FaCustomerProgressDto>(
        'api/FaSecurity/GetCustomerProgress/' + activeUser?.socialSecurityNumber
      )
      .then((res) => {
        if (res.data.progressItems.length > 0) setProgressData(res.data);
        setIsLoading(false);
        setError(null);
      })
      .catch((err) => {
        console.error(err);
        setError('Error loading customer progress');
      });
  }, []);

  return (
    <Box className="investment-progress round-corner">
      {error ? (
        <>
          <Box display="flex" justifyContent="center" my={6}>
            <Typography color="error.main">{error}</Typography>
          </Box>
        </>
      ) : (
        <>
          {isLoading ? (
            <Box display="flex" justifyContent="center" my={6}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Grid container>
                <Grid
                  item
                  container
                  xs={5}
                  justifyContent="flex-end"
                  direction="column"
                  alignItems="flex-start"
                >
                  <Box>
                    <Typography className="title_text">
                      {t('ProgressThisYear')}
                    </Typography>
                    <Typography className="title_text italic">
                      {t('Marketvalue')}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={7} display="flex" justifyContent="flex-end">
                  <Box textAlign={'end'}>
                    <PercentageField
                      decimals={2}
                      value={
                        progressData?.progressItems.filter(
                          (f) => f.timePeriod == TimePeriodType.YTD
                        )[0].relativeProgress
                      }
                      className="total_text"
                    ></PercentageField>
                    <CurrencyField
                      decimals={0}
                      value={progressData?.marketValue}
                      className="marketvalue_text italic"
                    ></CurrencyField>
                  </Box>
                </Grid>
                <Grid item xs={12} className="break"></Grid>
                <Grid item xs={12}>
                  <Grid container>
                    {ProgressBox(
                      '1 ' + t('MonthShort').toLocaleLowerCase(),
                      progressData?.progressItems.filter(
                        (f) => f.timePeriod == TimePeriodType.Month
                      )[0].relativeProgress
                    )}
                    {ProgressBox(
                      '12 ' + t('MonthShort').toLocaleLowerCase(),
                      progressData?.progressItems.filter(
                        (f) => f.timePeriod == TimePeriodType.Year
                      )[0].relativeProgress
                    )}
                    {ProgressBox(
                      '24 ' + t('MonthShort').toLocaleLowerCase(),
                      progressData?.progressItems.filter(
                        (f) => f.timePeriod == TimePeriodType.Year_2
                      )[0].relativeProgress
                    )}
                    {ProgressBox(
                      '5 ' + t('Years').toLocaleLowerCase(),
                      progressData?.progressItems.filter(
                        (f) => f.timePeriod == TimePeriodType.Year_5
                      )[0].relativeProgress
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </>
      )}
    </Box>
  );
}
