import { Typography } from '@mui/material';
import CurrencyFormat from '../../util/currency-format/currency-format';

interface PercentageFieldProps {
  value?: number;
  decimals?: number;
  className?: string;
}

export default function PercentageField(props: PercentageFieldProps) {
  const { decimals = 2 } = props;

  var separator = ',';
  if (decimals == 0) separator = '';

  return (
    <>
      {props.value == null ? (
        ''
      ) : (
        <CurrencyFormat
          value={props.value}
          displayType="text"
          thousandSeparator={' '}
          decimalSeparator={separator}
          decimalScale={decimals}
          fixedDecimalScale={true}
          suffix={' %'}
          className={props.className}
          renderText={(value: any) => (
            <Typography variant="body1" className={props.className}>
              {value}
            </Typography>
          )}
        ></CurrencyFormat>
      )}
    </>
  );
}
