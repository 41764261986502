export enum coeli_advisorrecomrisklevelforcustomer {
  Low = 963440000,
  MediumLow = 963440001,
  Medium = 963440002,
  MediumHigh = 963440003,
  High = 963440004,
  BankAccount = 963440005,
  Speculations = 963440006,
}

export interface RiskRecommendationsDto {
  recommendedRiskLevel?: coeli_advisorrecomrisklevelforcustomer;
  recommendedAllocHighLiquidity?: number;
}
