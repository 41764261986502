import './wdyr'; // <--- first import

import ReactDOM from 'react-dom/client';
import App from './App';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { pages, routes, authRoutes } from './routes';

import './fonts/Gotham-Book.otf';
import './fonts/Gotham-Medium.otf';

import './styles/coeli_portal.css';
import SignIn from './pages/SignIn';
import { StrictMode } from 'react';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <StrictMode>
    <BrowserRouter>
      <Routes>
        {/* Page routes with children */}
        <Route path="/" element={<App />}>
          {pages.map((page, index) => (
            <Route path={page.nav} element={page.element()} key={page.nav}>
              {page.routes.map((route, index) => (
                <Route
                  path={route.nav}
                  element={route.element()}
                  key={page.nav}
                ></Route>
              ))}
            </Route>
          ))}

          {/* Other routes */}
          {routes.map((page, index) => (
            <Route
              path={page.nav}
              element={page.element()}
              key={page.nav}
            ></Route>
          ))}
          {/* Auth routes */}
          {authRoutes.map((page, index) => (
            <Route
              path={page.nav}
              element={page.element()}
              key={page.nav}
            ></Route>
          ))}

          <Route path="/" element={<SignIn />} />
          <Route path="*" element={<SignIn />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </StrictMode>
);
