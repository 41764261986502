import { User } from 'oidc-client-ts';
import { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { useApiClient } from '../../hooks/apiClient';
import Login from './Login';
import { addUsers } from '../../redux/slices/users';
import { IUser } from '../../models/user';
import AuthInfoWrapper, { AuthInfoType } from './AuthInfoWrapper';

export const Auth = {
  oidcConfig: {
    authority:
      process.env.REACT_APP_OAUTH_AUTHORITY ||
      (() => {
        console.error('Missing REACT_APP_OAUTH_AUTHORITY in .env.');
        return '';
      })(),
    redirect_uri:
      process.env.REACT_APP_OAUTH_REDIRECT_URI ||
      (() => {
        console.error('Missing REACT_APP_OAUTH_REDIRECT_URI in .env.');
        return '';
      })(),
    client_id:
      process.env.REACT_APP_OAUTH_CLIENT_ID ||
      (() => {
        console.error('Missing REACT_APP_OAUTH_CLIENT_ID in .env.');
        return '';
      })(),
    scope: 'openid profile personalidentitynumber api.scope',
    requireHttpsMetadata: true,
    onSigninCallback: (_user: User | void): void => {
      if (_user != null) {
        sessionStorage.setItem('coeli_token', _user.access_token);
      } else {
        sessionStorage.removeItem('coeli_token');
      }

      // Strip auth-code form URL
      window.history.replaceState({}, document.title, window.location.pathname);
    },
    onSilentRenewErrorCallback: (error: Error): Promise<void> | void => {},
  },
};

interface Props {
  children: React.ReactNode;
}

const AuthContext: React.FunctionComponent<Props> = (props: Props) => {
  const auth = useAuth();
  var api = useApiClient();
  const users = useAppSelector((state) => state.users);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (users.length == 0 && auth.isAuthenticated) {
      api
        .get('api/User/GetUsersAndCompanies')
        .then((res) => {
          var readUsers = res.data.users as IUser[];
          // Only add once...
          console.log('USERS : ');
          console.log(users);
          if (readUsers.length > 0 && users.length == 0) {
            dispatch(addUsers(readUsers));
          } else {
            Logout();
          }
        })
        .catch((error) => {
          Logout();
        });
    }
  }, [users, auth]);

  function Logout() {
    sessionStorage.removeItem('coeli_token');
    auth.signoutRedirect({ id_token_hint: auth.user?.id_token });
    auth.removeUser();
  }

  switch (auth.activeNavigator) {
    case 'signinSilent':
      return <AuthInfoWrapper type={AuthInfoType.SignIn} />;
    case 'signoutRedirect':
      return <AuthInfoWrapper type={AuthInfoType.SignOut} />;
  }

  if (auth.error) {
    // TODO: if 'login_required' try silent renewal
    return <Login></Login>;
  }

  if (auth.isLoading) {
    return <AuthInfoWrapper type={AuthInfoType.Loading} />;
  }

  if (auth.isAuthenticated && sessionStorage.getItem('coeli_token') != null) {
    return <>{props.children}</>;
  }

  auth.clearStaleState();

  return <Login></Login>;
};

export default AuthContext;
