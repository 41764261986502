import i18n from 'i18next';
import { TradeOrderOperationEnum } from '../Enums';

export function translateCRMString(value: string): string {
  const t = i18n.t;

  switch (value) {
    /* Document types */
    case 'Kyc':
      return t('kyc.Kyc');
    case 'Investor Profile':
      return t('InvestorProfile');
    case 'Other Document':
      return t('OtherDocument');
    case 'Trade Order':
      return t('TradeOrder');
    case 'Portfolio Agreement':
      return t('PortfolioAgreement');
    case 'New Portfolio Agreement':
      return t('NewPortfolioAgreement');

    /* Status */
    case 'Approved':
      return t('Approved');
    case 'Rejected':
      return t('Rejected');
  }
  return value;
}

export function getOrderTypeName(value: number): string {
  const t = i18n.t;

  switch (value) {
    /* Order types */
    case TradeOrderOperationEnum.BuyForCashBFC:
      return t('tradeOrders.BuyForCashBFC');
    case TradeOrderOperationEnum.SellForCashSFC:
      return t('tradeOrders.SellForCashSFC');
    case TradeOrderOperationEnum.BuyUnitsB:
      return t('tradeOrders.BuyUnitsB');
    case TradeOrderOperationEnum.SellUnitsS:
      return t('tradeOrders.SellUnitsS');
  }
  return '';
}

export function getTransactionType(value: string): string {
  const t = i18n.t;

  switch (value) {
    case 'Buy':
      return t('transactions.Buy');
    case 'Buy for Cash':
      return t('transactions.Buy');
    case 'Sell':
      return t('transactions.Sell');
    case 'Sell for Cash':
      return t('transactions.Sell');
    case 'Deposit':
      return t('transactions.Deposit');
    case 'Dividend':
        return t('transactions.Dividend');
    default:
      return value;
  }
  return '';
}
