import { Box } from '@mui/material';
import InvestmentLayout from '../../components/layout/InvestmentLayout';
import ProgressGraphHC from '../../components/widgets/ProgressGraphHC';

export default function Progress() {
  return (
    <>
      <InvestmentLayout>
        {/* "SE0016828511", "LU1133292463", */}
        {/* <GraphWidget symbols={['LU1133292463']}></GraphWidget> */}
        <Box className="widget">
          <ProgressGraphHC totalView={false} navBar={true} />
        </Box>
        {/* <ProgressGraph /> */}
      </InvestmentLayout>
    </>
  );
}
