import React from 'react';
import * as Icon from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { AxiosInstance } from 'axios';
import { securityConfig } from '../config';
import { withTranslation, WithTranslation } from 'react-i18next';
import { SecurityType } from '../Enums';
import { FaFilteredSecurityDto } from '../models/dto/faSecurityDto';
import { getSecurityDetailLink } from '../util/common';

export enum SecurityDetailsSize
{
  Small,
  Medium,
  Large
}

export interface ISecurityDetailsProps extends WithTranslation {
  isinCode: string;
  api: AxiosInstance;
  size: SecurityDetailsSize;
}

export interface ISecurityDetailsState {
  url1?: string;
  url2?: string;
  allocation?: any;
  securityType?: string;
  detailsUrl?: string;
}

class SecurityDetails extends React.Component<
  ISecurityDetailsProps,
  ISecurityDetailsState
> {
  constructor(props: ISecurityDetailsProps) {
    super(props);
    this.state = {
      detailsUrl: '',
      url1: '',
      url2: '',
    };
  }

  public componentDidMount = (): void => {
    this.props.api
      .get<FaFilteredSecurityDto>(
        `/api/fasecurity/GetSecurityDetails/${this.props.isinCode}`
      )
      .then(
        (res) => {
          let data = res.data;

          this.setState({
            url1: data.url,
            url2: data.url2,
            detailsUrl: getSecurityDetailLink(data),
          });
        },
        (err) => {}
      );
  };

  render(): JSX.Element {
    return (
      <>
        <IconButton
          aria-label={this.props.t('tradeOrders.Details')}
          title={this.props.t('tradeOrders.Details')}
          href={this.state.detailsUrl!}
          className={'securityDetails-' + SecurityDetailsSize[this.props.size]}
          target="_blank"
        >
          <Icon.InsertChart />
        </IconButton>
        <IconButton
          color="secondary"
          aria-label={this.props.t('tradeOrders.BaseFacts')}
          title={this.props.t('tradeOrders.BaseFacts')}
          href={this.state.url1!}
          className={'securityDetails-' + SecurityDetailsSize[this.props.size]}
          target="_blank"
        >
          <Icon.FindInPage />
        </IconButton>
        <IconButton
          color="primary"
          aria-label={this.props.t('tradeOrders.Sustainability')}
          title={this.props.t('tradeOrders.Sustainability')}
          href={this.state.url2!}
          className={'securityDetails-' + SecurityDetailsSize[this.props.size]}
          target="_blank"
        >
          <Icon.Article />
        </IconButton>
      </>
    );
  }
}

export default withTranslation()(SecurityDetails);
