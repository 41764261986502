import React from "react";
import { FieldProps, getIn } from "formik";
import { TextField } from "@mui/material";

export const TextFormField: React.FC<FieldProps> = ({
    field,
    form,
    ...props
}) => {
    const errorText: string = getIn(form.touched, field.name) && getIn(form.errors, field.name);
    return (
        <TextField
            fullWidth
            helperText={errorText}
            error={!!errorText}
            {...field}
            {...props}
        />
    );
};