import Allocations from '../../components/widgets/Allocations';
import InvestmentLayout from '../../components/layout/InvestmentLayout';

export default function AllocationsPage() {
  return (
    <>
      <InvestmentLayout>
        <Allocations />
      </InvestmentLayout>
    </>
  );
}
