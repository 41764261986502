import React, { useEffect, useState } from 'react';

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Box,
  CircularProgress,
  TextField,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormGroup,
} from '@mui/material';
import {
  DataGridPremium as DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
} from '@mui/x-data-grid-premium';

import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

import { useTranslation } from 'react-i18next';
import { useApiClient } from '../../hooks/apiClient';
import { getActiveUser } from '../../redux/stateFuncs';
import { useAppSelector } from '../../redux/hooks';
import format from 'date-fns/format';
import { Common } from '../../util/common';
import { getOrderTypeName, translateCRMString } from '../../util/translations';
import CurrencyField from '../formfields/CurrencyField';
import NumberField from '../formfields/NumberField';

let myrows = [] as ICustomerApproval[];

interface ITradeorder {
  coeli_tradeamount: number;
  coeli_amount: number;
  coeli_basictype: string;
  coeli_name: string;
  coeli_securitycontrol: string;
  coeli_settlementdate: string;
  coeli_tradeorderdate: string;
  coeli_tradeorderid: string;
  coeli_tradeordertype: number;
  coeli_transactionfee: number;
  coeli_unitprice: number;
  coeli_tradeorderrequestid: string;
}

type ApprovalHistoryDetailsDialogProps = {
  approvalItemId: string;
};

interface IKycDto {
  coeli_kycid: string;
  coeli_surname: string;
  coeli_firstname: string;
  coeli_socialsecuritynumber: string;
  coeli_streetaddress: string;
  coeli_zipcode: string;
  coeli_city: string;
  coeli_country: string;
  coeli_isrepresentative: string;
  coeli_representativesocialsecuritynumber: string;
  coeli_relationshiptocustomertext: string;
  coeli_representativeidentificator: string;
  coeli_beneficialownerpep: string;
  coeli_businessrelationshipforwhom: string;
  coeli_ispep: string;
  coeli_realmandatorpepname: string;
  coeli_realmandatorpepsocialsecuritynumber: string;
  coeli_taxdomicileoraffiliation: string;
  coeli_islivinginanothercountry: string;
  coeli_citizenshipinanothercountry: boolean;
  coeli_connectiontousa: boolean;
  coeli_taxtesidencyotherthansweden: boolean;
  coeli_selfdeclarationcountry: string;
  coeli_selfdeclarationtinorequivalent: string;
  coeli_taxableactivity: string;
  coeli_functionaspep: string;
  coeli_functionceased: string;
  coeli_isfamilymemberpep: string;
  coeli_familymemberpepname: string;
  coeli_familymemberpepsocialsecuritynumber: string;
  coeli_connectiontocustomer: string;
  coeli_familymemberfunctionaspep: string;
  coeli_familymemberfunctionceased: string;
  coeli_depositsfrom: string;
  coeli_mainreasonforinvestment: string;
  coeli_averageexpecteddeposit: string;
  coeli_depositfrequencychoice: string;
}

export interface ICustomerApproval {
  id: string;
  approvalItemId: string;
  approverId: string;
  comment: string;
  decision: string;
  date: Date | null;
  approvalType: string;
  button: string;
  ownerName: string;
}

function ApprovalHistoryDetailsDialog(
  props: ApprovalHistoryDetailsDialogProps
) {
  const api = useApiClient();
  const { t } = useTranslation();

  const [open, setOpen] = React.useState(false);
  const isDisabled: boolean = true;

  const [firstName, setfirstName] = React.useState('');
  const [surname, setsurname] = React.useState('');
  const [socialSecurityNumber, setsocialSecurityNumber] = React.useState('');
  const [streetAddress, setstreetAddress] = React.useState('');
  const [zipCode, setzipCode] = React.useState('');
  const [city, setcity] = React.useState('');
  const [country, setcountry] = React.useState('');
  const [isrepresentative, setisrepresentative] = React.useState('');
  const [
    representativeSocialSecurityNumber,
    setrepresentativeSocialSecurityNumber,
  ] = React.useState('');
  const [relationshipToCustomer, setrelationshipToCustomer] =
    React.useState('');
  const [representativeId, setrepresentativeId] = React.useState('');
  const [beneficialOwnerPep, setbeneficialOwnerPep] = React.useState('');

  const [businessrelationshipForWhom, setbusinessrelationshipForWhom] =
    React.useState('');
  const [realMandatorPep, setrealMandatorPep] = React.useState('');
  const [realMandatorPepName, setrealMandatorPepName] = React.useState('');
  const [
    realMandatorPepSocialSecurityNumber,
    setrealMandatorPepSocialSecurityNumber,
  ] = React.useState('');
  const [taxDomicileOrAffiliation, settaxDomicileOrAffiliation] =
    React.useState('');
  const [isLivingInAnotherCountry, setisLivingInAnotherCountry] =
    React.useState(false);
  const [citizenshipInAnotherCountry, setcitizenshipInAnotherCountry] =
    React.useState(false);
  const [connectionToUsa, setconnectionToUsa] = React.useState(false);
  const [taxResidencyOtherThanSweden, settaxResidencyOtherThanSweden] =
    React.useState(false);
  const [selfDeclarationCountry, setselfDeclarationCountry] =
    React.useState('');
  const [selfDeclarationTinOrEquivalent, setselfDeclarationTinOrEquivalent] =
    React.useState('');

  const [taxableActivity, settaxableActivity] = React.useState('');
  const [isPep, setisPep] = React.useState('');
  const [functionAsPep, setfunctionAsPep] = React.useState('');
  const [familyMemberPep, setfamilyMemberPep] = React.useState('');
  const [familyMemberPepName, setfamilyMemberPepName] = React.useState('');
  const [
    familyMemberPepSocialSecurityNumber,
    setfamilyMemberPepSocialSecurityNumber,
  ] = React.useState('');
  const [connectionToCustomer, setconnectionToCustomer] = React.useState('');
  const [familyMemberFunctionAsPep, setfamilyMemberFunctionAsPep] =
    React.useState('');

  let initialValues: IKycDto = {
    coeli_kycid: '',
    coeli_surname: '',
    coeli_firstname: '',
    coeli_socialsecuritynumber: '',
    coeli_streetaddress: '',
    coeli_zipcode: '',
    coeli_city: '',
    coeli_country: '',
    coeli_isrepresentative: '',
    coeli_representativesocialsecuritynumber: '',
    coeli_relationshiptocustomertext: '',
    coeli_representativeidentificator: '',
    coeli_beneficialownerpep: '',
    coeli_businessrelationshipforwhom: '',
    coeli_ispep: '',
    coeli_realmandatorpepname: '',
    coeli_realmandatorpepsocialsecuritynumber: '',
    coeli_taxdomicileoraffiliation: '',
    coeli_islivinginanothercountry: '',
    coeli_citizenshipinanothercountry: false,
    coeli_connectiontousa: false,
    coeli_taxtesidencyotherthansweden: false,
    coeli_selfdeclarationcountry: '',
    coeli_selfdeclarationtinorequivalent: '',
    coeli_taxableactivity: '',
    coeli_functionaspep: '',
    coeli_functionceased: '',
    coeli_isfamilymemberpep: '',
    coeli_familymemberpepname: '',
    coeli_familymemberpepsocialsecuritynumber: '',
    coeli_connectiontocustomer: '',
    coeli_familymemberfunctionaspep: '',
    coeli_familymemberfunctionceased: '',
    coeli_depositsfrom: '',
    coeli_mainreasonforinvestment: '',
    coeli_averageexpecteddeposit: '',
    coeli_depositfrequencychoice: '',
  };

  const handleClickOpenDetails = () => {
    api.get(`api/KycNew/GetKycById/${props.approvalItemId}`).then((res) => {
      initialValues = { ...res.data };

      setfirstName(initialValues.coeli_firstname);
      setsurname(initialValues.coeli_surname);
      setsocialSecurityNumber(initialValues.coeli_socialsecuritynumber);
      setstreetAddress(initialValues.coeli_streetaddress);
      setzipCode(initialValues.coeli_zipcode);
      setcity(initialValues.coeli_city);
      setcountry(initialValues.coeli_country);
      setisrepresentative(initialValues.coeli_isrepresentative);
      setrepresentativeSocialSecurityNumber(
        initialValues.coeli_representativesocialsecuritynumber
      );
      setrelationshipToCustomer(initialValues.coeli_relationshiptocustomertext);
      setrepresentativeId(initialValues.coeli_representativeidentificator);
      setbeneficialOwnerPep(initialValues.coeli_beneficialownerpep);
      setbusinessrelationshipForWhom(
        initialValues.coeli_businessrelationshipforwhom
      );
      setrealMandatorPep(initialValues.coeli_ispep);
      setrealMandatorPepName(initialValues.coeli_realmandatorpepname);
      setrealMandatorPepSocialSecurityNumber(
        initialValues.coeli_realmandatorpepsocialsecuritynumber
      );
      settaxDomicileOrAffiliation(initialValues.coeli_taxdomicileoraffiliation);
      setisLivingInAnotherCountry(
        initialValues.coeli_islivinginanothercountry === 'true' ? true : false
      );
      setcitizenshipInAnotherCountry(
        initialValues.coeli_citizenshipinanothercountry
      );
      setconnectionToUsa(initialValues.coeli_connectiontousa);
      settaxResidencyOtherThanSweden(
        initialValues.coeli_taxtesidencyotherthansweden
      );
      setselfDeclarationCountry(initialValues.coeli_selfdeclarationcountry);
      setselfDeclarationTinOrEquivalent(
        initialValues.coeli_selfdeclarationtinorequivalent
      );
      settaxableActivity(initialValues.coeli_taxableactivity);
      setisPep(initialValues.coeli_ispep);
      setfunctionAsPep(initialValues.coeli_functionaspep);
      setfamilyMemberPep(initialValues.coeli_functionaspep);
      setfamilyMemberPepName(initialValues.coeli_familymemberpepname);
      setfamilyMemberPepSocialSecurityNumber(
        initialValues.coeli_familymemberpepsocialsecuritynumber
      );
      setconnectionToCustomer(initialValues.coeli_connectiontocustomer);
      setfamilyMemberFunctionAsPep(
        initialValues.coeli_familymemberfunctionaspep
      );

      setOpen(true);
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        size="small"
        style={{ marginLeft: 16 }}
        onClick={handleClickOpenDetails}
      >
        {t('Details')}
      </Button>
      <Dialog maxWidth="lg" fullWidth={true} open={open}>
        <DialogTitle>{t('Details')}</DialogTitle>
        <DialogContent>
          {open ? (
            <Grid container spacing={6} mt={2}>
              <Grid item xs={12} mt={4}>
                <TextField
                  name="firstName"
                  label={'1. ' + t('Firstname')}
                  value={firstName}
                  fullWidth
                  variant="outlined"
                  disabled={isDisabled}
                />
              </Grid>
              <Grid item xs={12} mt={4}>
                <TextField
                  id="surname"
                  name="surname"
                  label={'2. ' + t('Lastname')}
                  value={surname}
                  onChange={(e) => setsurname(e.target.value)}
                  fullWidth
                  variant="outlined"
                  disabled={isDisabled}
                />
              </Grid>
              <Grid item xs={12} mt={4}>
                <TextField
                  name="socialSecurityNumber"
                  label={'3. ' + t('SocialSecurityNumber')}
                  fullWidth
                  value={socialSecurityNumber}
                  variant="outlined"
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} mt={4}>
                <TextField
                  name="streetAddress"
                  label={'4. ' + t('StreetAddress')}
                  value={streetAddress}
                  fullWidth
                  variant="outlined"
                  disabled={isDisabled}
                />
              </Grid>
              <Grid item xs={12} mt={4}>
                <TextField
                  name="zipCode"
                  label={'5. ' + t('ZipCode')}
                  value={zipCode}
                  fullWidth
                  variant="outlined"
                  disabled={isDisabled}
                />
              </Grid>
              <Grid item xs={12} mt={4}>
                <TextField
                  name="city"
                  label={'6. ' + t('City')}
                  value={city}
                  fullWidth
                  variant="outlined"
                  disabled={isDisabled}
                />
              </Grid>
              <Grid item xs={12} mt={4}>
                <TextField
                  name="country"
                  label={'7. ' + t('Country')}
                  value={country}
                  fullWidth
                  variant="outlined"
                  disabled={isDisabled}
                />
              </Grid>
              {/* 8 */}
              <Grid item xs={12} mt={4}>
                <FormLabel component="legend">8. {t('Represented')}</FormLabel>
                <RadioGroup
                  aria-label="representative"
                  name="representative"
                  value={isrepresentative}
                >
                  <FormControlLabel
                    value="true"
                    disabled={isDisabled}
                    control={<Radio />}
                    label={t('Yes')}
                  />
                  <FormControlLabel
                    value="false"
                    disabled={isDisabled}
                    control={<Radio />}
                    label={t('No')}
                  />
                </RadioGroup>
              </Grid>
              <>
                {/* 9 */}
                <Grid item xs={12} mt={4}>
                  <TextField
                    name="representativeSocialSecurityNumber"
                    label={'9. ' + t('kyc.RepresentativeSocialSecurityNumber')}
                    value={representativeSocialSecurityNumber}
                    fullWidth
                    disabled={isDisabled}
                  />
                </Grid>

                {/* 10 */}
                <Grid item xs={12} mt={4}>
                  <TextField
                    name="relationshipToCustomer"
                    label={'10. ' + t('kyc.RelationshipToCustomer')}
                    value={relationshipToCustomer}
                    fullWidth
                    variant="outlined"
                    disabled={isDisabled}
                  />
                </Grid>

                {/* 11 */}
                <Grid item xs={12} mt={4}>
                  <FormLabel component="legend">
                    {'11. ' + t('kyc.IDCopyFromRepresentative')}
                  </FormLabel>
                  <RadioGroup
                    aria-label="representativeId"
                    name="representativeId"
                    value={representativeId}
                  >
                    <FormControlLabel
                      value="true"
                      disabled={isDisabled}
                      control={<Radio />}
                      label={t('Yes')}
                    />
                    <FormControlLabel
                      value="false"
                      disabled={isDisabled}
                      control={<Radio />}
                      label={t('No')}
                    />
                  </RadioGroup>
                </Grid>
              </>
              {/* 12 */}
              <Grid item xs={12} mt={4}>
                <FormLabel component="legend">
                  {'12. ' + t('kyc.BusinessRelationshipQuestion')}
                </FormLabel>
                <RadioGroup
                  aria-label="beneficialOwnerPep"
                  name="beneficialOwnerPep"
                  value={beneficialOwnerPep}
                >
                  <FormControlLabel
                    value="true"
                    disabled={isDisabled}
                    control={<Radio />}
                    label={t('Yes')}
                  />
                  <FormControlLabel
                    value="false"
                    disabled={isDisabled}
                    control={<Radio />}
                    label={t('No')}
                  />
                </RadioGroup>
              </Grid>
              <>
                {/* 13 */}
                <Grid item xs={12} mt={4}>
                  <TextField
                    name="businessrelationshipForWhom"
                    label={'13. ' + t('kyc.BusinessrelationshipForWhom')}
                    fullWidth
                    variant="outlined"
                    disabled={isDisabled}
                    value={businessrelationshipForWhom}
                  />
                </Grid>

                {/* 14 */}
                <Grid item xs={12} mt={4}>
                  <FormLabel component="legend">
                    {'14. ' + t('kyc.RealMandatorPep')}
                  </FormLabel>
                  <RadioGroup
                    aria-label="realMandatorPep"
                    name="realMandatorPep"
                    value={realMandatorPep}
                  >
                    <FormControlLabel
                      value="true"
                      disabled={isDisabled}
                      control={<Radio />}
                      label={t('Yes')}
                    />
                    <FormControlLabel
                      value="false"
                      disabled={isDisabled}
                      control={<Radio />}
                      label={t('No')}
                    />
                  </RadioGroup>
                </Grid>

                {/* 15 */}
                <>
                  <Grid item xs={12} mt={4}>
                    <TextField
                      name="realMandatorPepName"
                      label={'15. ' + t('kyc.RealMandatorPepName')}
                      fullWidth
                      variant="outlined"
                      disabled={isDisabled}
                      value={realMandatorPepName}
                    />
                  </Grid>

                  <Grid item xs={12} mt={4}>
                    <TextField
                      name="realMandatorPepSocialSecurityNumber"
                      label={
                        '15. ' + t('kyc.RealMandatorPepSocialSecurityNumber')
                      }
                      fullWidth
                      variant="outlined"
                      disabled={isDisabled}
                      value={realMandatorPepSocialSecurityNumber}
                    />
                  </Grid>
                </>
              </>
              {/* 16 */}
              <Grid item xs={12} mt={4}>
                <FormLabel component="legend">
                  {'16. ' + t('kyc.TaxDomicileOrAffiliation')}
                </FormLabel>
                <RadioGroup
                  aria-label="taxDomicileOrAffiliation"
                  name="taxDomicileOrAffiliation"
                  value={taxDomicileOrAffiliation}
                >
                  <FormControlLabel
                    value="true"
                    disabled={isDisabled}
                    control={<Radio />}
                    label={t('Yes')}
                  />
                  <FormControlLabel
                    value="false"
                    disabled={isDisabled}
                    control={<Radio />}
                    label={t('No')}
                  />
                </RadioGroup>
              </Grid>
              <>
                {/* 17 */}
                <Grid item md={12} mt={4}>
                  <FormLabel component="legend">
                    {'17. ' + t('kyc.TaxDomicileOrAffiliationUS')}
                  </FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isLivingInAnotherCountry}
                          disabled={isDisabled}
                          name="isLivingInAnotherCountry"
                        />
                      }
                      label={t(
                        'kyc.taxDomicileOrAffiliationChocies.ResidingAbroad'
                      )}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={citizenshipInAnotherCountry}
                          disabled={isDisabled}
                          name="citizenshipInAnotherCountry"
                        />
                      }
                      label={t(
                        'kyc.taxDomicileOrAffiliationChocies.CitizenshipInAnotherCountry'
                      )}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={connectionToUsa}
                          disabled={isDisabled}
                          name="connectionToUsa"
                        />
                      }
                      label={t(
                        'kyc.taxDomicileOrAffiliationChocies.ConnectionToUSA'
                      )}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={taxResidencyOtherThanSweden}
                          disabled={isDisabled}
                          name="taxResidencyOtherThanSweden"
                        />
                      }
                      label={t(
                        'kyc.taxDomicileOrAffiliationChocies.TaxResidencyOtherThanSweden'
                      )}
                    />
                  </FormGroup>
                </Grid>

                {/* 18 */}
                <Grid item xs={12} mt={4}>
                  <TextField
                    name="selfDeclarationCountry"
                    label={'18. ' + t('kyc.SelfDeclarationCountry')}
                    fullWidth
                    variant="outlined"
                    disabled={isDisabled}
                    value={selfDeclarationCountry}
                  />
                </Grid>

                <Grid item xs={12} mt={4}>
                  <TextField
                    name="selfDeclarationTinOrEquivalent"
                    label={'19. ' + t('kyc.SelfDeclarationTinOrEquivalent')}
                    fullWidth
                    variant="outlined"
                    value={selfDeclarationTinOrEquivalent}
                    disabled={isDisabled}
                  />
                </Grid>

                <Grid item xs={12} mt={4}>
                  <TextField
                    name="taxableActivity"
                    label={'20. ' + t('kyc.TaxableActivity')}
                    fullWidth
                    variant="outlined"
                    disabled={isDisabled}
                    value={taxableActivity}
                  />
                </Grid>
              </>
              <Grid item xs={12} mt={4}>
                <FormLabel component="legend">
                  {'21. ' + t('kyc.CustomerPEP')}
                </FormLabel>
                <RadioGroup aria-label="isPep" name="isPep" value={isPep}>
                  <FormControlLabel
                    value="true"
                    disabled={isDisabled}
                    control={<Radio />}
                    label={t('Yes')}
                  />
                  <FormControlLabel
                    value="false"
                    disabled={isDisabled}
                    control={<Radio />}
                    label={t('No')}
                  />
                </RadioGroup>
              </Grid>
              <>
                <Grid item xs={12} mt={4}>
                  <TextField
                    name="functionAsPep"
                    label={'22. ' + t('kyc.FunctionAsPep')}
                    fullWidth
                    variant="outlined"
                    disabled={isDisabled}
                    value={functionAsPep}
                  />
                </Grid>
              </>
              <Grid item xs={12} mt={4}>
                <FormLabel component="legend">
                  {'23. ' + t('kyc.FamilyMemberPep')}
                </FormLabel>
                <RadioGroup
                  aria-label="familyMemberPep"
                  name="familyMemberPep"
                  value={familyMemberPep}
                >
                  <FormControlLabel
                    value="true"
                    disabled={isDisabled}
                    control={<Radio />}
                    label={t('Yes')}
                  />
                  <FormControlLabel
                    value="false"
                    disabled={isDisabled}
                    control={<Radio />}
                    label={t('No')}
                  />
                </RadioGroup>
              </Grid>
              {familyMemberPep === 'true' ? (
                <>
                  <Grid item xs={12} mt={4}>
                    <TextField
                      name="familyMemberPepName"
                      label={t('kyc.FamilyMemberPepName')}
                      value={familyMemberPepName}
                      fullWidth
                      variant="outlined"
                      disabled={isDisabled}
                    />
                  </Grid>

                  <Grid item xs={12} mt={4}>
                    <TextField
                      name="familyMemberPepSocialSecurityNumber"
                      label={t('kyc.FamilyMemberPepSocialSecurityNumber')}
                      value={familyMemberPepSocialSecurityNumber}
                      fullWidth
                      variant="outlined"
                      disabled={isDisabled}
                    />
                  </Grid>

                  <Grid item xs={12} mt={4}>
                    <TextField
                      name="connectionToCustomer"
                      label={t('kyc.ConnectionToCustomer')}
                      value={connectionToCustomer}
                      fullWidth
                      variant="outlined"
                      disabled={isDisabled}
                    />
                  </Grid>

                  <Grid item xs={12} mt={4}>
                    <TextField
                      name="familyMemberFunctionAsPep"
                      label={t('kyc.FunctionAsPep')}
                      value={familyMemberFunctionAsPep}
                      fullWidth
                      variant="outlined"
                      disabled={isDisabled}
                    />
                  </Grid>
                </>
              ) : null}
            </Grid>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('Close')}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function ApprovalHistoryTradeOrderDetailsDialog(
  props: ApprovalHistoryDetailsDialogProps
) {
  const api = useApiClient();
  const { t } = useTranslation();

  const [open, setOpen] = React.useState(false);
  const [isFetched, setIsFetched] = React.useState(false);
  const [tradeOrder, setTradeOrder] = React.useState<ITradeorder[]>([]);

  const handleClickOpenDetails = () => {
    setIsFetched(false);
    api
      .get(
        `api/tradeorder/GetTradeOrdersByOpportunityId/${props.approvalItemId}`
      )
      .then((res) => {
        var tradeOrders = res.data as ITradeorder[];
        setTradeOrder(tradeOrders);
        setIsFetched(true);
      });
    setOpen(true);
  };

  const handleClickExante = (tradeOrderRequestId: string) => {
    setIsFetched(false);
    api
      .get(
        `api/DocumentGenerator/ExecuteTradeorderFlow/${tradeOrderRequestId}`,
        {
          method: 'GET',
          responseType: 'text',
        }
      )
      .then(
        (res) => {
          let result = JSON.parse(res.data);
          if (result.status === 'Running' || result.status === 'Queued') {
            checkFlowResult(result.id);
          } else if (result.status === 'Error') {
            alert(result.message);
            setIsFetched(true);
          } else {
            getDocumentIds(result);
          }
        },
        (err) => {
          setIsFetched(true);
        }
      );
  };

  const getDocumentIds = (result: any) => {
    if (Object.keys(result.model).length > 1) {
      for (var prop in result.model) {
        setIsFetched(false);
        let documentObject = Common.getObject(result.model[prop], 'document');
        getDocumentContent(documentObject.document.id);
      }
    } else {
      let documentObject = Common.getObject(result.model, 'document');
      getDocumentContent(documentObject.document.id);
    }
  };

  const checkFlowResult = (executionId: string) => {
    api
      .get(`api/DocumentGenerator/CheckDocumentExecution/${executionId}`, {
        method: 'GET',
        responseType: 'text',
      })
      .then(
        (res) => {
          let result = JSON.parse(res.data);
          if (result.status === 'Running' || result.status === 'Queued') {
            checkFlowResult(result.id);
          } else if (result.status === 'Error') {
            setIsFetched(true);
          } else {
            getDocumentIds(result);
          }
        },
        (err) => {
          setIsFetched(true);
        }
      );
  };

  const getDocumentContent = (documentId: string) => {
    api
      .get(`api/DocumentGenerator/GetDocumentContent/${documentId}`, {
        method: 'GET',
        responseType: 'text',
      })
      .then(
        (res) => {
          const url = 'data:application/octet-stream;base64,' + res.data;
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Portfolio Exante.pdf');
          document.body.appendChild(link);
          setIsFetched(true);
          link.click();
        },
        (err) => {
          setIsFetched(true);
        }
      );
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        size="small"
        style={{ marginLeft: 16 }}
        onClick={handleClickOpenDetails}
      >
        {t('Details')}
      </Button>
      <Dialog maxWidth="lg" fullWidth={true} open={open}>
        <DialogTitle>{t('Details')}</DialogTitle>
        <DialogContent>
          {isFetched &&
            tradeOrder.map((c) => {
              return (
                <Box
                  key={c.coeli_tradeorderid + c.coeli_name}
                  mb={2}
                  pb={2}
                  className="trade-order-details-box"
                >
                  <Typography className="trade-order-details">
                    <Typography variant="h4">
                      {t('tradeOrders.Security')}:
                    </Typography>
                    <Typography>{c.coeli_name} </Typography>
                    <Typography variant="h4">
                      {t('tradeOrders.TradeOrderType')}:{' '}
                    </Typography>
                    <Typography>
                      {getOrderTypeName(c.coeli_tradeordertype)}
                    </Typography>
                    <Typography variant="h4">
                      {t('tradeOrders.Fee')}:{' '}
                    </Typography>
                    <CurrencyField value={c.coeli_transactionfee} />
                    <Typography variant="h4">
                      {t('tradeOrders.Amount')}:
                    </Typography>
                    <NumberField value={c.coeli_amount} decimals={4} />
                    <Typography variant="h4">
                      {t('tradeOrders.Unitprice')}:
                    </Typography>
                    <CurrencyField value={c.coeli_unitprice} />
                    <Typography variant="h4">
                      {t('tradeOrders.TradeAmount')}:{' '}
                    </Typography>
                    <CurrencyField value={c.coeli_unitprice * c.coeli_amount} />
                    <Typography variant="h4">
                      {t('tradeOrders.TransactionDate')}:
                    </Typography>
                    <Typography>
                      {format(new Date(c.coeli_tradeorderdate), 'yyyy-MM-dd')}{' '}
                    </Typography>
                    <br />
                  </Typography>
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    startIcon={<InsertDriveFileIcon />}
                    onClick={() =>
                      handleClickExante(c.coeli_tradeorderrequestid)
                    }
                  >
                    {t('Exante')}
                  </Button>
                </Box>
              );
            })}
          {!isFetched && (
            <Box display="flex" justifyContent="center" my={6}>
              <CircularProgress />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('Close')}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function ApprovalHistory() {
  const [isFetched, setIsFetched] = useState(false);
  const api = useApiClient();
  const users = useAppSelector((state) => state.users);
  const { t } = useTranslation();

  const mycolumns: GridColDef[] = [
    {
      field: 'button',
      headerName: '',
      width: 160,
      renderCell: (params: GridRenderCellParams) => (
        <strong>
          {(params.row.approvalType === 'Portfolio Agreement' ||
            params.row.approvalType === 'New Portfolio Agreement') &&
          params.row.decision === 'Approved' ? (
            <Button
              variant="outlined"
              color="primary"
              size="small"
              style={{ marginLeft: 16 }}
              onClick={() =>
                handleClickDownloadPortfolioAgreementFile(
                  params.row.id as string
                )
              }
            >
              {t('Download')}
            </Button>
          ) : null}
          {params.row.approvalType === 'Other Document' &&
          params.row.decision === 'Approved' ? (
            <Button
              variant="outlined"
              color="primary"
              size="small"
              style={{ marginLeft: 16 }}
              onClick={() => {
                handleClickDownloadDocument(
                  params.row.approvalItemId as string
                );
              }}
            >
              {t('Download')}
            </Button>
          ) : null}
          {params.row.approvalType === 'Kyc' ? <></> : null}

          {params.row.approvalType === 'Trade Order' ? (
            <>
              <ApprovalHistoryTradeOrderDetailsDialog
                approvalItemId={params.row.approvalItemId as string}
              />
            </>
          ) : null}
        </strong>
      ),
    },
    {
      field: 'approvalType',
      headerName: t('DocumentType'),
      width: 160,
      valueFormatter: (params: GridValueFormatterParams<string>) => {
        if (params.value == null) {
          return '';
        }

        const valueFormatted = translateCRMString(params.value);
        return valueFormatted;
      },
    },
    { field: 'approverId', headerName: t('Approver'), width: 160 },
    {
      field: 'date',
      headerName: t('Date'),
      type: 'string',
      width: 120,
      valueFormatter: (params: GridValueFormatterParams<Date>) => {
        return format(new Date(params.value), 'yyyy-MM-dd');
      },
    },
    {
      field: 'decision',
      headerName: t('Status'),
      sortable: false,
      width: 160,
      valueFormatter: (params: GridValueFormatterParams<string>) => {
        if (params.value == null) {
          return '';
        }

        const valueFormatted = translateCRMString(params.value);
        return valueFormatted;
      },
    },
    {
      field: 'comment',
      headerName: t('Comment'),
      minWidth: 200,
      //resizable: true,
      flex: 400,
    },
  ];

  const handleClickDownloadPortfolioAgreementFile = (
    approvalhistoryid: string
  ) => {
    api
      .get(`api/portfolio/getapporvalhisoryfile/${approvalhistoryid}`, {
        method: 'GET',
      })
      .then((res) => {
        const url = res.data.url;
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', res.data.fileName);
        document.body.appendChild(link);
        link.click();
      });
  };

  const handleClickDownloadDocument = (approvalhistoryid: string) => {
    api
      .get(`api/OtherDocuments/getfile/${approvalhistoryid}`, {
        method: 'GET',
      })
      .then((res) => {
        const url = res.data.url;
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', res.data.fileName);
        document.body.appendChild(link);
        link.click();
      });
  };

  useEffect(() => {
    const activeUserId = getActiveUser(users)?.id;
    api.get(`api/kyc/approvalhistory/${activeUserId}`).then((res) => {
      setIsFetched(false);
      myrows = [];
      res.data.forEach((d: ICustomerApproval) => {
        myrows.push({
          id: d.id,
          approvalItemId: d.approvalItemId,
          button: d.id,
          approverId: d.approverId,
          approvalType: d.approvalType,
          date: d.date,
          decision: d.decision,
          comment: d.comment,
          ownerName: d.ownerName,
        });
      });
      setIsFetched(true);
    });
  }, [users]);

  return (
    <React.Fragment>
      <Grid container spacing={6}>
        <Grid item xs={12} md={12}>
          {isFetched ? (
            <>
              <div style={{ width: '100%' }}>
                <DataGrid
                  disableRowSelectionOnClick
                  rows={myrows}
                  columns={mycolumns}
                  autoHeight
                  pageSizeOptions={[10]}
                  initialState={{
                    sorting: {
                      sortModel: [{ field: 'date', sort: 'desc' }],
                    },
                  }}
                />
              </div>
            </>
          ) : (
            <Box display="flex" justifyContent="center" my={6}>
              <CircularProgress />
            </Box>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default ApprovalHistory;
