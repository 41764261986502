import { NewsWidget } from '../../components/widgets/InFront/NewsWidget';
import { useAppSelector } from '../../redux/hooks';
import { useEffect, useState } from 'react';
import { useApiClient } from '../../hooks/apiClient';
import { getActiveUser } from '../../redux/stateFuncs';
import { useTranslation } from 'react-i18next';
import { FaPortfolioPositionsDto } from '../../models/dto/faPortfolioPositionDto';
import { FaPositionDto } from '../../models/dto/faPositionDto';

export default function PortfolioNews() {
  const [isLoading, setIsLoading] = useState<boolean>();
  const [error, setError] = useState<string | null>(null);
  const [portfolioPositions, setPortfolioPositions] = useState<
    FaPortfolioPositionsDto[]
  >([]);

  const [data, setData] = useState<FaPositionDto[]>([]);
  const [symbols, setSymbols] = useState<string[]>([]);

  const portfolios = useAppSelector((state) => state.portfolios);
  const api = useApiClient();
  const users = useAppSelector((state) => state.users);
  var activeUser = getActiveUser(users);
  const { t } = useTranslation();

  useEffect(() => {
    setIsLoading(true);
    api
      .get<FaPortfolioPositionsDto[]>(
        'api/FaSecurity/GetPortfolioPositions/' +
          activeUser?.socialSecurityNumber
      )
      .then((res) => {
        portfolioPositions.splice(0);
        res.data.forEach((i) => {
          portfolioPositions.push(i);
        });
        setIsLoading(false);
        setError(null);
        setData(
          portfolioPositions
            .filter((e) =>
              portfolios
                .filter((p) => p.show == true)
                .map((p) => p.id)
                .includes(e.id)
            )
            .map((pp) => pp.positions)
            .flat()
        );
      })
      .catch((err) => {
        console.error(err);
        setError('Error loading portfolio positions');
      });
  }, []);

  useEffect(() => {
    setData(
      portfolioPositions
        .filter((e) =>
          portfolios
            .filter((p) => p.show == true)
            .map((p) => p.id)
            .includes(e.id)
        )
        .map((pp) => pp.positions)
        .flat()
    );
  }, [portfolios]);

  useEffect(() => {
    setSymbols(data.map((d) => d.id));
  }, [data]);

  return (
    <>
      {symbols.length > 1 ? (
        <NewsWidget symbols={symbols} id="PortfolisNewsWidget4"></NewsWidget>
      ) : (
        <></>
      )}
    </>
  );
}
