import React, { useEffect, useState } from 'react';
import { Formik, useFormikContext } from 'formik';
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormGroup,
  Checkbox,
  RadioGroup,
  Radio,
  Link,
} from '@mui/material';

import Button from '@mui/material/Button';

import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useApiClient, IApiError } from '../../hooks/apiClient';
import { IKycDto } from '../../models/kyc';
import { getActiveUser, getMainUser } from '../../redux/stateFuncs';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setToDoComplete } from '../../redux/slices/todo';
import { ToDoEnum } from '../../Enums';
import { DatePicker } from '@mui/x-date-pickers';

let initialValues: IKycDto = {
  id: '',
  contactId: '',
  contactLogicalName: '',
  ownerName: '',
  approverId: '',
  statuscode: '',
  firstName: '',
  surname: '',
  socialSecurityNumber: '',
  email: '',
  phoneNumber: '',
  streetAddress: '',
  zipCode: '',
  city: '',
  country: '',
  representative: '',
  representativeSocialSecurityNumber: '',
  relationshipToCustomer: '',
  representativeId: '',
  beneficialOwnerPep: '',
  businessrelationshipForWhom: '',
  businessrelationshipForWhomSocialSecurityNumber: '',
  businessrelationshipForWhomId: '',
  realMandatorPep: '',
  realMandatorPepName: '',
  realMandatorPepSocialSecurityNumber: '',
  taxDomicileOrAffiliation: '',
  isLivingInAnotherCountry: false,
  citizenshipInAnotherCountry: false,
  connectionToUsa: false,
  taxResidencyOtherThanSweden: false,
  selfDeclarationCountry: '',
  selfDeclarationTinOrEquivalent: '',
  taxableActivity: '',
  isPep: '',
  functionAsPep: '',
  pepFunctionCeased: null,
  familyMemberPep: '',
  familyMemberPepName: '',
  familyMemberPepSocialSecurityNumber: '',
  connectionToCustomer: '',
  familyMemberFunctionAsPep: '',
  familyMemberFunctionCeased: null,
  depositsFromSvenskBankOrAnother: false,
  depositsFromForeignBankOrOtherWithinEss: false,
  depositsFromForeignBankOrOtherOutsideEss: false,
  mainReasonForInvestmentCapitalInvestment: false,
  mainReasonForInvestmentPension: false,
  averageExpectedDeposit: 0,
  depositFrequency: 0,
  rejectComment: '',

  moneyOriginSalary: false,
  moneyOriginPension: false,
  moneyOriginSavings: false,
  moneyOriginReturnOnInvestments: false,
  moneyOriginInsurance: false,
  moneyOriginRealEstateSales: false,
  moneyOriginCompanySales: false,
  moneyOriginInherited: false,
  moneyOriginOther: false,
  moneyOriginOtherSpecified: '',
};

function KycForm(props: any) {
  const api = useApiClient();
  const users = useAppSelector((state) => state.users);
  const dispatch = useAppDispatch();

  const IsToDo = props.isToDo;
  const { t } = useTranslation();

  //confirm
  function FormDialogConfirm(props: any) {
    const [open, setOpen] = React.useState(false);
    const [apiError, setApiError] = React.useState<IApiError>();
    const { values, setSubmitting, isSubmitting } = useFormikContext();

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = (isSubmit: boolean) => {
      if (isSubmit) {
        setSubmitting(true);
        const mainUserId = getMainUser(users)?.id;
        const kycData = values as IKycDto;
        const kycDataConfirmedStatuscode = {
          ...kycData,
          statuscode: 'Confirmed',
          approverId: mainUserId,
        };
        if (props.isEdit && !IsToDo) {
          api.post('api/kyc', { ...kycData }).then((res) => {
            const guid: string = res.data;
            kycDataConfirmedStatuscode.id = guid;
            api
              .post('api/kyc/update', { ...kycDataConfirmedStatuscode })
              .then((res) => {
                props.handleIsFetch(false);
                props.handlekycDataChanged(!props.dataChangd);
                props.handleIsEdit(false);
                setOpen(false);
              });
          });
        } else {
          api
            .post('api/kyc/update', { ...kycDataConfirmedStatuscode })
            .then((res) => {
              props.handleIsFetch(false);
              props.handlekycDataChanged(!props.dataChangd);
              if (IsToDo) {
                dispatch(setToDoComplete(ToDoEnum.Kyc));
              }
              setOpen(false);
            })
            .catch((error) => {
              setApiError(error);
              setSubmitting(false);
            });
        }
      } else {
        setOpen(false);
      }
    };

    return (
      <>
        <Button variant="contained" color="primary" onClick={handleClickOpen}>
          {t('Confirm')}
        </Button>
        <Dialog
          maxWidth="sm"
          fullWidth={true}
          open={open}
          onClose={handleClose}
        >
          {isSubmitting ? (
            <>
              <DialogContent>
                <Box display="flex" justifyContent="center" my={6}>
                  <CircularProgress />
                </Box>
                <Box display="flex" justifyContent="center" my={6}>
                  <Typography> {t('LongLoading')}</Typography>
                </Box>
              </DialogContent>
            </>
          ) : (
            <>
              {apiError ? (
                <>
                  <DialogTitle sx={{ color: '#f00' }}>
                    {t('errors.ErrorKYC')}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      {apiError.additionalDetails.message}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => handleClose(false)}>
                      {t('Close')}
                    </Button>
                  </DialogActions>
                </>
              ) : (
                <>
                  <DialogTitle>{t('LegalNote')}</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      {t('kyc.dialog.ConfirmText')}
                      <br />
                      <Link
                        href="https://coeli.se/legal-information-coeli-wealth-management-ab/"
                        target={'_blank'}
                      >
                        https://coeli.se/legal-information-coeli-wealth-management-ab/
                      </Link>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => handleClose(true)}>
                      {t('Confirm')}
                    </Button>
                    <Button onClick={() => handleClose(false)}>
                      {t('Cancel')}
                    </Button>
                  </DialogActions>
                </>
              )}
            </>
          )}
        </Dialog>
      </>
    );
  }

  //reject
  function FormDialogReject(props: any) {
    const [open, setOpen] = React.useState(false);
    const [rejectComment, setRejectComment] = React.useState<string>('');
    const [apiError, setApiError] = React.useState<IApiError>();
    const { values, setSubmitting, isSubmitting } = useFormikContext();

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = (isSubmit: boolean) => {
      if (isSubmit) {
        setSubmitting(true);

        const mainUserId = getMainUser(users)?.id;
        const kycData = values as IKycDto;
        kycData.statuscode = 'Rejected';
        kycData.rejectComment = rejectComment;

        api
          .post('api/kyc/update', { ...kycData, approverId: mainUserId })
          .then((res) => {
            props.handlekycDataChanged(!props.dataChangd);
            if (IsToDo) {
              dispatch(setToDoComplete(ToDoEnum.Kyc));
            }
            setOpen(false);
          })
          .catch((error) => {
            setApiError(error);
            setSubmitting(false);
          });
      } else {
        setOpen(false);
      }
    };

    return (
      <>
        <Button variant="contained" color="primary" onClick={handleClickOpen}>
          {t('Reject')}
        </Button>
        <Dialog
          maxWidth="sm"
          fullWidth={true}
          open={open}
          onClose={handleClose}
        >
          {isSubmitting ? (
            <>
              <DialogContent>
                <Box display="flex" justifyContent="center" my={6}>
                  <CircularProgress />
                </Box>
                <Box display="flex" justifyContent="center" my={6}>
                  <Typography> {t('LongLoading')}</Typography>
                </Box>
              </DialogContent>
            </>
          ) : (
            <>
              {apiError ? (
                <>
                  <DialogTitle sx={{ color: '#f00' }}>
                    {t('errors.ErrorKYC')}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      {apiError.additionalDetails.message}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => handleClose(false)}>
                      {t('Close')}
                    </Button>
                  </DialogActions>
                </>
              ) : (
                <>
                  <DialogTitle>{t('LegalNote')}</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      {t('kyc.dialog.RejectText')}
                    </DialogContentText>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="comment"
                      label={t('RejectReason')}
                      fullWidth
                      multiline
                      minRows={4}
                      variant="outlined"
                      required={true}
                      value={rejectComment}
                      onChange={(e) => setRejectComment(e.target.value)}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      disabled={rejectComment === '' ? true : false}
                      onClick={() => handleClose(true)}
                    >
                      {t('Reject')}
                    </Button>
                    <Button onClick={() => handleClose(false)}>
                      {t('Cancel')}
                    </Button>
                  </DialogActions>
                </>
              )}
            </>
          )}
        </Dialog>
      </>
    );
  }

  function BasicForm() {
    const [isEditable, setIsEditable] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isKycEnabled, setIsKycEnabled] = useState(false);
    const [dataChanged, setDataChanged] = useState(false);
    const [isFetched, setIsFetched] = useState(false);
    const users = useAppSelector((state) => state.users);
    const activeUserCanSign = getActiveUser(users)?.canSign;

    const handleEdit = (edit: boolean) => {
      setIsEditable(edit);
      setIsEdit(edit);
      setIsFetched(true);
    };

    const handleSubmitEdit = async (
      values: IKycDto,
      { resetForm, setErrors, setStatus, setSubmitting }: any
    ) => {};

    useEffect(() => {
      setIsFetched(false);
      const activeUserSSN = getActiveUser(users)?.socialSecurityNumber;

      api.get(`api/kyc/${activeUserSSN}`).then((res) => {
        const kycs: IKycDto[] = res.data;

        let item = kycs.find(
          (kyc) => kyc.statuscode === 'ClientRevision'
        ) as IKycDto;
        if (item) {
          if (activeUserCanSign) {
            setIsKycEnabled(true);
            console.log(item);
          } else {
            setIsKycEnabled(false);
          }
        } else {
          item = kycs.find((kyc) => kyc.statuscode === 'Confirmed') as IKycDto;
          if (item) {
            if (activeUserCanSign) {
              setIsKycEnabled(true);
            }
          } else {
            setIsKycEnabled(false);
            initialValues = {} as IKycDto;
          }
        }
        if (item) {
          initialValues = { ...(item as IKycDto) };
        }
        setTimeout(() => {
          setIsFetched(true);
        }, 1500);
      });
    }, [users, dataChanged]);

    return (
      <>
        {isFetched ? (
          <Formik
            initialValues={{ ...initialValues }}
            onSubmit={handleSubmitEdit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              setSubmitting,
              handleReset,
              isSubmitting,
              touched,
              values,
              status,
            }) =>
              values.id ? (
                <Card>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      {t('kyc.MyDataHeadline')}
                    </Typography>
                    {IsToDo ? (
                      <Typography variant="body2" gutterBottom>
                        {t('kyc.MyDataDesc')}
                      </Typography>
                    ) : (
                      <Typography variant="body2" gutterBottom>
                        {t('kyc.MyDataNonEditDesc')}
                      </Typography>
                    )}

                    <Typography display="inline">
                      Ansvarig hos Coeli:{' '}
                    </Typography>
                    <Typography
                      variant="body2"
                      display="inline"
                      fontWeight={'bold'}
                    >
                      {values.ownerName}
                    </Typography>
                    <form onSubmit={handleSubmit}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} mt={4}>
                          {activeUserCanSign ? (
                            <>
                              {isKycEnabled &&
                              !isEditable &&
                              !IsToDo /* Activate when KYC should be editable when confirmed already*/ ? (
                                <>
                                  {/* Edit */}
                                  {/* <Button
                                    onClick={() => handleEdit(true)}
                                    variant="outlined"
                                    color="primary"
                                  >
                                    {t('Edit')}
                                  </Button> */}
                                </>
                              ) : isEditable || IsToDo ? (
                                <>
                                  {/* Confirm / Cancel (edit) */}
                                  {isEdit ? (
                                    <Grid container spacing={1}>
                                      <Grid>
                                        <FormDialogConfirm
                                          handleIsFetch={setIsFetched}
                                          handlekycDataChanged={setDataChanged}
                                          dataChangd={dataChanged}
                                          isEdit={isEdit}
                                          handleIsEdit={handleEdit}
                                        ></FormDialogConfirm>
                                      </Grid>
                                      <Grid>
                                        <Button
                                          onClick={() => handleEdit(false)}
                                          color="primary"
                                          variant="contained"
                                        >
                                          {t('Cancel')}
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  ) : (
                                    <>
                                      {/* Confirm / Reject */}
                                      <Grid container spacing={1}>
                                        <Grid item>
                                          <FormDialogConfirm
                                            handleIsFetch={setIsFetched}
                                            handlekycDataChanged={
                                              setDataChanged
                                            }
                                            dataChangd={dataChanged}
                                          ></FormDialogConfirm>
                                        </Grid>
                                        <Grid item>
                                          {/* Edit */}
                                          <Button
                                            onClick={() => handleEdit(true)}
                                            variant="outlined"
                                            color="primary"
                                          >
                                            {t('Edit')}
                                          </Button>
                                        </Grid>
                                        <Grid item>
                                          <FormDialogReject
                                            handleIsFetch={setIsFetched}
                                            handlekycDataChanged={
                                              setDataChanged
                                            }
                                            dataChangd={dataChanged}
                                          ></FormDialogReject>
                                        </Grid>
                                      </Grid>
                                    </>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : null}
                        </Grid>

                        <Grid item xs={12} mt={4}>
                          <TextField
                            name="firstName"
                            label={t('Firstname')}
                            value={values.firstName}
                            error={Boolean(
                              touched.firstName && errors.firstName
                            )}
                            fullWidth
                            helperText={touched.firstName && errors.firstName}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                            disabled={!isEditable}
                          />
                        </Grid>

                        <Grid item xs={12} mt={4}>
                          <TextField
                            name="surname"
                            label={t('Lastname')}
                            value={values.surname}
                            error={Boolean(touched.surname && errors.surname)}
                            fullWidth
                            helperText={touched.surname && errors.surname}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                            disabled={!isEditable}
                          />
                        </Grid>

                        <Grid item xs={12} mt={4}>
                          <TextField
                            name="socialSecurityNumber"
                            label={t('SocialSecurityNumber')}
                            value={values.socialSecurityNumber}
                            error={Boolean(
                              touched.socialSecurityNumber &&
                                !isSSNValid(values.socialSecurityNumber)
                            )}
                            fullWidth
                            helperText={
                              touched.socialSecurityNumber &&
                              errors.socialSecurityNumber
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                            disabled={true}
                          />
                        </Grid>
                        <Grid item xs={12} mt={4}>
                          <TextField
                            name="email"
                            label={t('Email')}
                            value={values.email}
                            error={Boolean(touched.email && errors.email)}
                            fullWidth
                            helperText={touched.email && errors.email}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                            disabled={!isEditable}
                          />
                        </Grid>

                        <Grid item xs={12} mt={4}>
                          <TextField
                            name="phoneNumber"
                            label={t('PhoneNumber')}
                            value={values.phoneNumber}
                            error={Boolean(
                              touched.phoneNumber && errors.phoneNumber
                            )}
                            fullWidth
                            helperText={
                              touched.phoneNumber && errors.phoneNumber
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                            disabled={!isEditable}
                          />
                        </Grid>
                        <Grid item xs={12} mt={4}>
                          <TextField
                            name="streetAddress"
                            label={t('StreetAddress')}
                            value={values.streetAddress}
                            error={Boolean(
                              touched.streetAddress && errors.streetAddress
                            )}
                            fullWidth
                            helperText={
                              touched.streetAddress && errors.streetAddress
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                            disabled={!isEditable}
                          />
                        </Grid>

                        <Grid item xs={12} mt={4}>
                          <TextField
                            name="zipCode"
                            label={t('ZipCode')}
                            value={values.zipCode}
                            error={Boolean(touched.zipCode && errors.zipCode)}
                            fullWidth
                            helperText={touched.zipCode && errors.zipCode}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                            disabled={!isEditable}
                          />
                        </Grid>

                        <Grid item xs={12} mt={4}>
                          <TextField
                            name="city"
                            label={t('City')}
                            value={values.city}
                            error={Boolean(touched.city && errors.city)}
                            fullWidth
                            helperText={touched.city && errors.city}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                            disabled={!isEditable}
                          />
                        </Grid>

                        <Grid item xs={12} mt={4}>
                          <TextField
                            name="country"
                            label={t('Country')}
                            value={values.country}
                            error={Boolean(touched.country && errors.country)}
                            fullWidth
                            helperText={touched.country && errors.country}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                            disabled={!isEditable}
                          />
                        </Grid>
                        {/* 8 */}
                        <Grid item xs={12} mt={4}>
                          <FormControl component="fieldset">
                            <FormLabel component="legend">
                              {t('Represented')}
                            </FormLabel>
                            <RadioGroup
                              aria-label={t('Represented')}
                              name="representative"
                              value={values.representative}
                              onChange={handleChange}
                            >
                              <FormControlLabel
                                value="True"
                                disabled={true}
                                control={<Radio />}
                                label={t('Yes')}
                              />
                              <FormControlLabel
                                value="False"
                                disabled={!isEditable}
                                control={<Radio />}
                                label={t('No')}
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>

                      {values.representative === 'True' ? (
                        <>
                          {/* 9 */}
                          <Grid item xs={12} mt={4}>
                            <TextField
                              name="representativeId"
                              label={t(
                                'kyc.RepresentativeName'
                              )}
                              value={
                                values.representativeId || ''
                              }
                              error={Boolean(
                                touched.representativeId &&
                                  !isSSNValid(
                                    values.representativeId
                                  )
                              )}
                              fullWidth
                              helperText={
                                touched.representativeId &&
                                errors.representativeId
                              }
                              onBlur={handleBlur}
                              onChange={handleChange}
                              variant="outlined"
                              disabled={true}
                            />
                          </Grid>
                          <Grid item xs={12} mt={4}>
                            <TextField
                              name="representativeSocialSecurityNumber"
                              label={t(
                                'kyc.RepresentativeSocialSecurityNumber'
                              )}
                              value={
                                values.representativeSocialSecurityNumber || ''
                              }
                              error={Boolean(
                                touched.representativeSocialSecurityNumber &&
                                  !isSSNValid(
                                    values.representativeSocialSecurityNumber
                                  )
                              )}
                              fullWidth
                              helperText={
                                touched.representativeSocialSecurityNumber &&
                                errors.representativeSocialSecurityNumber
                              }
                              onBlur={handleBlur}
                              onChange={handleChange}
                              variant="outlined"
                              disabled={true}
                            />
                          </Grid>

                            {/* 10 */}
                            <Grid item xs={12} mt={4}>
                              <TextField
                                name="relationshipToCustomer"
                                label={t('kyc.RelationshipToCustomer')}
                                value={values.relationshipToCustomer || ''}
                                error={Boolean(
                                  touched.relationshipToCustomer &&
                                    errors.relationshipToCustomer
                                )}
                                fullWidth
                                helperText={
                                  touched.relationshipToCustomer &&
                                  errors.relationshipToCustomer
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={!isEditable}
                              />
                            </Grid>

                            {/* 11 */}
                            <Grid item xs={12} mt={4}>
                              <FormControl component="fieldset">
                                <FormLabel component="legend">
                                  {t('kyc.IDCopyFromRepresentative')}
                                </FormLabel>
                                <RadioGroup
                                  aria-label="representativeId"
                                  name="representativeId"
                                  value={values.representativeId || ''}
                                  onChange={handleChange}
                                >
                                  <FormControlLabel
                                    value="True"
                                    disabled={true}
                                    control={<Radio />}
                                    label={t('Yes')}
                                  />
                                  <FormControlLabel
                                    value="False"
                                    disabled={!isEditable}
                                    control={<Radio />}
                                    label={t('No')}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                          </>
                        ) : null}

                        {/* 12 */}
                        <Grid item xs={12} mt={4}>
                          <FormControl component="fieldset">
                            <FormLabel component="legend">
                              {t('kyc.BusinessRelationshipQuestion')}
                            </FormLabel>
                            <RadioGroup
                              aria-label="beneficialOwnerPep"
                              name="beneficialOwnerPep"
                              value={values.beneficialOwnerPep}
                              onChange={handleChange}
                            >
                              <FormControlLabel
                                value="True"
                                disabled={true}
                                control={<Radio />}
                                label={t('Yes')}
                              />
                              <FormControlLabel
                                value="False"
                                disabled={!isEditable}
                                control={<Radio />}
                                label={t('No')}
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>

                        {values.beneficialOwnerPep === 'True' ? (
                          <>
                            {/* 13 */}
                            <Grid item xs={12} mt={4}>
                              <TextField
                                name="businessrelationshipForWhom"
                                label={t('kyc.BusinessrelationshipForWhom')}
                                value={values.businessrelationshipForWhom || ''}
                                error={Boolean(
                                  touched.businessrelationshipForWhom &&
                                    errors.businessrelationshipForWhom
                                )}
                                fullWidth
                                helperText={
                                  touched.businessrelationshipForWhom &&
                                  errors.businessrelationshipForWhom
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={true}
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <TextField
                                name="BusinessrelationshipForWhomSocialSecurityNumber"
                                label={t(
                                  'kyc.BusinessrelationshipForWhomSocialSecurityNumber'
                                )}
                                value={
                                  values.businessrelationshipForWhomSocialSecurityNumber ||
                                  ''
                                }
                                error={Boolean(
                                  touched.businessrelationshipForWhomSocialSecurityNumber &&
                                    errors.businessrelationshipForWhomSocialSecurityNumber
                                )}
                                fullWidth
                                helperText={
                                  touched.businessrelationshipForWhomSocialSecurityNumber &&
                                  errors.businessrelationshipForWhomSocialSecurityNumber
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={true}
                              />
                            </Grid>

                            {/* 14 */}
                            <Grid item xs={12} mt={4}>
                              <FormControl component="fieldset">
                                <FormLabel component="legend">
                                  {t('kyc.RealMandatorPep')}
                                </FormLabel>
                                <RadioGroup
                                  aria-label="realMandatorPep"
                                  name="realMandatorPep"
                                  value={values.realMandatorPep}
                                  onChange={handleChange}
                                >
                                  <FormControlLabel
                                    value="True"
                                    disabled={!isEditable}
                                    control={<Radio />}
                                    label={t('Yes')}
                                  />
                                  <FormControlLabel
                                    value="False"
                                    disabled={!isEditable}
                                    control={<Radio />}
                                    label={t('No')}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>

                            {values.realMandatorPep === 'True' ? (
                              //{/* 15 */ }
                              <>
                                <Grid item xs={12} mt={4}>
                                  <TextField
                                    name="realMandatorPepName"
                                    label={t('kyc.RealMandatorPepName')}
                                    value={values.realMandatorPepName || ''}
                                    error={Boolean(
                                      touched.realMandatorPepName &&
                                        errors.realMandatorPepName
                                    )}
                                    fullWidth
                                    helperText={
                                      touched.realMandatorPepName &&
                                      errors.realMandatorPepName
                                    }
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    variant="outlined"
                                    disabled={!isEditable}
                                  />
                                </Grid>

                                <Grid item xs={12} mt={4}>
                                  <TextField
                                    name="realMandatorPepSocialSecurityNumber"
                                    label={t(
                                      'kyc.RealMandatorPepSocialSecurityNumber'
                                    )}
                                    value={
                                      values.realMandatorPepSocialSecurityNumber ||
                                      ''
                                    }
                                    error={Boolean(
                                      touched.realMandatorPepSocialSecurityNumber &&
                                        !isSSNValid(
                                          values.realMandatorPepSocialSecurityNumber
                                        )
                                    )}
                                    fullWidth
                                    helperText={
                                      touched.realMandatorPepSocialSecurityNumber &&
                                      errors.realMandatorPepSocialSecurityNumber
                                    }
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    variant="outlined"
                                    disabled={!isEditable}
                                  />
                                </Grid>
                              </>
                            ) : null}
                          </>
                        ) : null}

                        {/* 16 */}
                        <Grid item xs={12} mt={4}>
                          <FormControl component="fieldset">
                            <FormLabel component="legend">
                              {t('kyc.TaxDomicileOrAffiliation')}
                            </FormLabel>
                            <RadioGroup
                              aria-label="taxDomicileOrAffiliation"
                              name="taxDomicileOrAffiliation"
                              value={values.taxDomicileOrAffiliation}
                              onChange={handleChange}
                            >
                              <FormControlLabel
                                value="True"
                                disabled={!isEditable}
                                control={<Radio />}
                                label={t('Yes')}
                              />
                              <FormControlLabel
                                value="False"
                                disabled={!isEditable}
                                control={<Radio />}
                                label={t('No')}
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>

                        {values.taxDomicileOrAffiliation === 'True' ? (
                          <>
                            {/* 17 */}
                            <Grid item md={12} mt={2}>
                              <FormControl
                                sx={{ m: 3 }}
                                component="fieldset"
                                variant="standard"
                              >
                                <FormLabel component="legend">
                                  {t('kyc.TaxDomicileOrAffiliationUS')}
                                </FormLabel>
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={
                                          values.isLivingInAnotherCountry
                                        }
                                        onChange={handleChange}
                                        name="isLivingInAnotherCountry"
                                      />
                                    }
                                    disabled={!isEditable}
                                    label={t(
                                      'kyc.taxDomicileOrAffiliationChocies.ResidingAbroad'
                                    )}
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={
                                          values.citizenshipInAnotherCountry
                                        }
                                        onChange={handleChange}
                                        disabled={!isEditable}
                                        name="citizenshipInAnotherCountry"
                                      />
                                    }
                                    label={t(
                                      'kyc.taxDomicileOrAffiliationChocies.CitizenshipInAnotherCountry'
                                    )}
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={values.connectionToUsa}
                                        onChange={handleChange}
                                        disabled={!isEditable}
                                        name="connectionToUsa"
                                      />
                                    }
                                    label={t(
                                      'kyc.taxDomicileOrAffiliationChocies.ConnectionToUSA'
                                    )}
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={
                                          values.taxResidencyOtherThanSweden
                                        }
                                        onChange={handleChange}
                                        disabled={!isEditable}
                                        name="taxResidencyOtherThanSweden"
                                      />
                                    }
                                    label={t(
                                      'kyc.taxDomicileOrAffiliationChocies.TaxResidencyOtherThanSweden'
                                    )}
                                  />
                                </FormGroup>
                              </FormControl>
                            </Grid>

                            {/* 18 */}
                            <Grid item xs={12} mt={4}>
                              <TextField
                                name="selfDeclarationCountry"
                                label={t('kyc.SelfDeclarationCountry')}
                                value={values.selfDeclarationCountry}
                                error={Boolean(
                                  touched.selfDeclarationCountry &&
                                    errors.selfDeclarationCountry
                                )}
                                fullWidth
                                helperText={
                                  touched.selfDeclarationCountry &&
                                  errors.selfDeclarationCountry
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={!isEditable}
                              />
                            </Grid>

                            <Grid item xs={12} mt={4}>
                              <TextField
                                name="selfDeclarationTinOrEquivalent"
                                label={t('kyc.SelfDeclarationTinOrEquivalent')}
                                value={values.selfDeclarationTinOrEquivalent}
                                error={Boolean(
                                  touched.selfDeclarationTinOrEquivalent &&
                                    errors.selfDeclarationTinOrEquivalent
                                )}
                                fullWidth
                                helperText={
                                  touched.selfDeclarationTinOrEquivalent &&
                                  errors.selfDeclarationTinOrEquivalent
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={!isEditable}
                              />
                            </Grid>

                            <Grid item xs={12} mt={4}>
                              <TextField
                                name="taxableActivity"
                                label={t('kyc.TaxableActivity')}
                                value={values.taxableActivity}
                                error={Boolean(
                                  touched.taxableActivity &&
                                    errors.taxableActivity
                                )}
                                fullWidth
                                helperText={
                                  touched.taxableActivity &&
                                  errors.taxableActivity
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={!isEditable}
                              />
                            </Grid>
                          </>
                        ) : null}
                        <Grid item xs={12} mt={4}>
                          <FormControl component="fieldset">
                            <FormLabel component="legend">
                              {t('kyc.CustomerPEP')}
                            </FormLabel>
                            <RadioGroup
                              aria-label="isPep"
                              name="isPep"
                              value={values.isPep}
                              onChange={handleChange}
                            >
                              <FormControlLabel
                                value="True"
                                disabled={!isEditable}
                                control={<Radio />}
                                label={t('Yes')}
                              />
                              <FormControlLabel
                                value="False"
                                disabled={!isEditable}
                                control={<Radio />}
                                label={t('No')}
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>

                        {values.isPep === 'True' ? (
                          <>
                            <Grid item xs={12} mt={4}>
                              <TextField
                                name="functionAsPep"
                                label={t('kyc.FunctionAsPep')}
                                value={values.functionAsPep}
                                error={Boolean(
                                  touched.functionAsPep && errors.functionAsPep
                                )}
                                fullWidth
                                helperText={
                                  touched.functionAsPep && errors.functionAsPep
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={!isEditable}
                              />
                            </Grid>

                            <Grid item xs={12} md={8} mt={4}>
                              <Typography>{t('FunctionCeased')}</Typography>
                              <DatePicker
                                format="dd-MM-yyyy"
                                value={
                                  values.pepFunctionCeased != null
                                    ? new Date(values.pepFunctionCeased)
                                    : null
                                }
                                onChange={(val) =>
                                  setFieldValue('pepFunctionCeased', val)
                                }
                                disabled={!isEditable}
                              />
                            </Grid>
                          </>
                        ) : null}

                        <Grid item xs={12} mt={4}>
                          <FormControl component="fieldset">
                            <FormLabel component="legend">
                              {t('kyc.FamilyMemberPep')}
                            </FormLabel>
                            <RadioGroup
                              aria-label="familyMemberPep"
                              name="familyMemberPep"
                              value={values.familyMemberPep}
                              onChange={handleChange}
                            >
                              <FormControlLabel
                                value="True"
                                disabled={!isEditable}
                                control={<Radio />}
                                label={t('Yes')}
                              />
                              <FormControlLabel
                                value="False"
                                disabled={!isEditable}
                                control={<Radio />}
                                label={t('No')}
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                        {values.familyMemberPep === 'True' ? (
                          <>
                            <Grid item xs={12} mt={4}>
                              <TextField
                                name="familyMemberPepName"
                                label={t('kyc.FamilyMemberPepName')}
                                value={values.familyMemberPepName}
                                error={Boolean(
                                  touched.familyMemberPepName &&
                                    errors.familyMemberPepName
                                )}
                                fullWidth
                                helperText={
                                  touched.familyMemberPepName &&
                                  errors.familyMemberPepName
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={!isEditable}
                              />
                            </Grid>

                            <Grid item xs={12} mt={4}>
                              <TextField
                                name="familyMemberPepSocialSecurityNumber"
                                label={t(
                                  'kyc.FamilyMemberPepSocialSecurityNumber'
                                )}
                                value={
                                  values.familyMemberPepSocialSecurityNumber
                                }
                                error={Boolean(
                                  touched.familyMemberPepSocialSecurityNumber &&
                                    !isSSNValid(
                                      values.familyMemberPepSocialSecurityNumber
                                    )
                                )}
                                fullWidth
                                helperText={
                                  touched.familyMemberPepSocialSecurityNumber &&
                                  errors.familyMemberPepSocialSecurityNumber
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={!isEditable}
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <TextField
                                name="familyMemberFunctionAsPep"
                                label={t('kyc.FunctionAsPep')}
                                value={values.familyMemberFunctionAsPep}
                                error={Boolean(
                                  touched.familyMemberFunctionAsPep &&
                                    errors.familyMemberFunctionAsPep
                                )}
                                fullWidth
                                helperText={
                                  touched.familyMemberFunctionAsPep &&
                                  errors.familyMemberFunctionAsPep
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={!isEditable}
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <TextField
                                name="connectionToCustomer"
                                label={t('kyc.ConnectionToCustomer')}
                                value={values.connectionToCustomer}
                                error={Boolean(
                                  touched.connectionToCustomer &&
                                    errors.connectionToCustomer
                                )}
                                fullWidth
                                helperText={
                                  touched.connectionToCustomer &&
                                  errors.connectionToCustomer
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={!isEditable}
                              />
                            </Grid>
                            <Grid item xs={12} md={8} mt={4}>
                              <Typography>{t('FunctionCeased')}</Typography>
                              <DatePicker
                                format="dd-MM-yyyy"
                                value={
                                  values.familyMemberFunctionCeased != null
                                    ? new Date(
                                        values.familyMemberFunctionCeased
                                      )
                                    : null
                                }
                                onChange={(val) =>
                                  setFieldValue(
                                    'familyMemberFunctionCeased',
                                    val
                                  )
                                }
                                disabled={!isEditable}
                              />
                            </Grid>
                          </>
                        ) : null}

                        <Grid item xs={12} mt={4}>
                          <FormControl component="fieldset" variant="standard">
                            <FormLabel component="legend">
                              {t('kyc.MoneyOrigin')}
                            </FormLabel>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={values.moneyOriginSalary}
                                    onChange={handleChange}
                                    disabled={!isEditable}
                                    name="moneyOriginSalary"
                                  />
                                }
                                label={t('kyc.moneyOrigin.Salary')}
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={values.moneyOriginPension}
                                    onChange={handleChange}
                                    disabled={!isEditable}
                                    name="moneyOriginPension"
                                  />
                                }
                                label={t('kyc.moneyOrigin.Pension')}
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={values.moneyOriginSavings}
                                    onChange={handleChange}
                                    disabled={!isEditable}
                                    name="moneyOriginSavings"
                                  />
                                }
                                label={t('kyc.moneyOrigin.Savings')}
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      values.moneyOriginReturnOnInvestments
                                    }
                                    onChange={handleChange}
                                    disabled={!isEditable}
                                    name="moneyOriginReturnOnInvestments"
                                  />
                                }
                                label={t('kyc.moneyOrigin.ReturnOfInvestments')}
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={values.moneyOriginInsurance}
                                    onChange={handleChange}
                                    disabled={!isEditable}
                                    name="moneyOriginInsurance"
                                  />
                                }
                                label={t('kyc.moneyOrigin.Insurance')}
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={values.moneyOriginRealEstateSales}
                                    onChange={handleChange}
                                    disabled={!isEditable}
                                    name="moneyOriginRealEstateSales"
                                  />
                                }
                                label={t('kyc.moneyOrigin.RealEstateSales')}
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={values.moneyOriginCompanySales}
                                    onChange={handleChange}
                                    disabled={!isEditable}
                                    name="moneyOriginCompanySales"
                                  />
                                }
                                label={t('kyc.moneyOrigin.CompanySales')}
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={values.moneyOriginInherited}
                                    onChange={handleChange}
                                    disabled={!isEditable}
                                    name="moneyOriginInherited"
                                  />
                                }
                                label={t('kyc.moneyOrigin.Inherited')}
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={values.moneyOriginOther}
                                    onChange={handleChange}
                                    disabled={!isEditable}
                                    name="moneyOriginOther"
                                  />
                                }
                                label={t('kyc.moneyOrigin.Other')}
                              />
                            </FormGroup>
                          </FormControl>
                          {values.moneyOriginOther === true ? (
                            <>
                              <TextField
                                name="moneyOriginOtherSpecified"
                                label={t('kyc.PleaseSpecify')}
                                value={values.moneyOriginOtherSpecified || ''}
                                error={Boolean(
                                  touched.moneyOriginOtherSpecified &&
                                    errors.moneyOriginOtherSpecified
                                )}
                                fullWidth
                                helperText={
                                  touched.moneyOriginOtherSpecified &&
                                  errors.moneyOriginOtherSpecified
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={!isEditable}
                              />
                            </>
                          ) : null}
                        </Grid>

                        <Grid item xs={12} mt={4}>
                          <FormControl component="fieldset" variant="standard">
                            <FormLabel component="legend">
                              {t('kyc.DepositsFrom')}
                            </FormLabel>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      values.depositsFromSvenskBankOrAnother
                                    }
                                    onChange={handleChange}
                                    disabled={!isEditable}
                                    name="depositsFromSvenskBankOrAnother"
                                  />
                                }
                                label={t('kyc.depositChoices.SwedishBank')}
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      values.depositsFromForeignBankOrOtherWithinEss
                                    }
                                    onChange={handleChange}
                                    disabled={!isEditable}
                                    name="depositsFromForeignBankOrOtherWithinEss"
                                  />
                                }
                                label={t('kyc.depositChoices.ForeignBankESS')}
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      values.depositsFromForeignBankOrOtherOutsideEss
                                    }
                                    onChange={handleChange}
                                    disabled={!isEditable}
                                    name="depositsFromForeignBankOrOtherOutsideEss"
                                  />
                                }
                                label={t(
                                  'kyc.depositChoices.ForeignBankNonESS'
                                )}
                              />
                            </FormGroup>
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} mt={4}>
                          <FormControl component="fieldset" variant="standard">
                            <FormLabel component="legend">
                              {t('kyc.InvestReason')}
                            </FormLabel>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      values.mainReasonForInvestmentCapitalInvestment
                                    }
                                    onChange={handleChange}
                                    disabled={!isEditable}
                                    name="mainReasonForInvestmentCapitalInvestment"
                                  />
                                }
                                label={t('kyc.investChoices.CapitalInvestment')}
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      values.mainReasonForInvestmentPension
                                    }
                                    onChange={handleChange}
                                    disabled={!isEditable}
                                    name="mainReasonForInvestmentPension"
                                  />
                                }
                                label={t('kyc.investChoices.Pension')}
                              />
                            </FormGroup>
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} mt={4}>
                          <FormControl component="fieldset">
                            <FormLabel component="legend">
                              {t('kyc.AverageExpectedDeposits')}
                            </FormLabel>
                            <RadioGroup
                              aria-label="averageExpectedDeposit"
                              name="averageExpectedDeposit"
                              value={values.averageExpectedDeposit}
                              onChange={handleChange}
                            >
                              <FormControlLabel
                                value="1"
                                disabled={!isEditable}
                                control={<Radio />}
                                label={t(
                                  'kyc.averageExpectedDepositsChocies.Option1'
                                )}
                              />
                              <FormControlLabel
                                value="2"
                                disabled={!isEditable}
                                control={<Radio />}
                                label={t(
                                  'kyc.averageExpectedDepositsChocies.Option2'
                                )}
                              />
                              <FormControlLabel
                                value="3"
                                disabled={!isEditable}
                                control={<Radio />}
                                label={t(
                                  'kyc.averageExpectedDepositsChocies.Option3'
                                )}
                              />
                              <FormControlLabel
                                value="4"
                                disabled={!isEditable}
                                control={<Radio />}
                                label={t(
                                  'kyc.averageExpectedDepositsChocies.Option4'
                                )}
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} mt={4}>
                          <FormControl component="fieldset">
                            <FormLabel component="legend">
                              {t('kyc.DepositFrequency')}
                            </FormLabel>
                            <RadioGroup
                              aria-label="depositFrequency"
                              name="depositFrequency"
                              value={values.depositFrequency}
                              onChange={handleChange}
                            >
                              <FormControlLabel
                                value="1"
                                disabled={!isEditable}
                                control={<Radio />}
                                label={t('kyc.depositFrequencyChocies.Option1')}
                              />
                              <FormControlLabel
                                value="2"
                                disabled={!isEditable}
                                control={<Radio />}
                                label={t('kyc.depositFrequencyChocies.Option2')}
                              />
                              <FormControlLabel
                                value="3"
                                disabled={!isEditable}
                                control={<Radio />}
                                label={t('kyc.depositFrequencyChocies.Option3')}
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} mt={4}>
                          {activeUserCanSign ? (
                            <>
                              {isKycEnabled &&
                              !isEditable &&
                              !IsToDo /* Activate when KYC should be editable when confirmed already*/ ? (
                                <>
                                  {/* Edit */}
                                  {/* <Button
                                    onClick={() => handleEdit(true)}
                                    variant="outlined"
                                    color="primary"
                                  >
                                    {t('Edit')}
                                  </Button> */}
                                </>
                              ) : isEditable || IsToDo ? (
                                <>
                                  {/* Confirm / Cancel (edit) */}
                                  {isEdit ? (
                                    <Grid container spacing={1}>
                                      <Grid item>
                                        <FormDialogConfirm
                                          handleIsFetch={setIsFetched}
                                          handlekycDataChanged={setDataChanged}
                                          dataChangd={dataChanged}
                                          isEdit={isEdit}
                                          handleIsEdit={handleEdit}
                                        ></FormDialogConfirm>
                                      </Grid>
                                      <Grid item>
                                        <Button
                                          onClick={() => handleEdit(false)}
                                          color="primary"
                                          variant="contained"
                                        >
                                          {t('Cancel')}
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  ) : (
                                    <>
                                      {/* Confirm / Reject */}
                                      <Grid container spacing={1}>
                                        <Grid item>
                                          <FormDialogConfirm
                                            handleIsFetch={setIsFetched}
                                            handlekycDataChanged={
                                              setDataChanged
                                            }
                                            dataChangd={dataChanged}
                                          ></FormDialogConfirm>
                                        </Grid>
                                        <Grid item>
                                          {/* Edit */}
                                          <Button
                                            onClick={() => handleEdit(true)}
                                            variant="outlined"
                                            color="primary"
                                          >
                                            {t('Edit')}
                                          </Button>
                                        </Grid>
                                        <Grid item>
                                          <FormDialogReject
                                            handleIsFetch={setIsFetched}
                                            handlekycDataChanged={
                                              setDataChanged
                                            }
                                            dataChangd={dataChanged}
                                          ></FormDialogReject>
                                        </Grid>
                                      </Grid>
                                    </>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : null}
                        </Grid>
                      </Grid>
                    </form>
                  </CardContent>
                </Card>
              ) : (
                <>
                  <Typography>{t('kyc.NoInfo')}</Typography>
                </>
              )
            }
          </Formik>
        ) : (
          <div>
            <Box display="flex" justifyContent="center" my={6}>
              <CircularProgress />
            </Box>
            <Box display="flex" justifyContent="center" my={6}>
              <Typography> {t('LongLoading')}</Typography>
            </Box>
          </div>
        )}
      </>
    );
  }

  return (
    <React.Fragment>
      <BasicForm />
    </React.Fragment>
  );

  function isSSNValid(ssn: string | null): boolean {
    if (!ssn) return true;

    if (ssn.length !== 12) {
      return false;
    }
    ssn = ssn.replace(/\D/g, '');
    if (ssn.length !== 12) {
      return false;
    }

    ssn = ssn.substring(2, 12);

    let ssnArray = ssn.split('').reverse();

    if (ssnArray.length !== 10) {
      return false;
    }

    var sum = ssnArray
      .map((n) => {
        return Number(n);
      })
      .reduce((previous, current, index) => {
        // multiply every other number with two
        if (index % 2) current *= 2;
        // if larger than 10 get sum of individual digits (also n-9)
        if (current > 9) current -= 9;

        return previous + current;
      });

    // sum must be divisible by 10
    return 0 === sum % 10;
  }
}

export default KycForm;
