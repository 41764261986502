import { Grid } from '@mui/material';
import InvestmentLayout from '../../components/layout/InvestmentLayout';
import PortfolioReports from '../../components/widgets/PortfolioReports';

export default function ReportsPage() {
  return (
    <>
      <InvestmentLayout hidePortfolioPicker={true}>
        <Grid
          container
          rowSpacing={0}
          sx={{ width: '100%', maxWidth: '96em' }}
          justifyContent="flex-start"
        >
          <Grid item>
            <PortfolioReports />
          </Grid>
        </Grid>
      </InvestmentLayout>
    </>
  );
}
