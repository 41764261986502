import InvestmentLayout from '../../components/layout/InvestmentLayout';
import PostionsWidget from '../../components/widgets/PostionsWidget';

export default function Positions() {
  return (
    <>
      <InvestmentLayout>
        <PostionsWidget />
      </InvestmentLayout>
    </>
  );
}
