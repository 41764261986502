import { Typography } from '@mui/material';
import CurrencyFormat from '../../util/currency-format/currency-format';

interface StringFieldProps {
  value?: string;
  className?: string;
}

export default function StringField(props: StringFieldProps) {
  return (
    <>
      {props.value == null ? (
        ''
      ) : (
          <Typography variant="body1" className={props.className}>
            {props.value}
          </Typography>
      )}
    </>
  );
}
