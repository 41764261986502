import { useTranslation } from 'react-i18next';
import { NewsWidget } from './InFront/NewsWidget';
import { useEffect, useState } from 'react';
import { Typography } from '@mui/material';

export default function CoeliNews() {
  const [feed, setFeed] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    setFeed(18031);
  }, []);

  return (
    <>
      <Typography variant="h4">Marknadens toppnyheter</Typography>
      <Typography variant="body2">
        I samarbete med Nyhetsbyrån Direkt
      </Typography>
      {feed != 0 ? (
        <NewsWidget feeds={[feed]} id="coeliNewsWidget18"></NewsWidget>
      ) : (
        <></>
      )}
    </>
  );
}
