import { Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface TabItems {
  menuItems: LinkTabProps[];
}

class LinkTabProps {
  label: string = '';
  to: string = '';
}

function LinkTab(props: LinkTabProps) {
  const navigate = useNavigate();

  return (
    <Tab
      component="a"
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        navigate(props.to);
      }}
      {...props}
    />
  );
}
const TabMeny = (props: TabItems) => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box
      className="tabmenu"
      sx={{
        maxWidth: {
          xs: 340,
          desktop450: 450,
          sm: 600,
          desktop675: 660,
          lg: 960,
          xl: 1280,
        },
      }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile={true}
        aria-label="scrollable auto tabs example"
        className="tabmenu-tabs"
      >
        {props.menuItems.map((i) => {
          return <LinkTab key={i.label} label={i.label} to={i.to} />;
        })}
      </Tabs>
    </Box>
  );
};

export default TabMeny;
