import { Box, Grid } from '@mui/material';
import InvestmentProgress from '../../components/widgets/InvestmentProgress';

import { Image } from 'mui-image';
import WebinarImage from '../../images/webinars.png';
import ProgressGraphHC from '../../components/widgets/ProgressGraphHC';

export default function Overview() {
  return (
    <Grid container>
      <Grid item xs={12} lg={6}>
        <Grid container>
          <Grid item xs={12}>
            <Box className="widget">
              <InvestmentProgress></InvestmentProgress>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className="widget">
              <ProgressGraphHC totalView={true} navBar={false} />
              </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Box className="board-image-box">
          <a
            href="https://coeli.se/filmbibliotek/"
            target="_blank"
            rel="noreferrer"
          >
            <Image src={WebinarImage} className="board-image" />
          </a>
        </Box>
      </Grid>
    </Grid>
  );
}
