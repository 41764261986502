import { Box, Grid } from '@mui/material';
import InvestmentLayout from '../../components/layout/InvestmentLayout';
import PortfolioOverview from '../../components/widgets/PortfolioOverview';

import { Image } from 'mui-image';
import NewsImage from '../../images/news.png';
import PortfolioDocuments from '../../components/widgets/PortfolioDocuments';

export default function DocumentsPage() {
  return (
    <>
      <InvestmentLayout>
        <Grid
          container
          rowSpacing={0}
          sx={{ width: '100%', maxWidth: '96em' }}
          justifyContent="flex-start"
        >
          <Grid item>
            <PortfolioDocuments />
          </Grid>
         
        </Grid>
      </InvestmentLayout>
    </>
  );
}
