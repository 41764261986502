import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useAppSelector } from '../../redux/hooks';
import { useTranslation } from 'react-i18next';

export interface IPieChartData {
  name: string;
  y: number;
  drilldown: string;
}

export interface IPieChartDrillDown {
  name: string;
  id: string;
  data?: IPieChartData[];
}

export interface IPieChartHCProps {
  data?: IPieChartData[];
  drillDown?: IPieChartDrillDown;
}

export default function PieChartHC(props: IPieChartHCProps) {
  const portfolios = useAppSelector((state) => state.portfolios);

  const [options, setOptions] = useState<Highcharts.Options>({
    colors: [
      '#de996f',
      '#92928f',
      '#876191',
      '#4073a5',
      '#58723f',
      '#af4a42',
      '#ebc2a9',
      '#bebebc',
      '#b89fbf',
      '#86abd0',
      '#9bb87e',
      '#d38f8a',
    ],
    accessibility: {
      enabled: false,
    },
    title: {
      text: '',
    },
    chart: {
      height: 300,
      type: 'pie',
      // ## Sort on piechart data load... ##
      // events: {
      //   load: (me: any, event: any) => {
      //     const that = me.target;
      //      if (that.series[0].data) {
      //        var data = that.series[0].data;
      //       var newData: IPieChartData[] = [];

      //       data.forEach((point: IPieChartData) => {
      //         newData.push({
      //           y: point.y,
      //           name: point.name
      //         })
      //       });

      //       newData.sort(function (a, b) {
      //         return a.y - b.y;
      //       });

      //       that.series[0].setData(newData);
      //      }
      //   }
      // }
    },
    series: [
      {
        name: 'Andelar i %',
        colorByPoint: true,
        data: props.data,
      },
    ],
    drilldown: props.drillDown,

    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '{point.percentage:.2f}%',
        },
        tooltip: {
          headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
          pointFormat:
            '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b><br/>',
        },
      },
    },
  } as Highcharts.Options);

  const users = useAppSelector((state) => state.users);
  const { t } = useTranslation();

  return (
    <Box>
      {portfolios ? (
        <HighchartsReact highcharts={Highcharts} options={options} />
      ) : (
        <>
          <Box display="flex" justifyContent="center" my={6}>
            <Typography>{t('MarketDataMissing')}</Typography>
          </Box>
        </>
      )}
    </Box>
  );
}
