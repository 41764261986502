import React, { useEffect, useState } from 'react';
import { Field, Formik, useFormikContext } from 'formik';
import { TextFormField } from '../formfields/TextFormField';
import { RadioGroupFormField } from '../formfields/RadioGroupFormField';
import {  
  InvestmentHorizon,
  investmentObjectivesDescription,
  investorCategory,
  investorProfilecompaniestatusCode,
  managementDecisionStructure,
  ToDoEnum,
  witchdoesntwant,
} from '../../Enums';

import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormGroup,
  Checkbox,
  Link,
} from '@mui/material';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { useTranslation } from 'react-i18next';

import InvestorProfileExperience from './InvestorProfileExperience';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useApiClient } from '../../hooks/apiClient';
import { IApproveData } from '../../models/approveData';
import { getActiveUser, getMainUser } from '../../redux/stateFuncs';
import { setToDoComplete } from '../../redux/slices/todo';
import { IInvestorProfileExperience } from '../../models/investorProfileExperience';

interface ICompanyInvestorProfile {
  managementDecisionStructureValues: number[];
  investmentObjectivesDescriptionValues: number[];
  assetTypeWhichValues: number[];
  acceptanceCanNegAffectReturnsRisk?: boolean;
  advisorRecomRiskLevelForCustomer?: number;
  annualCapitalDependency5to10y?: string;
  annualCapitalDependency1to3y?: string;
  annualCapitalDependency3to5y?: string;
  annualCapitalDependencyMore10y?: string;
  annualEarnings?: number;
  annualRevenue?: number;
  annualIncomeFromCapital?: number;
  annualTargetReturn?: number;
  assessedRiskDiscrepancyClientAdviser?: boolean;
  assetTypeWhich: number[];
  assetTypeCustomerDoesntWant?: boolean;
  ConfirmationDate?: Date;
  dependentOnCapitalInLongterm?: boolean;
  dependentOnCapitalInShortterm?: boolean;
  expectedChangeAssetsAndLiabilities?: boolean;
  whatAreExpectedChangeInAssetsAndLiabilities?: number;
  externalAssetsAlternativeFunds?: number;
  externalAssetsBondFunds?: number;
  externalAssetsCash?: number;
  externalAssetsEquityFunds?: number;
  externalAssetsMixtFundsOrDisk?: number;
  externalAssetsOtherInvestments?: number;
  externalAssetsPrivateEquity?: number;
  externalAssetsRealEstate?: number;
  externalAssetsEquity?: number;
  externalAssetsInterestFunds?: number;
  externalAssetsOwnedCompanies?: number;
  financialYear?: number;
  incomeEmploymentAndPensionMonthAfterTax?: number;
  investmentAlternativeToBankAccount?: boolean;
  investmentGoalsDescribe?: string;
  investmentHorizonCompany?: number;
  investmentHorizonDescribe?: string;
  investmentOppListedAndUnlisted?: boolean;
  investmentSummaryObjectivesHorizon?: string;
  investmentWithAllocationStrategy?: boolean;
  investorCategory?: number;
  monthlyCost?: number;
  name?: string;
  numberOfEmployees?: number;
  otherLoans?: number;
  ownEquity?: number;
  professionalInvestor?: boolean;
  professionalInvestorAssetUnderManagement?: string;
  realEstateLoans?: number;
  realEstateValue?: number;
  recommendationAmountAlterInvestHedge?: string;
  recommendationAmountListedEquityOrEquityFund?: string;
  recommendationAmountListUnlistInvestCase?: string;
  recommendationAmountPrivateEquityFund?: string;
  recommendationAmountRealEstateFund?: string;
  recommendationAmountInterestFunds?: string;
  riskAssessmentDiscrepancyDescription?: string;
  shareEquity?: string;
  shareEquityFunds?: string;
  shareHedgeFunds?: string;
  shareInterestFunds?: string;
  shareInterestInstruments?: string;
  sharePrivateEquityFunds?: string;
  shareRealEstateFunds?: string;
  shareUnlistedEquity?: string;
  summaryIncomeExpensesAndAssets?: string;
  summaryInvestmentPreferences?: string;
  summaryOfOverallManagementStrategy?: string;
  summaryRiskAssessment?: string;
  summarySituationOfProfessionalInvestors?: string;
  taxonomyInvestmentPreferences?: number;
  taxonomyPortfolio?: number;
  whatDoesRiskMeanForTheInvestor?: number;
  statecode?: number;
  statuscode?: number;
  id?: string;
  ownerName?: string;
  investorProfileExperience1?: IInvestorProfileExperience;
  investorProfileExperience2?: IInvestorProfileExperience;
  investorProfileCompanyId?: string;
  doYouHaveASustainabilityPreference?: boolean;
  sp_ParticularInterestSustainableInvestment?: number;
  sp_GreenhouseGasesEmission?: number;
  sp_CarbonFootprint?: number;
  sp_GreenhouseGasEmissionsCompany?: number;
  sp_FossilFuel?: number;
  sp_NonRenewableEnergy?: number;
  sp_EnergyConsumption?: number;
  sp_ReleaseIntoWater?: number;
  sp_HazardousWaste?: number;
  sp_ViolationGlobalAgreement?: number;
  sp_DeficiencyGlobalAgreement?: number;
  sp_ControversialWeapons?: number;
  sp_GenderPayGap?: number;
  sp_GenderDistribution?: number;
  sp_InterestProducts?: number;

  investRisk?: string;
  investriskAction?: string;
  specifyWhy?: string;
  allocationsAssetsHighLiquidityDec?: number;
  allocationsAssetsLimitedLiquidityDec?: number;
  allowedDriftOnOverallRecommendedAllocation?: number;
}

let initialValues: ICompanyInvestorProfile = {
  managementDecisionStructureValues: [],
  investmentObjectivesDescriptionValues: [],
  assetTypeWhichValues: [],
  acceptanceCanNegAffectReturnsRisk: undefined,
  advisorRecomRiskLevelForCustomer: undefined,
  annualCapitalDependency5to10y: undefined,
  annualCapitalDependency1to3y: undefined,
  annualCapitalDependency3to5y: undefined,
  annualCapitalDependencyMore10y: undefined,
  annualIncomeFromCapital: undefined,
  annualTargetReturn: undefined,
  assessedRiskDiscrepancyClientAdviser: undefined,
  assetTypeWhich: [],
  assetTypeCustomerDoesntWant: undefined,
  ConfirmationDate: undefined,
  dependentOnCapitalInLongterm: undefined,
  dependentOnCapitalInShortterm: undefined,
  expectedChangeAssetsAndLiabilities: undefined,
  whatAreExpectedChangeInAssetsAndLiabilities: undefined,
  externalAssetsAlternativeFunds: undefined,
  externalAssetsBondFunds: undefined,
  externalAssetsCash: undefined,
  externalAssetsEquityFunds: undefined,
  externalAssetsMixtFundsOrDisk: undefined,
  externalAssetsOtherInvestments: undefined,
  externalAssetsPrivateEquity: undefined,
  externalAssetsRealEstate: undefined,
  externalAssetsEquity: undefined,
  externalAssetsInterestFunds: undefined,
  externalAssetsOwnedCompanies: undefined,
  financialYear: undefined,
  incomeEmploymentAndPensionMonthAfterTax: undefined,
  investmentAlternativeToBankAccount: undefined,
  investmentGoalsDescribe: undefined,
  investmentHorizonCompany: undefined,
  investmentHorizonDescribe: undefined,
  investmentOppListedAndUnlisted: undefined,
  investmentSummaryObjectivesHorizon: undefined,
  investmentWithAllocationStrategy: undefined,
  investorCategory: undefined,
  monthlyCost: undefined,
  name: undefined,
  otherLoans: undefined,
  ownEquity: undefined,
  professionalInvestor: undefined,
  professionalInvestorAssetUnderManagement: undefined,
  realEstateLoans: undefined,
  realEstateValue: undefined,
  recommendationAmountAlterInvestHedge: undefined,
  recommendationAmountListedEquityOrEquityFund: undefined,
  recommendationAmountListUnlistInvestCase: undefined,
  recommendationAmountPrivateEquityFund: undefined,
  recommendationAmountRealEstateFund: undefined,
  recommendationAmountInterestFunds: undefined,
  riskAssessmentDiscrepancyDescription: undefined,
  shareEquity: undefined,
  shareEquityFunds: undefined,
  shareHedgeFunds: undefined,
  shareInterestFunds: undefined,
  shareInterestInstruments: undefined,
  sharePrivateEquityFunds: undefined,
  shareRealEstateFunds: undefined,
  shareUnlistedEquity: undefined,
  summaryIncomeExpensesAndAssets: undefined,
  summaryInvestmentPreferences: undefined,
  summaryOfOverallManagementStrategy: undefined,
  summaryRiskAssessment: undefined,
  summarySituationOfProfessionalInvestors: undefined,
  taxonomyInvestmentPreferences: undefined,
  taxonomyPortfolio: undefined,
  whatDoesRiskMeanForTheInvestor: undefined,
  statecode: undefined,
  statuscode: undefined,
  id: undefined,
  ownerName: undefined,
  investorProfileExperience1: undefined,
  investorProfileExperience2: undefined,
  investorProfileCompanyId: undefined,
  doYouHaveASustainabilityPreference: undefined,
  sp_ParticularInterestSustainableInvestment: undefined,
  sp_GreenhouseGasesEmission: undefined,
  sp_CarbonFootprint: undefined,
  sp_GreenhouseGasEmissionsCompany: undefined,
  sp_FossilFuel: undefined,
  sp_NonRenewableEnergy: undefined,
  sp_EnergyConsumption: undefined,
  sp_ReleaseIntoWater: undefined,
  sp_HazardousWaste: undefined,
  sp_ViolationGlobalAgreement: undefined,
  sp_DeficiencyGlobalAgreement: undefined,
  sp_ControversialWeapons: undefined,
  sp_GenderPayGap: undefined,
  sp_GenderDistribution: undefined,
  sp_InterestProducts: undefined,

  investRisk: undefined,
  investriskAction: undefined,
  specifyWhy: '',
  allocationsAssetsHighLiquidityDec: undefined,
  allocationsAssetsLimitedLiquidityDec: undefined,
  allowedDriftOnOverallRecommendedAllocation: undefined,
};

function InvestmentProfileCompanyForm(props: any) {
  const IsToDo = props.isToDo;

  const dispatch = useAppDispatch();
  const users = useAppSelector((state) => state.users);
  const api = useApiClient();
  const { t } = useTranslation();

  //confirm
  function FormDialogConfirm(props: any) {
    const [open, setOpen] = React.useState(false);

    const { values } = useFormikContext();

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = (isSubmit: boolean) => {
      if (isSubmit) {
        props.handleIsFetch(false);

        const activeCompanyId = getActiveUser(users)?.id;
        const mainUserId = getMainUser(users)?.id;

        const investorProfileCompanyData = values as ICompanyInvestorProfile;

        const approveData: IApproveData = {
          approverId: mainUserId,
          ownerId: activeCompanyId,
          entityId: investorProfileCompanyData.investorProfileCompanyId,
          ownerEntityLogicalName: 'account',
        };
        api
          .post('api/investorprofilecompany/confirm', { ...approveData })
          .then((res) => {
            props.handleDataChanged(!props.dataChangd);
            if (IsToDo) {
              dispatch(setToDoComplete(ToDoEnum.InvestorProfileCompany));
            }
          });
      }

      setOpen(false);
    };

    return (
      <>
        <Button variant="contained" color="primary" onClick={handleClickOpen}>
          {t('Confirm')}
        </Button>
        <Dialog
          maxWidth="sm"
          fullWidth={true}
          open={open}
          onClose={handleClose}
        >
          <DialogTitle> {t('LegalNote')}</DialogTitle>
          <DialogContentText ml={1}>
            {t('investorProfile.dialog.ConfirmCompanyText')}
            <br />
            <Link
              href="https://coeli.se/legal-information-coeli-wealth-management-ab/"
              target={'_blank'}
            >
              https://coeli.se/legal-information-coeli-wealth-management-ab/
            </Link>
          </DialogContentText>
          <DialogActions>
            <Button onClick={() => handleClose(true)}>{t('Confirm')}</Button>
            <Button onClick={() => handleClose(false)}>{t('Cancel')}</Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  //reject
  function FormDialogReject(props: any) {
    const [open, setOpen] = React.useState(false);
    const [rejectComment, setRejectComment] = React.useState<string>('');

    const { values } = useFormikContext();

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = (isSubmit: boolean) => {
      props.handleIsFetch(false);
      if (isSubmit) {
        const mainUserId = getMainUser(users)?.id;
        const activeCompanyId = getActiveUser(users)?.id;

        const investorProfileCompanyData = values as ICompanyInvestorProfile;

        const approveData: IApproveData = {
          approverId: mainUserId,
          ownerId: activeCompanyId,
          entityId: investorProfileCompanyData.investorProfileCompanyId,
          rejectComment: rejectComment,
          ownerEntityLogicalName: 'account',
        };
        api
          .post('api/investorprofilecompany/reject', { ...approveData })
          .then((res) => {
            props.handleDataChanged(!props.dataChangd);
            if (IsToDo) {
              dispatch(setToDoComplete(ToDoEnum.InvestorProfileCompany));
            }
          });
      }
      setOpen(false);
    };

    return (
      <>
        <Button variant="contained" color="primary" onClick={handleClickOpen}>
          {t('Reject')}
        </Button>
        <Dialog
          maxWidth="sm"
          fullWidth={true}
          open={open}
          onClose={handleClose}
        >
          <DialogTitle>{t('LegalNote')}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t('investorProfile.dialog.RejectCompanyText')}
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="comment"
              label="Rejection reason"
              fullWidth
              multiline
              minRows={4}
              variant="outlined"
              required={true}
              value={rejectComment}
              onChange={(e) => setRejectComment(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button
              disabled={rejectComment === '' ? true : false}
              onClick={() => handleClose(true)}
            >
              Reject
            </Button>
            <Button onClick={() => handleClose(false)}>{t('Cancel')}</Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  function BaseForm(props: any) {
    const activeUserCanSign = getActiveUser(users)?.canSign;

    const [isFetched, setIsFetched] = useState(false);
    const [dataChanged, setDataChanged] = useState(false);
    const isDisabled = true;
    const [isConfirmed, setIsConfirmed] = useState(true);

    const yesNoOptions = [
      { label: t('Yes'), value: true },
      { label: t('No'), value: false },
    ];

    const investorCategoryOptions = [
      { label: t('investorProfile.NonProfessional'), value: 963440000 },
      { label: t('investorProfile.Professional'), value: 963440001 },
      { label: t('investorProfile.ComparableCounterparty'), value: 963440002 },
      {
        label: t('investorProfile.SimplifiedInvestorProfile'),
        value: 963440003,
      },
    ];

    const financialYearOptions = [
      { label: '1 Jan - 31 Dec', value: 963440000 },
      { label: '1 May - 30 April', value: 963440001 },
      { label: '1 Sept - 31 Aug', value: 963440002 },
    ];

    const investmentHorizonCompanyOptions = [
      {
        value: 963440000,
        label: t('investorProfile.investmentHorizon.Option1'),
      },
      {
        value: 963440001,
        label: t('investorProfile.investmentHorizon.Option2'),
      },
      {
        value: 963440002,
        label: t('investorProfile.investmentHorizon.Option3'),
      },
      {
        value: 963440003,
        label: t('investorProfile.investmentHorizon.Option4'),
      },
      {
        value: 963440004,
        label: t('investorProfile.investmentHorizon.Option5'),
      },
      { label: t('Other'), value: 963440005 },
    ];

    const annualTargetReturnOptions = [
      { value: 963440000, label: '1 - 3 %' },
      { value: 963440001, label: '3 - 5 %' },
      { value: 963440002, label: '5 - 8 %' },
      { value: 963440003, label: '8 - 12 %' },
      { value: 963440004, label: t('investorProfile.MoreThan12%') },
    ];

    const advisorsOverallRecommendationOnRiskLevelOptions = [
      { value: 963440000, label: t('Low') },
      { value: 963440001, label: t('Medium-Low') },
      { value: 963440002, label: t('Medium') },
      { value: 963440003, label: t('Medium-High') },
      { value: 963440004, label: t('High') },
      { value: 963440005, label: t('Bankaccount') },
      { value: 963440006, label: t('Speculation') },
    ];

    const investmentPrefrencesPercentages = [
      { value: 963440010, label: '10 %' },
      { value: 963440020, label: '20 %' },
      { value: 963440030, label: '30 %' },
      { value: 963440040, label: '40 %' },
      { value: 963440050, label: '50 %' },
      { value: 963440060, label: '60 %' },
      { value: 963440070, label: '70 %' },
      { value: 963440080, label: '80 %' },
      { value: 963440090, label: '90 %' },
      { value: 963440100, label: '100 %' },
      { value: 963440000, label: t('investorProfile.NoPreferences') },
    ];

    const investRisk = [
      {
        value: 963440000,
        label: t('investorProfile.investRiskOptions.Option1'),
      },
      {
        value: 963440001,
        label: t('investorProfile.investRiskOptions.Option2'),
      },
      {
        value: 963440002,
        label: t('investorProfile.investRiskOptions.Option3'),
      },
      {
        value: 963440003,
        label: t('investorProfile.investRiskOptions.Option4'),
      },
      {
        value: 963440004,
        label: t('investorProfile.investRiskOptions.Option5'),
      },
      {
        value: 963440005,
        label: t('investorProfile.investRiskOptions.Option6'),
      },
      {
        value: 963440006,
        label: t('investorProfile.investRiskOptions.Option7'),
      },
    ];

    const investRiskAction = [
      {
        value: 963440000,
        label: t('investorProfile.investRiskActionOptions.Option1'),
      },
      {
        value: 963440001,
        label: t('investorProfile.investRiskActionOptions.Option2'),
      },
      {
        value: 963440002,
        label: t('investorProfile.investRiskActionOptions.Option3'),
      },
      {
        value: 963440003,
        label: t('investorProfile.investRiskActionOptions.Option4'),
      },
      {
        value: 963440004,
        label: t('investorProfile.investRiskActionOptions.Option5'),
      },
      {
        value: 963440005,
        label: t('investorProfile.investRiskActionOptions.Option6'),
      },
      {
        value: 963440006,
        label: t('investorProfile.investRiskActionOptions.Option7'),
      },
    ];

    const handleSubmit = async () => {};

    useEffect(() => {
      const activeCompanyId = getActiveUser(users)?.id;

      setIsFetched(false);
      api.get(`/api/investorprofilecompany/${activeCompanyId}`).then((res) => {
        let item: ICompanyInvestorProfile | undefined = res.data;
        if (item) {
          initialValues = { ...(item as ICompanyInvestorProfile) };

          if (item.statuscode === investorProfilecompaniestatusCode.Confirmed) {
            setIsConfirmed(true);
          } else {
            if (activeUserCanSign) {
              setIsConfirmed(false);
            } else {
              setIsConfirmed(true);
            }
          }
        } else {
          initialValues = {} as ICompanyInvestorProfile;
          setIsConfirmed(true);
        }
        setTimeout(() => {
          setIsFetched(true);
        }, 500);
      });
    }, [users, activeUserCanSign]);

    return (
      <div>
        {isFetched ? (
          <Formik initialValues={{ ...initialValues }} onSubmit={handleSubmit}>
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              handleReset,
              setFieldValue,
              isSubmitting,
              touched,
              values,
              status,
            }) => (
              <Card>
                <CardContent>
                  {isSubmitting ? (
                    <Box display="flex" justifyContent="center" my={6}>
                      <CircularProgress />
                    </Box>
                  ) : values.id ? (
                    <form>
                      <Typography variant="h6" gutterBottom>
                        {t('menu.InvestorProfile')}
                      </Typography>
                      <Typography display="inline">
                        {t('ResponsibleCoeli')}
                        {': '}
                      </Typography>
                      <Typography
                        variant="body2"
                        display="inline"
                        fontWeight={'bold'}
                      >
                        {values.ownerName}
                      </Typography>
                      {isConfirmed ? null : (
                        <Grid container spacing={1} mt={2}>
                          <Grid item>
                            <FormDialogConfirm
                              handleDataChanged={setDataChanged}
                              handleIsFetch={setIsFetched}
                            ></FormDialogConfirm>
                          </Grid>
                          <Grid item>
                            <FormDialogReject
                              handleDataChanged={setDataChanged}
                              handleIsFetch={setIsFetched}
                            ></FormDialogReject>
                          </Grid>
                        </Grid>
                      )}
                      <Grid container spacing={6} mt={2}>
                        <Grid item xs={12} mt={4}>
                          <Field
                            name="investorCategory"
                            component={RadioGroupFormField}
                            options={investorCategoryOptions}
                            isDisabled={isDisabled}
                            label={t('investorProfile.InvestorCategory')}
                          />
                        </Grid>
                        <Grid item xs={12} mt={4}>
                          <Field
                            name="professionalInvestor"
                            component={RadioGroupFormField}
                            options={yesNoOptions}
                            isDisabled={isDisabled}
                            label={t('investorProfile.Professional')}
                          />
                        </Grid>
                        {values.professionalInvestor === true ? (
                          <>
                            <Grid item xs={12} mt={4}>
                              <Field
                                name="professionalInvestorAssetUnderManagement"
                                label={
                                  t('investorProfile.Professional') +
                                  ' - ' +
                                  t('investorProfile.AssetsUnderManagement')
                                }
                                variant="outlined"
                                component={TextFormField}
                                disabled={isDisabled}
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <FormControl
                                sx={{ m: 2 }}
                                component="fieldset"
                                variant="standard"
                              >
                                <FormLabel component="legend">
                                  {t(
                                    'investorProfile.ManagementDecisionStructure'
                                  )}
                                </FormLabel>
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={values.managementDecisionStructureValues?.some(
                                          (p) =>
                                            p ===
                                            managementDecisionStructure.OwnDecisions
                                        )}
                                        onChange={handleChange}
                                        name="operatingIncome"
                                      />
                                    }
                                    disabled={isDisabled}
                                    label={t('investorProfile.OwnDecisions')}
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={values.managementDecisionStructureValues?.some(
                                          (p) =>
                                            p ===
                                            managementDecisionStructure.Discretionary
                                        )}
                                        onChange={handleChange}
                                        disabled={isDisabled}
                                        name="returnOnCapitalInvestments"
                                      />
                                    }
                                    label={t(
                                      'investorProfile.ManagementDecisionStructure'
                                    )}
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={values.managementDecisionStructureValues?.some(
                                          (p) =>
                                            p ===
                                            managementDecisionStructure.PresentedInvestmentCaseOwnDecisions
                                        )}
                                        onChange={handleChange}
                                        disabled={isDisabled}
                                        name="realEstateSales"
                                      />
                                    }
                                    label={t(
                                      'investorProfile.PresentedInvCase'
                                    )}
                                  />
                                </FormGroup>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Field
                                name="shareEquity"
                                label={
                                  t('investorProfile.Share') +
                                  ' - ' +
                                  t('investorProfile.Equity')
                                }
                                variant="outlined"
                                component={TextFormField}
                                disabled={isDisabled}
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Field
                                name="shareEquityFunds"
                                label={
                                  t('investorProfile.Share') +
                                  ' - ' +
                                  t('investorProfile.EquityFunds')
                                }
                                variant="outlined"
                                component={TextFormField}
                                disabled={isDisabled}
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Field
                                name="shareInterestFunds"
                                label={
                                  t('investorProfile.Share') +
                                  ' - ' +
                                  t('investorProfile.InterestFunds')
                                }
                                variant="outlined"
                                component={TextFormField}
                                disabled={isDisabled}
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Field
                                name="shareInterestInstruments"
                                label={
                                  t('investorProfile.Share') +
                                  ' - ' +
                                  t('investorProfile.InterestInstruments')
                                }
                                variant="outlined"
                                component={TextFormField}
                                disabled={isDisabled}
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Field
                                name="shareHedgeFunds"
                                label={
                                  t('investorProfile.Share') +
                                  ' - ' +
                                  t('investorProfile.HedgeFunds')
                                }
                                variant="outlined"
                                component={TextFormField}
                                disabled={isDisabled}
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Field
                                name="sharePrivateEquityFunds"
                                label={
                                  t('investorProfile.Share') +
                                  ' - ' +
                                  t('investorProfile.PrivateEquityFunds')
                                }
                                variant="outlined"
                                component={TextFormField}
                                disabled={isDisabled}
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Field
                                name="shareRealEstateFunds"
                                label={
                                  t('investorProfile.Share') +
                                  ' - ' +
                                  t('investorProfile.RealEstateFunds')
                                }
                                variant="outlined"
                                component={TextFormField}
                                disabled={isDisabled}
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Field
                                name="shareUnlistedEquity"
                                label={
                                  t('investorProfile.Share') +
                                  ' - ' +
                                  t('investorProfile.UnlistedEquities')
                                }
                                variant="outlined"
                                component={TextFormField}
                                disabled={isDisabled}
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <TextField
                                name="summarySituationOfProfessionalInvestors"
                                label={t(
                                  'investorProfile.SummarySituationOfProfessionalInvestors'
                                )}
                                value={
                                  values.summarySituationOfProfessionalInvestors
                                }
                                fullWidth
                                multiline
                                minRows={4}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={isDisabled}
                              />
                            </Grid>
                          </>
                        ) : null}
                        {values.investorCategory !==
                        investorCategory.SimplifiedInvestorProfile ? (
                          <>
                            <Grid item xs={12} mt={4}>
                              <Typography variant="h6" gutterBottom>
                                {t('investorProfile.EconomicalOverview')}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Field
                                name="numberOfEmployees"
                                label={t('investorProfile.NumberOfEmployees')}
                                variant="outlined"
                                component={TextFormField}
                                disabled={isDisabled}
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Field
                                name="annualRevenue"
                                label={t('investorProfile.AnnualRevenue')}
                                variant="outlined"
                                component={TextFormField}
                                disabled={isDisabled}
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Field
                                name="annualEarnings"
                                label={t('investorProfile.AnnualEarnings')}
                                variant="outlined"
                                component={TextFormField}
                                disabled={isDisabled}
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Field
                                name="ownEquity"
                                label={t('investorProfile.OwnEquity')}
                                variant="outlined"
                                component={TextFormField}
                                disabled={isDisabled}
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Field
                                name="financialYear"
                                component={RadioGroupFormField}
                                options={financialYearOptions}
                                isDisabled={isDisabled}
                                label={t('investorProfile.FinancialYear')}
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Field
                                name="externalAssetsCash"
                                label={t('investorProfile.ExternalAssetsCash')}
                                variant="outlined"
                                component={TextFormField}
                                disabled={isDisabled}
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Field
                                name="externalAssetsEquity"
                                label={t(
                                  'investorProfile.ExternalAssetsEquity'
                                )}
                                variant="outlined"
                                component={TextFormField}
                                disabled={isDisabled}
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Field
                                name="externalAssetsInterestFunds"
                                label={t(
                                  'investorProfile.ExternalAssetsInterestFunds'
                                )}
                                variant="outlined"
                                component={TextFormField}
                                disabled={isDisabled}
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Field
                                name="externalAssetsBondFunds"
                                label={t('investorProfile.ExternalAssetsBonds')}
                                variant="outlined"
                                component={TextFormField}
                                disabled={isDisabled}
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Field
                                name="externalAssetsEquityFunds"
                                label={t(
                                  'investorProfile.ExternalAssetsEquityFunds'
                                )}
                                variant="outlined"
                                component={TextFormField}
                                disabled={isDisabled}
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Field
                                name="externalAssetsAlternativeFunds"
                                label={t(
                                  'investorProfile.ExternalAssetsAlternativInvestments'
                                )}
                                variant="outlined"
                                component={TextFormField}
                                disabled={isDisabled}
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Field
                                name="externalAssetsMixtFundsOrDisk"
                                label={t(
                                  'investorProfile.ExternalAssetsMixtFundsOrDisk'
                                )}
                                variant="outlined"
                                component={TextFormField}
                                disabled={isDisabled}
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Field
                                name="externalAssetsPrivateEquity"
                                label={t(
                                  'investorProfile.ExternalAssetsPrivateEquity'
                                )}
                                variant="outlined"
                                component={TextFormField}
                                disabled={isDisabled}
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Field
                                name="externalAssetsRealEstate"
                                label={t(
                                  'investorProfile.ExternalAssetsRealEstate'
                                )}
                                variant="outlined"
                                component={TextFormField}
                                disabled={isDisabled}
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Field
                                name="externalAssetsOtherInvestments"
                                label={t(
                                  'investorProfile.ExternalAssetsUnlistedEquity'
                                )}
                                variant="outlined"
                                component={TextFormField}
                                disabled={isDisabled}
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Field
                                name="externalAssetsOwnedCompanies"
                                label={t(
                                  'investorProfile.ExternalAssetsOwnedCompanies'
                                )}
                                variant="outlined"
                                component={TextFormField}
                                disabled={isDisabled}
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Field
                                name="realEstateValue"
                                label={t('investorProfile.RealEstateValue')}
                                variant="outlined"
                                component={TextFormField}
                                disabled={isDisabled}
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Field
                                name="realEstateLoans"
                                label={t('investorProfile.RealEstateLoans')}
                                variant="outlined"
                                component={TextFormField}
                                disabled={isDisabled}
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Field
                                name="otherLoans"
                                label={t('investorProfile.OtherLoans')}
                                variant="outlined"
                                component={TextFormField}
                                disabled={isDisabled}
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Field
                                name="assessedRiskDiscrepancyClientAdviser"
                                component={RadioGroupFormField}
                                options={yesNoOptions}
                                isDisabled={isDisabled}
                                label={t(
                                  'investorProfile.AssessedRiskDiscrepancyClientAdviser'
                                )}
                              />
                            </Grid>
                            {values.assessedRiskDiscrepancyClientAdviser ===
                            true ? (
                              <>
                                <Grid item xs={12} mt={4}>
                                  <Field
                                    name="riskAssessmentDiscrepancyDescription"
                                    variant="outlined"
                                    label={t(
                                      'investorProfile.RiskAssessmentDiscrepancyDescription'
                                    )}
                                    component={TextFormField}
                                    disabled={isDisabled}
                                  />
                                </Grid>
                              </>
                            ) : null}
                            <Grid item xs={12} mt={4}>
                              <Field
                                name="expectedChangeAssetsAndLiabilities"
                                component={RadioGroupFormField}
                                options={yesNoOptions}
                                isDisabled={isDisabled}
                                label={t(
                                  'investorProfile.ExpectedChangeAssetsAndLiabilities'
                                )}
                              />
                            </Grid>
                            {values.expectedChangeAssetsAndLiabilities ===
                            true ? (
                              <>
                                <Grid item xs={12} mt={4}>
                                  <Field
                                    name="whatAreExpectedChangeInAssetsAndLiabilities"
                                    label={t(
                                      'investorProfile.WhatAreExpectedChangeInAssetsAndLiabilities'
                                    )}
                                    variant="outlined"
                                    component={TextFormField}
                                    disabled={isDisabled}
                                  />
                                </Grid>
                              </>
                            ) : null}
                            <Grid item xs={12} mt={4}>
                              <TextField
                                name="summaryIncomeExpensesAndAssets"
                                label={t(
                                  'investorProfile.SummaryOfIncomeExpensesAndAssets'
                                )}
                                value={values.summaryIncomeExpensesAndAssets}
                                fullWidth
                                multiline
                                minRows={4}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={isDisabled}
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Typography variant="h6" gutterBottom>
                                {t('investorProfile.InvestmentObjectives')}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <FormControl
                                sx={{ m: 2 }}
                                component="fieldset"
                                variant="standard"
                              >
                                <FormLabel component="legend">
                                  {t(
                                    'investorProfile.InvestmentObjectivesDescription'
                                  )}
                                </FormLabel>
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={values.investmentObjectivesDescriptionValues?.some(
                                          (p) =>
                                            p ===
                                            investmentObjectivesDescription.NextGenerations
                                        )}
                                        onChange={handleChange}
                                        name="operatingIncome"
                                      />
                                    }
                                    disabled={isDisabled}
                                    label={t(
                                      'investorProfile.investmentObjectivesDescriptions.NextGenerations'
                                    )}
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={values.investmentObjectivesDescriptionValues?.some(
                                          (p) =>
                                            p ===
                                            investmentObjectivesDescription.SecureOwnPension
                                        )}
                                        onChange={handleChange}
                                        disabled={isDisabled}
                                        name="returnOnCapitalInvestments"
                                      />
                                    }
                                    label={t(
                                      'investorProfile.investmentObjectivesDescriptions.SecureOwnPension'
                                    )}
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={values.investmentObjectivesDescriptionValues?.some(
                                          (p) =>
                                            p ===
                                            investmentObjectivesDescription.ReturnOnCapital
                                        )}
                                        onChange={handleChange}
                                        disabled={isDisabled}
                                        name="realEstateSales"
                                      />
                                    }
                                    label={t(
                                      'investorProfile.investmentObjectivesDescriptions.ReturnOnCapital'
                                    )}
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={values.investmentObjectivesDescriptionValues?.some(
                                          (p) =>
                                            p ===
                                            investmentObjectivesDescription.SpecificInvestmentGoals
                                        )}
                                        onChange={handleChange}
                                        disabled={isDisabled}
                                        name="realEstateSales"
                                      />
                                    }
                                    label={t(
                                      'investorProfile.investmentObjectivesDescriptions.SpecificInvestmentGoals'
                                    )}
                                  />
                                </FormGroup>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Field
                                name="investmentGoalsDescribe"
                                label={t(
                                  'investorProfile.InvestmentGoalsDescribe'
                                )}
                                variant="outlined"
                                component={TextFormField}
                                disabled={isDisabled}
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Field
                                name="investmentHorizonCompany"
                                component={RadioGroupFormField}
                                options={investmentHorizonCompanyOptions}
                                isDisabled={isDisabled}
                                label={t('investorProfile.InvestmentHorizon')}
                              />
                            </Grid>
                            {values.investmentHorizonCompany ===
                            InvestmentHorizon.Other ? (
                              <Grid item xs={12} mt={4}>
                                <Field
                                  name="investmentHorizonDescribe"
                                  label={t(
                                    'investorProfile.InvestmentGoalsDescribe'
                                  )}
                                  variant="outlined"
                                  component={TextFormField}
                                  disabled={isDisabled}
                                />
                              </Grid>
                            ) : null}

                            <Grid item xs={12} mt={4}>
                              <Field
                                name="dependentOnCapitalInShortterm"
                                component={RadioGroupFormField}
                                options={yesNoOptions}
                                isDisabled={isDisabled}
                                label={t(
                                  'investorProfile.DependentOnCapitalShortTermCompany'
                                )}
                              />
                            </Grid>
                            {values.dependentOnCapitalInShortterm === true ? (
                              <>
                                <Grid item xs={12} mt={4}>
                                  <Field
                                    name="annualCapitalDependency1to3y"
                                    label={t(
                                      'investorProfile.AnnualCapitalDependency1to3y'
                                    )}
                                    variant="outlined"
                                    component={TextFormField}
                                    disabled={isDisabled}
                                  />
                                </Grid>
                                <Grid item xs={12} mt={4}>
                                  <Field
                                    name="annualCapitalDependency3to5y"
                                    label={t(
                                      'investorProfile.AnnualCapitalDependency3to5y'
                                    )}
                                    variant="outlined"
                                    component={TextFormField}
                                    disabled={isDisabled}
                                  />
                                </Grid>
                              </>
                            ) : null}
                            <Grid item xs={12} mt={4}>
                              <Field
                                name="dependentOnCapitalInLongterm"
                                component={RadioGroupFormField}
                                options={yesNoOptions}
                                isDisabled={isDisabled}
                                label={t(
                                  'investorProfile.DependentOnCapitalLongTermCompany'
                                )}
                              />
                            </Grid>
                            {values.dependentOnCapitalInLongterm === true ? (
                              <>
                                <Grid item xs={12} mt={4}>
                                  <Field
                                    name="annualCapitalDependency5to10y"
                                    label={t(
                                      'investorProfile.Annualcapitaldependence5to10y'
                                    )}
                                    variant="outlined"
                                    component={TextFormField}
                                    disabled={isDisabled}
                                  />
                                </Grid>
                                <Grid item xs={12} mt={4}>
                                  <Field
                                    name="annualCapitalDependencyMore10y"
                                    label={t(
                                      'investorProfile.Annualcapitaldependencymore10y'
                                    )}
                                    variant="outlined"
                                    component={TextFormField}
                                    disabled={isDisabled}
                                  />
                                </Grid>
                              </>
                            ) : null}
                            <Grid item xs={12} mt={4}>
                              <TextField
                                name="investmentSummaryObjectivesHorizon"
                                label={t(
                                  'investorProfile.InvestmentSummaryObjectivesHorizon'
                                )}
                                value={
                                  values.investmentSummaryObjectivesHorizon
                                }
                                fullWidth
                                multiline
                                minRows={4}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={isDisabled}
                              />
                            </Grid>
                          </>
                        ) : null}
                        <Grid item xs={12} mt={4}>
                          <Typography variant="h6" gutterBottom>
                            {t('investorProfile.ExperienceAndTraining')} (
                            {t('Representative')} 1)
                          </Typography>
                        </Grid>
                        {values.investorProfileExperience1 != null &&
                        values.investorProfileExperience1.contactId != null ? (
                          <InvestorProfileExperience
                            investorProfileExperience={
                              values.investorProfileExperience1
                            }
                            isDisabled={isDisabled}
                          />
                        ) : (
                          <Typography>{t('No')}</Typography>
                        )}
                        {values.investorProfileExperience2 != null &&
                        values.investorProfileExperience2.contactId != null ? (
                          <>
                            <Grid item xs={12} mt={4}>
                              <Typography variant="h6" gutterBottom>
                                {t('investorProfile.ExperienceAndTraining')} (
                                {t('Representative')} 2)
                              </Typography>
                            </Grid>
                            <InvestorProfileExperience
                              investorProfileExperience={
                                values.investorProfileExperience2
                              }
                              isDisabled={isDisabled}
                            />
                          </>
                        ) : null}
                        <Grid item xs={12} mt={4}>
                          <Typography variant="h6" gutterBottom>
                            {t('investorProfile.RiskProfileAssessment')}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} mt={4}>
                          <Field
                            name="investRisk"
                            component={RadioGroupFormField}
                            options={investRisk}
                            isDisabled={isDisabled}
                            label={t('investorProfile.InvestRisk')}
                          />
                        </Grid>
                        <Grid item xs={12} mt={4}>
                          <Field
                            name="investRiskAction"
                            component={RadioGroupFormField}
                            options={investRiskAction}
                            isDisabled={isDisabled}
                            label={t('investorProfile.investRiskAction')}
                          />
                        </Grid>
                        <Grid item xs={12} mt={4}>
                          <Field
                            name="annualTargetReturn"
                            component={RadioGroupFormField}
                            options={annualTargetReturnOptions}
                            isDisabled={isDisabled}
                            label={t('investorProfile.AnnualTargetReturn')}
                          />
                        </Grid>
                        <Grid item xs={12} mt={4}>
                          <Field
                            name="investmentAlternativeToBankAccount"
                            component={RadioGroupFormField}
                            options={yesNoOptions}
                            isDisabled={isDisabled}
                            label={t(
                              'investorProfile.InvestmentAlternativetoBankAccount'
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} mt={4}>
                          <Field
                            name="investmentWithAllocationStrategy"
                            component={RadioGroupFormField}
                            options={yesNoOptions}
                            isDisabled={isDisabled}
                            label={t(
                              'investorProfile.InvestmentWithAllocationStrategy'
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} mt={4}>
                          <Field
                            name="investmentOppListedAndUnlisted"
                            component={RadioGroupFormField}
                            options={yesNoOptions}
                            isDisabled={isDisabled}
                            label={t(
                              'investorProfile.InvestmentPppListedandUnlisted'
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} mt={4}>
                          <TextField
                            name="summaryRiskAssessment"
                            label={t('investorProfile.SummaryRiskAssessment')}
                            value={values.summaryRiskAssessment}
                            fullWidth
                            multiline
                            minRows={4}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                            disabled={isDisabled}
                          />
                        </Grid>
                        <Grid item xs={12} mt={4}>
                          <Typography variant="h6" gutterBottom>
                            {t('investorProfile.InvestmentPreferences')}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} mt={4}>
                          <Field
                            name="doYouHaveASustainabilityPreference"
                            component={RadioGroupFormField}
                            options={yesNoOptions}
                            isDisabled={isDisabled}
                            label={t(
                              'investorProfile.DoYouHaveaSustainabilityPreference'
                            )}
                          />
                        </Grid>
                        {values.doYouHaveASustainabilityPreference === true ? (
                          <>
                            <Grid item xs={12} mt={4}>
                              <Typography>
                                {t(
                                  'investorProfile.InvestmentPrefrencesSustainableInvestments'
                                )}
                              </Typography>
                              <Typography sx={{ fontStyle: 'italic' }}>
                                {t(
                                  'investorProfile.InvestmentPrefrencesSustainableInvestmentsDesc'
                                )}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Field
                                name="sp_ParticularInterestSustainableInvestment"
                                component={RadioGroupFormField}
                                options={investmentPrefrencesPercentages}
                                isDisabled={isDisabled}
                                label={t(
                                  'investorProfile.ParticularInterestSustainableInvestments'
                                )}
                              />
                            </Grid>

                            <Grid item xs={12} mt={4}>
                              <Typography>
                                {t(
                                  'investorProfile.InvestmentPrefrencesNegativeSustainability'
                                )}
                              </Typography>
                              <Typography sx={{ fontStyle: 'italic' }}>
                                {' '}
                                {t(
                                  'investorProfile.InvestmentPrefrencesNegativeSustainabilityDesc'
                                )}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Typography>
                                {t(
                                  'investorProfile.InvestmentPrefrencesNegativeConsequencesEnvironment'
                                )}
                              </Typography>
                              <Typography sx={{ fontStyle: 'italic' }}>
                                {t(
                                  'investorProfile.InvestmentPrefrencesNegativeConsequencesEnvironmentDesc'
                                )}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Typography>
                                {t('investorProfile.GreenhouseGasesEmission')}
                              </Typography>
                              <Typography sx={{ fontStyle: 'italic' }}>
                                {t(
                                  'investorProfile.GreenhouseGasesEmissionDesc'
                                )}
                              </Typography>
                              <Field
                                name="sp_GreenhouseGasesEmission"
                                component={RadioGroupFormField}
                                options={investmentPrefrencesPercentages}
                                isDisabled={isDisabled}
                                label=""
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Typography>
                                {t('investorProfile.CarbonFootprint')}
                              </Typography>
                              <Typography sx={{ fontStyle: 'italic' }}>
                                {' '}
                                {t('investorProfile.CarbonFootprintDesc')}
                              </Typography>
                              <Field
                                name="sp_CarbonFootprint"
                                component={RadioGroupFormField}
                                options={investmentPrefrencesPercentages}
                                isDisabled={isDisabled}
                                label=""
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Typography>
                                {' '}
                                {t(
                                  'investorProfile.GreenhouseGasEmissionsCompany'
                                )}
                              </Typography>
                              <Typography sx={{ fontStyle: 'italic' }}>
                                {t(
                                  'investorProfile.GreenhouseGasEmissionsCompanyDesc'
                                )}
                              </Typography>
                              <Field
                                name="sp_GreenhouseGasEmissionsCompany"
                                component={RadioGroupFormField}
                                options={investmentPrefrencesPercentages}
                                isDisabled={isDisabled}
                                label=""
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Typography>
                                {t('investorProfile.FossilFuel')}
                              </Typography>
                              <Typography sx={{ fontStyle: 'italic' }}>
                                {t('investorProfile.FossilFuelDesc')}
                              </Typography>
                              <Field
                                name="sp_FossilFuel"
                                component={RadioGroupFormField}
                                options={investmentPrefrencesPercentages}
                                isDisabled={isDisabled}
                                label=""
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Typography>
                                {t('investorProfile.NonRenewableEnergy')}
                              </Typography>
                              <Typography sx={{ fontStyle: 'italic' }}>
                                {t('investorProfile.NonRenewableEnergyDesc')}
                              </Typography>
                              <Field
                                name="sp_NonRenewableEnergy"
                                component={RadioGroupFormField}
                                options={investmentPrefrencesPercentages}
                                isDisabled={isDisabled}
                                label=""
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Typography>
                                {t('investorProfile.EnergyConsumption')}
                              </Typography>
                              <Typography sx={{ fontStyle: 'italic' }}>
                                {t('investorProfile.EnergyConsumptionDesc')}
                              </Typography>
                              <Field
                                name="sp_EnergyConsumption"
                                component={RadioGroupFormField}
                                options={investmentPrefrencesPercentages}
                                isDisabled={isDisabled}
                                label=""
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Typography>
                                {t('investorProfile.ReleaseIntoWater')}
                              </Typography>
                              <Typography sx={{ fontStyle: 'italic' }}>
                                {t('investorProfile.ReleaseIntoWaterDesc')}
                              </Typography>
                              <Field
                                name="sp_ReleaseIntoWater"
                                component={RadioGroupFormField}
                                options={investmentPrefrencesPercentages}
                                isDisabled={isDisabled}
                                label=""
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Typography>
                                {t('investorProfile.HazardousWaste')}
                              </Typography>
                              <Typography sx={{ fontStyle: 'italic' }}>
                                {t('investorProfile.HazardousWasteDesc')}
                              </Typography>
                              <Field
                                name="sp_HazardousWaste"
                                component={RadioGroupFormField}
                                options={investmentPrefrencesPercentages}
                                isDisabled={isDisabled}
                                label=""
                              />
                            </Grid>

                            <Grid item xs={12} mt={4}>
                              <Typography>
                                {t(
                                  'investorProfile.InvestmentPrefrencesNegativeConsequencesSocialSustainability'
                                )}
                              </Typography>
                              <Typography sx={{ fontStyle: 'italic' }}>
                                {t(
                                  'investorProfile.InvestmentPrefrencesNegativeConsequencesSocialSustainabilityDesc'
                                )}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Typography>
                                {t('investorProfile.ViolationGlobalAgreement')}
                              </Typography>
                              <Typography sx={{ fontStyle: 'italic' }}>
                                {t(
                                  'investorProfile.ViolationGlobalAgreementDesc'
                                )}
                              </Typography>
                              <Field
                                name="sp_ViolationGlobalAgreement"
                                component={RadioGroupFormField}
                                options={investmentPrefrencesPercentages}
                                isDisabled={isDisabled}
                                label=""
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Typography>
                                {' '}
                                {t('investorProfile.DeficiencyGlobalAgreement')}
                              </Typography>
                              <Typography sx={{ fontStyle: 'italic' }}>
                                {t(
                                  'investorProfile.DeficiencyGlobalAgreementDesc'
                                )}
                              </Typography>
                              <Field
                                name="sp_DeficiencyGlobalAgreement"
                                component={RadioGroupFormField}
                                options={investmentPrefrencesPercentages}
                                isDisabled={isDisabled}
                                label=""
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Typography>
                                {' '}
                                {t('investorProfile.ControversialWeapons')}
                              </Typography>
                              <Typography sx={{ fontStyle: 'italic' }}>
                                {t('investorProfile.ControversialWeaponsDesc')}
                              </Typography>
                              <Field
                                name="sp_ControversialWeapons"
                                component={RadioGroupFormField}
                                options={investmentPrefrencesPercentages}
                                isDisabled={isDisabled}
                                label=""
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Typography>
                                {t('investorProfile.GenderPayGap')}
                              </Typography>
                              <Typography sx={{ fontStyle: 'italic' }}>
                                {t('investorProfile.GenderPayGapDesc')}
                              </Typography>
                              <Field
                                name="sp_GenderPayGap"
                                component={RadioGroupFormField}
                                options={investmentPrefrencesPercentages}
                                isDisabled={isDisabled}
                                label=""
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Typography>
                                {t('investorProfile.GenderDistribution')}
                              </Typography>
                              <Typography sx={{ fontStyle: 'italic' }}>
                                {t('investorProfile.GenderDistributionDesc')}
                              </Typography>
                              <Field
                                name="sp_GenderDistribution"
                                component={RadioGroupFormField}
                                options={investmentPrefrencesPercentages}
                                isDisabled={isDisabled}
                                label=""
                              />
                            </Grid>

                            <Grid item xs={12} mt={4}>
                              <Typography>
                                {t(
                                  'investorProfile.InvestmentPrefrencesInvestedProductsSpecialEnvironmental'
                                )}
                              </Typography>
                              <Typography sx={{ fontStyle: 'italic' }}>
                                {' '}
                                {t(
                                  'investorProfile.InvestmentPrefrencesInvestedProductsSpecialEnvironmentalDesc'
                                )}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Field
                                name="sp_InterestProducts"
                                component={RadioGroupFormField}
                                options={investmentPrefrencesPercentages}
                                isDisabled={isDisabled}
                                label={t('investorProfile.InterestProducts')}
                              />
                            </Grid>
                          </>
                        ) : null}

                        <Grid item xs={12} mt={4}>
                          <Field
                            name="assetTypeCustomerDoesntWant"
                            component={RadioGroupFormField}
                            options={yesNoOptions}
                            isDisabled={isDisabled}
                            label={t(
                              'investorProfile.AssetCustomerDoesNotWantInvested'
                            )}
                          />
                        </Grid>
                        {values.assetTypeCustomerDoesntWant === true ? (
                          <>
                            <Grid item xs={12} mt={4}>
                              <FormControl
                                sx={{ m: 2 }}
                                component="fieldset"
                                variant="standard"
                              >
                                <FormLabel component="legend">
                                  {t('investorProfile.Which_2')}
                                </FormLabel>
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={values.assetTypeWhichValues?.some(
                                          (p) =>
                                            p === witchdoesntwant.ListedEquties
                                        )}
                                        onChange={handleChange}
                                        name="operatingIncome"
                                      />
                                    }
                                    disabled={isDisabled}
                                    label={t('investorProfile.ListedEquity')}
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={values.assetTypeWhichValues?.some(
                                          (p) =>
                                            p === witchdoesntwant.EquityFunds
                                        )}
                                        onChange={handleChange}
                                        name="operatingIncome"
                                      />
                                    }
                                    disabled={isDisabled}
                                    label={t('investorProfile.EquityFunds')}
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={values.assetTypeWhichValues?.some(
                                          (p) => p === witchdoesntwant.HedgeFunds
                                        )}
                                        onChange={handleChange}
                                        name="operatingIncome"
                                      />
                                    }
                                    disabled={isDisabled}
                                    label={t(
                                      'investorProfile.AlternativeFundsHedgeFunds'
                                    )}
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={values.assetTypeWhichValues?.some(
                                          (p) =>
                                            p === witchdoesntwant.InterestFunds
                                        )}
                                        onChange={handleChange}
                                        name="operatingIncome"
                                      />
                                    }
                                    disabled={isDisabled}
                                    label={t('investorProfile.InterestFunds')}
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={values.assetTypeWhichValues?.some(
                                          (p) =>
                                            p ===
                                            witchdoesntwant.PrivateEquityFunds
                                        )}
                                        onChange={handleChange}
                                        name="operatingIncome"
                                      />
                                    }
                                    disabled={isDisabled}
                                    label={t(
                                      'investorProfile.PrivateEquityFunds'
                                    )}
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={values.assetTypeWhichValues?.some(
                                          (p) =>
                                            p === witchdoesntwant.RealEstateFunds
                                        )}
                                        onChange={handleChange}
                                        name="operatingIncome"
                                      />
                                    }
                                    disabled={isDisabled}
                                    label={t('investorProfile.RealEstateFunds')}
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={values.assetTypeWhichValues?.some(
                                          (p) =>
                                            p ===
                                            witchdoesntwant.UnlistedEquities
                                        )}
                                        onChange={handleChange}
                                        name="operatingIncome"
                                      />
                                    }
                                    disabled={isDisabled}
                                    label={t(
                                      'investorProfile.UnlistedEquities'
                                    )}
                                  />
                                </FormGroup>
                              </FormControl>
                              <Grid item xs={12} mt={4}>
                                <TextField
                                  name="specifyWhy"
                                  label={t('investorProfile.SpecifyWhy')}
                                  value={values.specifyWhy || ''}
                                  error={Boolean(
                                    touched.specifyWhy && errors.specifyWhy
                                  )}
                                  fullWidth
                                  helperText={
                                    touched.specifyWhy && errors.specifyWhy
                                  }
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  variant="outlined"
                                  disabled={isDisabled}
                                />
                              </Grid>
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Field
                                name="acceptanceCanNegAffectReturnsRisk"
                                component={RadioGroupFormField}
                                options={yesNoOptions}
                                isDisabled={isDisabled}
                                label={t(
                                  'investorProfile.AcceptanceCanNegAffectReturnsRisk'
                                )}
                              />
                            </Grid>
                          </>
                        ) : null}

                        <Grid item xs={12} mt={4}>
                          <TextField
                            name="summaryInvestmentPreferences"
                            label={t(
                              'investorProfile.SummaryInvestmentPreferences'
                            )}
                            value={values.summaryInvestmentPreferences}
                            fullWidth
                            multiline
                            minRows={4}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                            disabled={isDisabled}
                          />
                        </Grid>

                        <Grid item xs={12} mt={4}>
                          <Typography variant="h6" gutterBottom>
                            {t('investorProfile.RecommendedAllocation')}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} mt={4}>
                          <TextField
                            name="allocationsAssetsHighLiquidityDec"
                            label={t(
                              'investorProfile.AllocationsAssetsHighLiquidityDec'
                            )}
                            value={
                              values.allocationsAssetsHighLiquidityDec || ''
                            }
                            error={Boolean(
                              touched.allocationsAssetsHighLiquidityDec &&
                                errors.allocationsAssetsHighLiquidityDec
                            )}
                            fullWidth
                            helperText={
                              touched.allocationsAssetsHighLiquidityDec &&
                              errors.allocationsAssetsHighLiquidityDec
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                            disabled={isDisabled}
                          />
                        </Grid>
                        <Grid item xs={12} mt={4}>
                          <TextField
                            name="allocationsAssetsLimitedLiquidityDec"
                            label={t(
                              'investorProfile.AllocationsAssetsLimitedLiquidityDec'
                            )}
                            value={
                              values.allocationsAssetsLimitedLiquidityDec || ''
                            }
                            error={Boolean(
                              touched.allocationsAssetsLimitedLiquidityDec &&
                                errors.allocationsAssetsLimitedLiquidityDec
                            )}
                            fullWidth
                            helperText={
                              touched.allocationsAssetsLimitedLiquidityDec &&
                              errors.allocationsAssetsLimitedLiquidityDec
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                            disabled={isDisabled}
                          />
                        </Grid>
                        <Grid item xs={12} mt={4}>
                          <Field
                            name="recommendationAmountInterestFunds"
                            label={t(
                              'investorProfile.RecommendationAmountInterestFunds'
                            )}
                            variant="outlined"
                            component={TextFormField}
                            disabled={isDisabled}
                          />
                        </Grid>
                        <Grid item xs={12} mt={4}>
                          <Field
                            name="recommendationAmountListedEquityOrEquityFund"
                            label={t(
                              'investorProfile.RecommendationAmountListEquity'
                            )}
                            variant="outlined"
                            component={TextFormField}
                            disabled={isDisabled}
                          />
                        </Grid>
                        <Grid item xs={12} mt={4}>
                          <Field
                            name="recommendationAmountAlterInvestHedge"
                            label={t(
                              'investorProfile.RecommendationAmountAlternativeHedge'
                            )}
                            variant="outlined"
                            component={TextFormField}
                            disabled={isDisabled}
                          />
                        </Grid>
                        <Grid item xs={12} mt={4}>
                          <Field
                            name="recommendationAmountPrivateEquityFund"
                            label={t(
                              'investorProfile.RecommendationamoPrivateEquityFund'
                            )}
                            variant="outlined"
                            component={TextFormField}
                            disabled={isDisabled}
                          />
                        </Grid>
                        <Grid item xs={12} mt={4}>
                          <Field
                            name="recommendationAmountRealEstateFund"
                            label={t(
                              'investorProfile.RecommendationAmountRealestateFund'
                            )}
                            variant="outlined"
                            component={TextFormField}
                            disabled={isDisabled}
                          />
                        </Grid>
                        <Grid item xs={12} mt={4}>
                          <Field
                            name="recommendationAmountListUnlistInvestCase"
                            label={t(
                              'investorProfile.RecommendationAmountListUnlistedCase'
                            )}
                            variant="outlined"
                            component={TextFormField}
                            disabled={isDisabled}
                          />
                        </Grid>
                        <Grid item xs={12} mt={4}>
                          <TextField
                            name="allowedDriftOnOverallRecommendedAllocation"
                            label={t(
                              'investorProfile.AllowedDriftOnOverallRecommendedAllocation'
                            )}
                            value={
                              values.allowedDriftOnOverallRecommendedAllocation ||
                              ''
                            }
                            error={Boolean(
                              touched.allowedDriftOnOverallRecommendedAllocation &&
                                errors.allowedDriftOnOverallRecommendedAllocation
                            )}
                            fullWidth
                            helperText={
                              touched.allowedDriftOnOverallRecommendedAllocation &&
                              errors.allowedDriftOnOverallRecommendedAllocation
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                            disabled={isDisabled}
                          />
                        </Grid>
                        <Grid item xs={12} mt={4}>
                          <Field
                            name="advisorRecomRiskLevelForCustomer"
                            component={RadioGroupFormField}
                            options={
                              advisorsOverallRecommendationOnRiskLevelOptions
                            }
                            isDisabled={isDisabled}
                            label={t(
                              'investorProfile.AdvisorRecomRiskLevelforCustomer'
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} mt={4}>
                          <TextField
                            name="summaryOfOverallManagementStrategy"
                            label={t(
                              'investorProfile.SummaryofOverallManagementStrategy'
                            )}
                            value={values.summaryOfOverallManagementStrategy}
                            fullWidth
                            multiline
                            minRows={4}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                            disabled={isDisabled}
                          />
                        </Grid>
                        <Grid item xs={12} mt={4}>
                          {isConfirmed ? null : (
                            <Grid container spacing={1}>
                              <Grid item>
                                <FormDialogConfirm
                                  handleDataChanged={setDataChanged}
                                  handleIsFetch={setIsFetched}
                                ></FormDialogConfirm>
                              </Grid>
                              <Grid item>
                                <FormDialogReject
                                  handleDataChanged={setDataChanged}
                                  handleIsFetch={setIsFetched}
                                ></FormDialogReject>
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </form>
                  ) : (
                    <Typography>{t('investorProfile.NoInfo')}</Typography>
                  )}
                </CardContent>
              </Card>
            )}
          </Formik>
        ) : (
          <div>
            <Typography> {t('auth.LoadingMessage')}</Typography>
            <Box display="flex" justifyContent="center" my={6}>
              <CircularProgress />
            </Box>
          </div>
        )}
      </div>
    );
  }

  return (
    <React.Fragment>
      <BaseForm />
    </React.Fragment>
  );
}

export default InvestmentProfileCompanyForm;
