import { Grid } from '@mui/material';
import InvestmentLayout from '../../components/layout/InvestmentLayout';
import PortfolioNews from '../../components/widgets/PortfolioNews';

export default function InvestmentNews() {

  return (
    <>
      <InvestmentLayout>
        <Grid container>
          <Grid item xs={12} p={1}>
            <PortfolioNews />

          </Grid>
        </Grid>
      </InvestmentLayout>
    </>
  );
}
