import React, { useEffect, useRef } from 'react';
import {
  Alert,
  Box,
  Button,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Stack,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import KycForm from './KycForm';
import PortfolioAgreement from './PortfolioAgreement';
import TradeOrder from './TradeOrder';
import OtherDocuments from './OtherDocuments';

import { useApiClient } from '../../hooks/apiClient';
import { ToDoEnum } from '../../Enums';
import { getActiveUser } from '../../redux/stateFuncs';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import InvestmentProfile from './InvestmentProfileUser';
import InvestmentProfileCompanyForm from './InvestmentProfileCompany';
import KycCompanyPage from './KycCompany';
import { deactivateUsers, setActiveUser } from '../../redux/slices/users';
import { useTranslation } from 'react-i18next';
import { clearTodos, setToDoComplete, setTodos } from '../../redux/slices/todo';
import { IToDo } from '../../models/todo';
import NewPortfolioAgreement from './NewPortfolioAgreement';

interface IToDoDto {
  isAnyToDo: boolean;
  toDoTasks: IToDo[];
}

interface IOtherUsersTaskToDo {
  userId: string;
  userName: string;
  isCompany: boolean;
}

function FormToDo(props: any) {
  //const [tasks, setTasks] = React.useState<IToDo[]>([]);
  const tasksLoaded = useRef(null);
  const [taskDialogOpen, setTaskDialogOpen] = React.useState(false);
  const [isPrevNotDone, setIsPrevNotDone] = React.useState(false);
  const [otherAccountToDoIgnored, setOtherAccountToDoIsIgnored] =
    React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [otherUsersTaskToDo, setOtherUsersTaskToDo] = React.useState<
    IOtherUsersTaskToDo[]
  >([]);
  const [isOtherUsersTasksOpen, setOtherUsersTasksOpen] = React.useState(false);

  const users = useAppSelector((state) => state.users);
  const api = useApiClient();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const todos = useAppSelector((state) => state.todos);

  const ToDoLabelMapping: Record<ToDoEnum, string> = {
    [ToDoEnum.Kyc]: t('menu.KnowYourCustomer'),
    [ToDoEnum.KycCompany]: t('menu.KnowYourCustomer'),
    [ToDoEnum.InvestorProfile]: t('menu.InvestorProfile'),
    [ToDoEnum.InvestorProfileCompany]: t('menu.InvestorProfileCompany'),
    [ToDoEnum.PortfolioAgreement]: t('menu.PortfolioAgreements'),
    [ToDoEnum.TradeOrders]: t('menu.TradeOrders'),
    [ToDoEnum.OtherDocuments]: t('menu.OtherDocuments'),
    [ToDoEnum.NewPortfolioAgreement]: t('menu.NewPortfolioAgreements'),
  };

  useEffect(() => {
    function checkTask(t: IToDo[]): boolean {
      return t.length != todos.filter((t) => t.complete == false).length;
    }

    function checkTodo() {
      var activeUser = getActiveUser(users);
      var canSign = users.find((user) => user.active === true)?.canSign;

      var activeUserId = activeUser?.id;
      var apiName =
        activeUser?.isCompany == true
          ? `api/todo/GetToDoTasksCompany/${activeUserId}`
          : `api/todo/GetToDoTasksPrivatePerson/${activeUserId}`;

      if (!canSign) return;

      api.get(apiName).then(
        (res) => {
          const todo: IToDoDto = res.data;
          checkIsOtherUsersTaskToDo();

          if (todo.isAnyToDo) {
            // Only set total tasks once
            // if (tasks.length == 0) {
            //   setTasks(todo.toDoTasks);
            // }

            if (checkTask(todo.toDoTasks)) {
              dispatch(
                setTodos(
                  todo.toDoTasks.map((t) => {
                    var todo: IToDo = {
                      id: t.id,
                      type: t.type,
                      complete: false,
                      active: false,
                    };
                    return todo;
                  })
                )
              );
            } else {
              // KYC
              if (
                todos[activeStep] &&
                todos[activeStep].type == ToDoEnum.Kyc &&
                todos[activeStep].complete
              ) {
                handleNext();
              } else {
                if (todos[activeStep] && todos[activeStep].complete) {
                  if (todos.filter((t) => t.complete == false).length > 0)
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                  else setTaskDialogOpen(false);
                }
              }
            }

            setTaskDialogOpen(true);
          } else {
            setTaskDialogOpen(false);
          }
        },
        (err) => {}
      );

      // // DEBUGGING
      // setTasks([ToDoEnum.Kyc, ToDoEnum.OtherDocuments]);
      // setTaskDialogOpen(true);
      //}
    }

    checkTodo();
  }, [users, todos]);

  // useEffect(() => {
  //   // If we find any completed todos, try call handleNext
  //   if (todos.find((f) => f.complete == true)) handleNext();
  // }, [todos]);

  useEffect(() => {
    if (otherUsersTaskToDo.length != 0) {
      if (!taskDialogOpen && !otherAccountToDoIgnored)
        setOtherUsersTasksOpen(true);
    }
  }, [taskDialogOpen, otherUsersTaskToDo, otherAccountToDoIgnored]);

  const getTaskForm = (task: ToDoEnum) => {
    switch (task) {
      case ToDoEnum.Kyc:
        return <KycForm isToDo />;
      case ToDoEnum.InvestorProfile:
        return <InvestmentProfile isToDo />;
      case ToDoEnum.PortfolioAgreement:
        return <PortfolioAgreement isToDo />;
      case ToDoEnum.TradeOrders:
        return <TradeOrder isToDo />;
      case ToDoEnum.OtherDocuments:
        return <OtherDocuments isToDo />;
      case ToDoEnum.InvestorProfileCompany:
        return <InvestmentProfileCompanyForm isToDo />;
      case ToDoEnum.KycCompany:
        return <KycCompanyPage isToDo />;
      case ToDoEnum.NewPortfolioAgreement:
        return <NewPortfolioAgreement isToDo />;

      default:
        return <KycForm isToDo />;
    }
  };
  const handleNext = () => {
    if (todos[activeStep]) {
      var activeUser = getActiveUser(users);

      var activeUserId = activeUser?.id;
      var apiName =
        activeUser?.isCompany == true
          ? `api/todo/IsPrevDoneCompany/${activeUserId}/${todos[activeStep].type}`
          : `api/todo/IsPrevDonePrivatePerson/${activeUserId}/${todos[activeStep].type}`;

      setIsPrevNotDone(false);
      api.get(apiName).then(
        (res) => {
          if (activeStep === todos.length - 1) {
            setTaskDialogOpen(false);
          } else {
            dispatch(setToDoComplete(todos[activeStep].type));
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          }
        },
        (err) => {
          setIsPrevNotDone(true);
        }
      );
    }
  };

  const handleCandelOtherAccountToDo = () => {
    setOtherAccountToDoIsIgnored(true);
    setOtherUsersTasksOpen(false);
  };

  const switchAccount = (id: string) => {
    dispatch(clearTodos());
    setOtherUsersTaskToDo([]);
    setOtherUsersTasksOpen(false);
    dispatch(setActiveUser(id));
  };

  const checkIsOtherUsersTaskToDo = () => {
    let UsersToCheck = users.filter(
      (user) =>
        user.active === false &&
        user.canSign === true &&
        user.isCompany == false
    );
    let CompanyToCheck = users.filter(
      (company) =>
        company.active === false &&
        company.canSign === true &&
        company.isCompany == true
    );
    if (UsersToCheck) {
      for (let index = 0; index < UsersToCheck.length; index++) {
        const user = UsersToCheck[index];
        if (!otherUsersTaskToDo.find((t) => t.userId == user.id)) {
          api.get(`api/todo/IsAnyToDoPrivatePerson/${user.id}`).then((res) => {
            if (res.data === true) {
              let toDo = [...otherUsersTaskToDo];

              toDo.push({
                userId: user.id,
                userName: user.name,
                isCompany: false,
              });
              setOtherUsersTaskToDo(toDo);
            }
          });
        }
      }
    }
    if (CompanyToCheck) {
      for (let index = 0; index < CompanyToCheck.length; index++) {
        const company = CompanyToCheck[index];
        if (!otherUsersTaskToDo.find((t) => t.userId == company.id)) {
          api.get(`api/todo/IsAnyToDoCompany/${company.id}`).then((res) => {
            if (res.data === true) {
              let toDo = [...otherUsersTaskToDo];

              toDo.push({
                userId: company.id,
                userName: company.name,
                isCompany: true,
              });
              setOtherUsersTaskToDo(toDo);
            }
          });
        }
      }
    }
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth="xl"
        open={isOtherUsersTasksOpen}
        scroll="paper"
      >
        <DialogTitle>
          <Typography align="center">{t('toDo.OtherTasks')}</Typography>
        </DialogTitle>
        <DialogContent dividers={true}>
          <Stack direction="row" spacing={2}>
            {otherUsersTaskToDo.map((p) => {
              return (
                <Button key={p.userId} onClick={() => switchAccount(p.userId)}>
                  {p.userName}
                </Button>
              );
            })}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Box sx={{ flex: '1 1 auto' }} />
          <Button onClick={handleCandelOtherAccountToDo}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth="xl"
        open={taskDialogOpen}
        scroll="paper"
      >
        <DialogTitle>
          {isPrevNotDone ? (
            <Alert severity="error">
              {t('toDo.PleaseCompleteTasksBeforeNext')}
            </Alert>
          ) : (
            <></>
          )}
          <Typography align="center">{t('toDo.PleaseCompleteTask')}</Typography>
          <Box alignItems="center">
            <Stepper activeStep={activeStep} alternativeLabel>
              {todos.map((task) => {
                const stepProps: { completed?: boolean } = {};
                const labelProps: {
                  optional?: React.ReactNode;
                } = {};
                return (
                  <Step key={task.id} {...stepProps}>
                    <StepLabel {...labelProps}>
                      {ToDoLabelMapping[task.type as ToDoEnum]}
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Box>
        </DialogTitle>
        <DialogContent dividers={true}>
          <Box sx={{ width: '100%' }}>
            <React.Fragment>
              {getTaskForm(todos[activeStep]?.type)}
            </React.Fragment>
          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
export default FormToDo;
