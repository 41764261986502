import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { Link } from 'react-router-dom';
import { pages } from '../../routes';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setNavigation } from '../../redux/slices/navigation';
import { getNavigationLink } from '../../util/common';
import React, { useEffect } from 'react';

const drawerWidth = 240;

export default function SideBar() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigation = useAppSelector((state) => state.navigation);
  const [value, setValue] = React.useState('');

  const setNavigationRoute = (nav: string) => {
    dispatch(setNavigation(nav));
  };

  useEffect(() => {
    setValue(navigation);
  }, [navigation]);

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
        display: { xs: 'none', md: 'flex' },
      }}
    >
      <Toolbar />
      <Box sx={{ overflow: 'auto' }}>
        <List>
          {pages.map((page, index) => (
            <ListItem
              key={page.title}
              disablePadding
              component={Link}
              to={getNavigationLink(page)}
              style={{ textDecoration: 'none' }}
            >
              <ListItemButton
                selected={getNavigationLink(page) == value}
                className="side-bar-button"
                onClick={() => setNavigationRoute(getNavigationLink(page))}
              >
                <ListItemText
                  className="side-bar-text"
                  sx={{ '& .MuiTypography-root': { fontSize: '0.875rem' },
                        '& .MuiTypography-root:hover': { color: '#fff' } }}
                >
                  {t(page.title)}
                </ListItemText>
                <page.icon className="side-bar-icon"></page.icon>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>
  );
}
