import { useAppSelector } from '../../redux/hooks';
import { haveActiveEntity } from '../../redux/stateFuncs';
import { CircularProgress, Paper } from '@mui/material';
import TopBar from '../menu/TopBar';

import { Outlet } from 'react-router-dom';
import { Box } from '@mui/system';
import SideBar from '../menu/SideBar';

import FormToDo from '../forms/ToDo';
import { useEffect, useState } from 'react';
import BottomBar from '../menu/BottomBar';
import DataGridPremiumLicense from '../DataGridPremium';
import Footer from '../Footer';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import sv from 'date-fns/locale/sv';
import enUS from 'date-fns/locale/en-US';
import { useTranslation } from 'react-i18next';

const AppLayout = () => {
  const [hasActiveEntity, setHasActiveEntity] = useState(false);
  const users = useAppSelector((state) => state.users);

  useEffect(() => {
    setHasActiveEntity(haveActiveEntity(users));
  }, [users]);

  type MyDictionary = {
    [key: string]: Locale;
  };
  const locales: MyDictionary = { se: sv, en: enUS };

  const { t, i18n } = useTranslation();

  return (
    <main>
      {hasActiveEntity ? (
        <>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={locales[i18n.language]}
          >
            {' '}
            {/* https://mui.com/x/react-date-pickers/localization/ */}
            <Box sx={{ display: 'flex', width: '100%' }}>
              <DataGridPremiumLicense />
              <TopBar />
              <SideBar />
              <Box marginTop={4} sx={{ display: 'flex', width: '100%' }} pb={7}>
                <FormToDo />
                <Outlet />
              </Box>
              <Paper
                className="bottomBarPaper"
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                <BottomBar />
              </Paper>
            </Box>
            <Footer></Footer>
          </LocalizationProvider>
        </>
      ) : (
        <Box display="flex" justifyContent="center" my={6}>
          <CircularProgress />
        </Box>
      )}
    </main>
  );
};

export default AppLayout;
