import { Typography } from '@mui/material';
import CurrencyFormat from '../../util/currency-format/currency-format';

interface CurrencyFieldProps {
  value?: number;
  suffix?: string;
  className?: string;
  decimals?: number;
  fixedDecimals?: boolean;
}

export default function CurrencyField(props: CurrencyFieldProps) {
  const { suffix = 'SEK', decimals = 2, fixedDecimals = true } = props;

  var separator = ',';
  if (decimals == 0) separator = '';

  return (
    <>
      {props.value == null ? (
        ''
      ) : (
        <CurrencyFormat
          value={props.value}
          displayType="text"
          thousandSeparator={' '}
          decimalSeparator={separator}
          decimalScale={decimals}
          fixedDecimalScale={fixedDecimals}
          suffix={' ' + suffix}
          renderText={(value: any) => (
            <Typography variant="body1" className={props.className}>
              {value}
            </Typography>
          )}
        ></CurrencyFormat>
      )}
    </>
  );
}
