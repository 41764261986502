import InvestmentLayout from '../../components/layout/InvestmentLayout';
import Transactions from '../../components/widgets/Transactions';

export default function TransactionsPage() {
  return (
    <>
      <InvestmentLayout>
        <Transactions />
      </InvestmentLayout>
    </>
  );
}
