import { LicenseInfo } from '@mui/x-license-pro';

const DataGridPremiumLicense = () => {
  LicenseInfo.setLicenseKey(
    '96f695d3adb6f5d2234081fcef2ac7afTz05MTY4NixFPTE3NDg3NzgzNTEwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y'
  );

  return <></>;
};

export default DataGridPremiumLicense;
