import React, { useEffect, useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  CircularProgress,
  DialogContentText,
  Box,
} from '@mui/material';

import { IInitSignResponse } from '../../models/initInsigniaResponse';
import { FaPortfolioDto } from '../../models/portfolio';
import { useApiClient } from '../../hooks/apiClient';
import { getActiveUser, getMainUser } from '../../redux/stateFuncs';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { setToDoComplete } from '../../redux/slices/todo';
import { ToDoEnum } from '../../Enums';
import { useTranslation } from 'react-i18next';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

function NewPortfolioAgreement(props: any) {
  const { t } = useTranslation();
  const api = useApiClient();
  const users = useAppSelector((state) => state.users);
  const dispatch = useAppDispatch();

  var isChecking = false;

  const isToDo: boolean | undefined = props.isToDo;
  const activeUserCanSign = getActiveUser(users)?.canSign;

  const columnsApproval: GridColDef[] = [
    {
      field: 'name',
      headerName: t('Agreement'),
      flex: 2.0,
      minWidth: 140,
      renderCell: (params: GridRenderCellParams<FaPortfolioDto>) => (
        <>{`${params.row.faPortfolioKey} - ${params.row.portfolioType} ${params.row.portfolioAgreement}`}</>
      ),
    },
    {
      field: 'id',
      headerName: '',
      flex: 1.0,
      minWidth: 150,
      renderCell: (params: GridRenderCellParams<FaPortfolioDto>) => (
        <Button
          className="agreement-table-button"
          sx={{ ml: 2 }}
          size="small"
          variant="outlined"
          color="primary"
          onClick={() => {
            handleClickDownload(params.row?.id!);
          }}
        >
          {t('Download')} {t('Agreement')}
        </Button>
      ),
    },
    {
      field: 'sign',
      headerName: '',
      flex: 1.0,
      minWidth: 270,
      align: 'right',
      renderCell: (params: GridRenderCellParams<FaPortfolioDto>) => (
        <>
          {activeUserCanSign ? (
            <>
              <Button
                size="small"
                sx={{ ml: 2 }}
                variant="outlined"
                color="primary"
                onClick={() =>
                  handleClickSign(params.row.id, params.row.portfolioId, true)
                }
              >
                {t('Confirm')}
              </Button>
            </>
          ) : null}
        </>
      ),
    },
  ];

  const handleClickDownload = (opportunityId: string) => {
    api
      .get(`api/portfolio/GetNewPortfolioAgreementFile/${opportunityId}`, {
        method: 'GET',
      })
      .then(
        (res) => {
          const url = res.data.url;
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', res.data.fileName);
          document.body.appendChild(link);
          link.click();
        },
        (err) => {
          alert('Error');
          setIsProgress(false);
        }
      );
  };

  const handleClickSign = (
    id: string,
    portfolioId: string,
    isAccept: boolean
  ) => {
    setIsQrCodeFetched(false);

    var activeUser = getActiveUser(users);
    var activeEntityId = activeUser?.id;
    var entityType = activeUser?.isCompany == true ? 'account' : 'contact';

    const mainUserId = getMainUser(users)?.id;
    const mainUserSSN = getMainUser(users)?.socialSecurityNumber;

    let intervalId: number | undefined;
    let counter = 0;
    let initSigningResponse: IInitSignResponse;

    api
      .post('api/Signing/InitSigningNewPortfolioAgreement', {
        socialSecurityNumber: mainUserSSN,
        time: counter,
        portfolioId: portfolioId,
        opportunityId: id,
      })
      .then((res) => {
        initSigningResponse = res.data as IInitSignResponse;
        setInitSignResponse(initSigningResponse);
        setIsQrCodeFetched(true);
      })
      .then(() => {
        intervalId = window.setInterval(() => {
          if (counter > 20) {
            window.clearInterval(intervalId);
            setIsQrCodeFetched(false);
            api
              .post(`api/Signing/CancelSign/${initSigningResponse.orderRef}`)
              .then((res) => {
                setIsProgress(false);
              });
            setApiError({
              ...apiError,
              isOpen: true,
              Error: 'Signing Error',
              InnerError:
                'The allowed time for signing the document has been exceeded. To repeat the operation, click on the confirm button.',
            });
          } else {
            if (!isChecking) {
              isChecking = true;
              counter++;

              initSigningResponse.time = counter;
              initSigningResponse.approverId = mainUserId;
              initSigningResponse.entityId = id;
              initSigningResponse.ownerId = activeEntityId;
              initSigningResponse.customerLogicalName = entityType;
              initSigningResponse.entityLogicalName = 'opportunity';
              initSigningResponse.socialSecurityNumber = mainUserSSN;

              api
                .post(
                  `api/Signing/GetSigningAndApprovePortfolioAgreement`,
                  initSigningResponse
                )
                .then(
                  (res) => {
                    initSigningResponse = res.data as IInitSignResponse;
                    setInitSignResponse(initSigningResponse);
                    if (initSigningResponse.status === 'complete') {
                      let statuscode = isAccept ? 'Won' : 'Canceled';

                      const newPortfolios = portfolios.map((p) => {
                        if (p.id == id) {
                          p.statuscode = statuscode;
                        }
                        return p;
                      });
                      setPortfolios(newPortfolios);
                      window.clearInterval(intervalId);
                      setIsQrCodeFetched(false);
                      setIsFetched(true);
                      setIsSigning(false);

                      // If no more portfolis
                      if (
                        isToDo &&
                        !newPortfolios.find(
                          (p) => p.statuscode == 'UnderRevision'
                        )
                      ) {
                        dispatch(
                          setToDoComplete(ToDoEnum.NewPortfolioAgreement)
                        );
                      }
                    }

                    isChecking = false;
                  },
                  (err) => {
                    isChecking = false;
                    api.post(
                      `api/Signing/CancelSign/${initSigningResponse.orderRef}`
                    );
                    window.clearInterval(intervalId);
                    setIsQrCodeFetched(false);
                    setIsSigning(false);
                    if (
                      err.response.data.additionalDetails.message !=
                      'InvalidParameters: No such order'
                    )
                      setApiError({
                        ...apiError,
                        isOpen: true,
                        Error: 'Signing Error',
                        InnerError: err.response.data.additionalDetails.message,
                      });
                  }
                );
            }
          }
        }, 2000);
      });
  };

  const handleClickReject = (id: string) => {
    setPortfolios(portfolios.filter((p) => p.id !== id));
  };

  const handleCloseError = () => {
    setApiError({ ...apiError, isOpen: false });
  };

  const [isFetched, setIsFetched] = useState(false);
  const [portfolios, setPortfolios] = useState<FaPortfolioDto[]>([]);
  const [isProgress, setIsProgress] = useState(false);
  const [initSignResponse, setInitSignResponse] = useState<IInitSignResponse>();
  const [isQrCodeFetched, setIsQrCodeFetched] = useState(false);
  const [isSigning, setIsSigning] = useState(false);
  const [apiError, setApiError] = React.useState({
    isOpen: false,
    Error: '',
    InnerError: '',
  });

  useEffect(() => {
    setIsFetched(false);
    var activeUser = getActiveUser(users);
    var activeEntityId = activeUser?.id;

    api.get(`api/portfolio/newagreements/${activeEntityId}`).then((res) => {
      setPortfolios(res.data as FaPortfolioDto[]);
      setIsFetched(true);
    });
  }, [users]);

  return (
    <React.Fragment>
      <Dialog
        open={apiError.isOpen}
        onClose={handleCloseError}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{apiError.Error}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {apiError.InnerError}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseError}>{t('Ok')}</Button>
        </DialogActions>
      </Dialog>

      {isProgress || isSigning ? (
        <Box display="flex" justifyContent="center" my={6}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={6} overflow={'hidden'}>
          {isQrCodeFetched ? (
            <Grid item xs={12} md={12}>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div>
                  <img
                    style={{ maxWidth: '300px' }}
                    alt="Embedded QR Code"
                    src={
                      'data:image/jpeg;base64,' +
                      initSignResponse?.qrCodeAsBase64
                    }
                  />
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                      size="small"
                      sx={{ ml: 2 }}
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        setIsQrCodeFetched(false);
                        setIsFetched(false);
                        setIsSigning(true);
                        window.location.href = `bankid:///?autostarttoken=${initSignResponse?.autoStartToken}&redirect=null`;
                      }}
                    >
                      {t('signing.ConfirmSameDevice')}
                    </Button>
                  </div>
                </div>
              </div>
            </Grid>
          ) : (
            <>
              {isToDo ? (
                <Grid item xs={12} md={12}>
                  {isFetched ? (
                    <>
                      <Typography variant="h6" gutterBottom display="inline">
                        {t('ForApproval')}
                      </Typography>
                      <Box height={'100%'} mb={2} pb={4}>
                        <DataGrid
                          rows={portfolios.filter(
                            (p) => p.statuscode === 'UnderRevision'
                          )}
                          disableRowSelectionOnClick
                          columns={columnsApproval}
                          columnHeaderHeight={36}
                          rowHeight={40}
                          autoHeight
                          getRowId={(row) =>
                            `${row.faPortfolioKey} - ${row.portfolioType} ${row.portfolioAgreement}`
                          }
                        />
                      </Box>
                    </>
                  ) : (
                    <div>
                      <Box display="flex" justifyContent="center" my={6}>
                        <CircularProgress />
                      </Box>
                      <Box display="flex" justifyContent="center" my={6}>
                        <Typography> {t('LongLoading')}</Typography>
                      </Box>
                    </div>
                  )}
                </Grid>
              ) : (
                ''
              )}
            </>
          )}
        </Grid>
      )}
    </React.Fragment>
  );
}

export default NewPortfolioAgreement;
