import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICustomer } from '../../models/customer';

const initialState: ICustomer[] = [];

export const customersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    setCustomers: (state, action: PayloadAction<ICustomer[]>) => {
      state = [];
      return [...state, ...action.payload];
    },
    clearCustomers: (state) => {
      state = [];
    },
    showCustomer: (state, action: PayloadAction<string>) => {
      const customer: ICustomer | undefined = state.find(
        (user) => user.contactId === action.payload
      );
      if (customer) {
        customer.show = true;
      }
    },
    hideCustomer: (state, action: PayloadAction<string>) => {
      const customer: ICustomer | undefined = state.find(
        (user) => user.contactId === action.payload
      );
      if (customer) {
        customer.show = false;
      }
    },
    showCustomers: (state, action: PayloadAction<string[]>) => {
      state.forEach((c) => {
        if (action.payload.find((p) => p === c.contactId)) c.show = true;
        else c.show = false;
      });
    },
  },
});

export const {
  setCustomers,
  clearCustomers,
  showCustomer,
  hideCustomer,
  showCustomers,
} = customersSlice.actions;
export default customersSlice.reducer;
