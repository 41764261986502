import { Box, Typography } from '@mui/material';
import React from 'react';

export enum AuthInfoType {
  SignIn = 1,
  SignOut = 2,
  Loading = 3,
}

interface Props {
  type: AuthInfoType;
}

const AuthInfoWrapper: React.FunctionComponent<Props> = (props: Props) => {
  var info: string;

  switch (props.type) {
    case AuthInfoType.SignIn:
      info = 'Signing in...';
      break;
    case AuthInfoType.SignOut:
      info = 'Signing out...';
      break;
    case AuthInfoType.Loading:
      info = 'Loading...';
      break;
    default:
      info = '';
  }

  return (
    <Box marginTop={4} sx={{ width: '100%' }}>
      <Typography align="center">{info}</Typography>
    </Box>
  );
};
 
export default AuthInfoWrapper;
