import React from 'react';
import { useEffect, useRef } from 'react';
import { securityConfig } from '../config';
import { SecurityType } from '../Enums';
import { FaFilteredSecurityDto } from '../models/dto/faSecurityDto';

type KeySelector<T> = (item: T) => string;

export type EnumDictionary<T extends string | symbol | number, U> = {
  [K in T]: U;
};

export class Common {
  public static getObject = (object: any, key: string): any => {
    if (Array.isArray(object)) {
      for (const obj of object) {
        const result = this.getObject(obj, key);
        if (result) {
          return obj;
        }
      }
    } else {
      if (object.hasOwnProperty(key)) {
        return object;
      }

      for (const k of Object.keys(object)) {
        if (typeof object[k] === 'object') {
          const o = this.getObject(object[k], key);
          if (o !== null && typeof o !== 'undefined') return o;
        }
      }

      return null;
    }
  };

  public static groupBy<T>(
    array: Iterable<T>,
    keySelector: KeySelector<T>
  ): Record<string, T[]> {
    return Array.from(array).reduce(
      (acc: Record<string, T[]>, item: T) => {
        const key = keySelector(item);
        if (key in acc) {
          // found key, push new item into existing array
          acc[key].push(item);
        } else {
          // did not find key, create new array
          acc[key] = [item];
        }
        return acc;
      },
      {} // start with empty object
    );
  }

  public static usePrevious = (value: any, initialValue: any) => {
    const ref = useRef(initialValue);
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  public static useEffectDebugger = (
    effectHook: any,
    dependencies: any,
    dependencyNames = []
  ) => {
    const previousDeps = Common.usePrevious(dependencies, []);

    const changedDeps = dependencies.reduce(
      (accum: any, dependency: any, index: number) => {
        if (dependency !== previousDeps[index]) {
          const keyName = dependencyNames[index] || index;
          return {
            ...accum,
            [keyName]: {
              before: previousDeps[index],
              after: dependency,
            },
          };
        }

        return accum;
      },
      {}
    );

    if (Object.keys(changedDeps).length) {
      console.log('[use-effect-debugger] ', changedDeps);
    }

    useEffect(effectHook, dependencies);
  };
}

export class routeEntry {
  nav: string = '';
  element = (): JSX.Element => {
    return React.createElement(React.Component);
  };

  constructor(_nav: string, _element: any) {
    this.nav = _nav;
    this.element = _element;
  }
}

export function getNavigationLink(page: any): string {
  if (page.routes.length > 0) {
    return page.nav + '/' + page.routes[0].nav;
  } else return page.nav;
}

export function getSecurityDetailLink(data: FaFilteredSecurityDto): string {
  let link = '';
  if (data !== undefined) {
    if (data.securityType === SecurityType.Stock) {
      link = `/aktie.html?isin=${data.isinCode}`;
    } else if (data.securityType === SecurityType.Fund) {
      let allocation =
        data.allocation !== undefined
          ? `&alloc=${encodeURIComponent(data.allocation)}`
          : '';
      link = `/fond.html?isin=${data.isinCode}${allocation}`;
    } else return '';

    return securityConfig.detailsUrl + link;
  } else return '';
}

export function dateRange(startDate: Date, endDate: Date, steps = 1) {
  const dateArray = [];
  let currentDate = new Date(startDate);

  while (currentDate <= new Date(endDate)) {
    dateArray.push(new Date(currentDate));
    // Use UTC date to prevent problems with time zones and DST
    currentDate.setUTCDate(currentDate.getUTCDate() + steps);
  }

  return dateArray;
}
